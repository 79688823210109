import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { NavLink } from "react-router-dom";
import Zoom from "react-reveal/Zoom";

import {Api_Endpoints, domain_name} from "../../../endpoint";

export default function HeroBanner({ data }) {
  const carousalIndicator = data.map((item, i) => {
    return (
      <button
        type="button"
        key={i}
        data-bs-target="#hophigh_herobanner"
        data-bs-slide-to={i}
        className={i == 0 ? "active" : ""}
      ></button>
    );
  });

  const carouselItem = data.map((item, i) => {
    const { banner_image, short_text, button } = item.children.content;
    let btnURL = "/read"; 
    if(button === "Write Now"){
      btnURL = "/write";
    }
    if(button === "Record Now"){
      btnURL = "/express";
    }
    return (
      <Carousel.Item  className={`carousel-item ratio ratio-26x9`}
      key={i}>
        <div className="img d-flex align-items-end justify-content-end">
          <img
            src={`${domain_name}${banner_image}`}
            layout="fill"
            alt="HopHigh"
            className="d-block float-end img-fluid "
          />
        </div>
        <div className="banner-overlay-div"></div> 
        <Carousel.Caption className="carousel-caption d-flex align-items-center position-absolute">
 
          <div className="col-9 col-md-6">
         <h2 className="px-2">{short_text}</h2>
            <div className="nav-link px-2">
              <NavLink
                to={btnURL}
                className="hvr-bounce-to-right"
              >
               {button}
              </NavLink>
            </div>
          </div>

        </Carousel.Caption>
        </Carousel.Item>
    );
  });

  return (
    <Carousel id="hophigh_herobanner" indicators={true} controls={false}>
      {carouselItem}  
    </Carousel>
  );
}
