import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Api_Endpoints, base_url } from "../../../endpoint";
import { PostData } from "../../services/fetchData";
import { authContext } from "../../services/authContext";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import LoaderCustom from '../../common/loaders/loaderCustom';
import groupImg from "../../../images/group.jpg";
import ModalPop from "../../common/modalPopup/modalPopup";



export default function JoinGroup() {
    const { joinGroupAcceptRequest } = Api_Endpoints;
    const { profileData, authenticated } = useContext(authContext);
    const [showToast, setShowToast] = useState(false)
    const [data, setdata] = useState(null)
    const [postMsg, setPostMsg] = useState('')
    const [modal, setModal] = useState();
    const [uid, setUid] = useState("");
    const [success, setSuccess] = useState('');
   // console.log(profileData.mail[0].value)
    // const location = useLocation();
    const { gid } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const emailid = query.get("email");
    let history = useHistory();

    const modalfn = () => {
        setModal(!modal)
    }

    useEffect(() => {
        if ("uid" in localStorage) {
            setUid(localStorage.getItem('uid'));
        }
    }, []);

const cancelHandler = () => {
    return history.push({
        pathname: `/`,
    });
}
    const submitHandler = () => {
        if (authenticated) {
            const req = {
                "nid": gid,
                "email": profileData.mail[0].value
            }

            const reqParam = JSON.stringify(req);
            //   console.log(emailid);
            PostData(`${joinGroupAcceptRequest}`, "Post", reqParam, true, true).then(
                (result) => {
                    if (result.error == true) {
                        setPostMsg(result.message)
                      
                        setShowToast(true)

                    } else {
                        setSuccess('success')
                        setPostMsg(result.success)
                        setShowToast(true)
                        setTimeout(() => {
                            return history.push({
                                pathname: `/`,
                            });

                        }, 2500);
                    }



                },
                (error) => {
                }
            );
        } else {
            setModal(true)
        }

    }
    useEffect(() => {
       
        PostData(`${base_url}/rest-group/${gid}?format=hal_json`, "GET", false).then(
            (result) => {
                console.log(result)
                if (result.length > 0) {
                    setdata(result[0])
                } else {
                    setdata(result)
                    console.log(result)
                }
            },
            (error) => {
            }
        );

    }, [gid]);

    return (
        <>

            <section className="container text-center">
                {data ?
                    <div className="joinbox pt-5 pb-5">
                        {data && data.title ?
                            <>
                                <h1 className="textColorSecondary">{data.title}</h1>
                                <img className="imgGroup" src={groupImg} />
                                <h4 className="mt-5 mb-4">Are you sure you want to join this Group?</h4>

                                <div className="d-flex justify-content-around">
                                    <button className="custButton hollow" onClick={cancelHandler}>Cancel</button> <button className="custButton" onClick={submitHandler}>JOIN</button>
                                </div>
                            </>
                            :
                            <>
                                <img className="imgGroup" src={groupImg} />
                                <h4 className="mt-5 mb-4">Oops!! Something went wrong.</h4>
                                <div className="d-flex justify-content-around">
                                    <button className="custButton hollow">Home</button>
                                </div>
                            </>
                        }
                    </div>
                    :
                    <LoaderCustom></LoaderCustom>


                }
            </section>
            <ToastContainer className="position-fixed p-3" position={`bottom-center`}>
                <Toast onClose={() => setShowToast(false)} bg={success} show={showToast} delay={4000} autohide>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">Group</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <p>{postMsg}</p>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {modal &&
                <ModalPop modal={modal} modalFn={modalfn} storyType={"socialModal"} shortDescription="Login before you join the group" />
            }
        </>
    )
}
