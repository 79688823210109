import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="videoWrapper">
    <iframe
      className="video"
      src={`https://www.youtube.com/embed/${embedId}?rel=0&amd;showinfo=0`}
      frameBorder="0"
      controls="0"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;