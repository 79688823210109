import React from "react";
import Register from "../../../images/register.svg";
import RegisterTitles from "../titles/registerTitles";
import Modal from "../modalPopup/modalPopup";
import LoginForm from "./loginForm";

export function RegisterSuccess({ data, login }) {
  return (
    <>
      {data && 
        <div className="row">
          <div className="col-md-6">
            <img
              src={Register}
              className="d-block float-end img-fluid"
              alt="HopHigh Register"
            />
          </div>
          <div className="col-6">
            <RegisterTitles title="Welcome to Hophigh"></RegisterTitles>
            <p className="mt-2">You have successfully created the account.</p>
            <RegisterTitles title={`Your User ID is ${data.name[0].value}`}></RegisterTitles>
            <p className="mt-2">
              Use the user ID and password to login to your account
            </p>
            <div className="nav-link px-0">
              <a href="#" className="custButton" onClick={login}>
                Let's Begin
              </a>
            </div>
          </div>
        </div>
       }
    </>
  );
}
