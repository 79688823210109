import React, { useState, useEffect, Fragment, useContext} from "react";
import styles from "./search.module.css";
import Select from "react-select";
import { PostData } from "../../services/fetchData";
import {NavLink} from "react-router-dom";
import {Api_Endpoints} from '../../../endpoint';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import { authContext } from "../../services/authContext";

//require('es6-promise').polyfill();
const SEARCH_URI = 'https://api.github.com/search/users';

export default function SearchBar({searchClass, mobileSearchbar}) {
  const [rdValue, setRdValue] = useState(true);
  const [rdTypeVal, setRdTypeVal ] = useState("stories")
  const [id, setId] = useState(0);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  let selectDisplay = ``;

  const onRadioValChange = (event) => {
    setRdTypeVal(event.target.value);
    setRdValue(!rdValue);
    setSelectedUserId("");
    setSelectedGenre("");
    setSelectedGrade("");
    setSelectedSchool("");
  };

  const schoolHandler = (events) => {
   setSelectedSchool(events.value)
   
  }
  const gradeHandler = (events) => {
    setSelectedGrade(events.value)
   }
  const genreHandler = (events) => {
    setSelectedGenre(events.label)
  }
  const userIdHandler = (val) => {
    setSelectedUserId(val)
 }

 const btnSearch = ()=>{
  setId(id+1);
  mobileSearchbar();
 }
 
  return (
    <div className={`d-none d-lg-block searchbar  ${styles.searchBar} ${searchClass ? "searchShow": ""}`}>

        <div className="filterContainer d-flex align-items-center justify-content-between">
          <div className="form-check form-check-inline">
            <label className="form-check-label">Search By</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="rdSearchType"
              id="rdStories"
              value="stories"
              checked={rdValue}
              onChange={onRadioValChange}/>
            <label className="form-check-label" htmlFor="rdStories">
              Stories
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="rdSearchType"
              id="rdUserId"
              value="userId"
              onChange={onRadioValChange}/>
            <label className="form-check-label" htmlFor="rdUserId">
              User Id
            </label>
          </div>
         { rdValue ? 
         <StoriesBlock chgSchoolHandler={schoolHandler} chgGradeHandler={gradeHandler} chgGenreHandler={genreHandler} /> : 
         <UserIdBlock userIdHandler={userIdHandler}/>}
            <div className="form-check form-check-inline flex-shrink-1">
           {selectedUserId || selectedSchool || selectedGrade || selectedGenre ?
            <NavLink 
            onClick={btnSearch}
            to={{
              pathname:"/search",
              state:{
                rdValue:{rdValue},
                userSel:{selectedUserId},
                schoolSel:{selectedSchool},
                gradeSel:{selectedGrade},
                genreSel:{selectedGenre},
                id:{id},
                type:{rdTypeVal}
              }
            }} 
            className="custButton lg inverse mt-2">
              SEARCH
            </NavLink>
           :
            <a 
            className="custButton lg inverse mt-2">
              SEARCH
            </a>
            }
          </div>
        </div>

    </div>
  );
}

const UserIdBlock = ({userIdHandler}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const {searchUserID} = Api_Endpoints;
  const handleSearch = (query) => {
    const tmpString = `user=${query}&name=${query}`;
    setIsLoading(true);
    fetch(`${searchUserID}${tmpString}`)
      .then((resp) => resp.json())
      .then((items) => {
        const options = items.map((i) => ({
         /* avatar_url: i.avatar_url,
          id: i.id,
          login: i.login,*/
          name:i.name,
          field_name:i.field_name
        }));
        setOptions(options);
        setIsLoading(false);
      });
    };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const handleChange = (e)=>{
    if(e.length !== 0){
    userIdHandler(e[0].name);
  }
  }

  return (
    <Fade> 
    <div className="searchDrops form-check form-check-inline flex-grow-1">
      <AsyncTypeahead
      filterBy={filterBy}
      id="async-example"
      isLoading={isLoading}
      labelKey="name"
      minLength={3}
      onSearch={handleSearch}
      className="form-control rounded-pill"
      options={options}
      onChange={handleChange}
      placeholder="Search for a HopHigh user..."
      renderMenuItemChildren={(option, props) => (
        <Fragment>
        <div><span>{option.name}</span> <span className="float-end">{option.field_name}</span></div>
        </Fragment>
      )}
    />
    </div>
    </Fade>
  );
};

const StoriesBlock = ({chgSchoolHandler, chgGradeHandler, chgGenreHandler, schoolOptions, gradeOptions, genreOptions}) => {
  const {div_list, grade_List, school_List, genre_List} = useContext(authContext);

  return (
    <div className="form-check form-check-inline  flex-grow-1 searchDrops">
   <Fade>   
     <div className="row">
        <div className="col-md-5 searchDropRow">
          <Select
            options={school_List}
            placeholder="Select School"
            isSearchable
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={chgSchoolHandler}
          />
        </div>
        <div className="col-md-3 searchDropRow">
          <Select
           options={grade_List}
            placeholder="Select Grade"           
            isSearchable
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={chgGradeHandler}
          />
        </div>
        <div className="col-md-4 searchDropRow">
          <Select
            options={genre_List}
            placeholder="Select Genre"
            isSearchable
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={chgGenreHandler}
          />
        </div>
      </div>
      </Fade>
    </div>
  );
};
