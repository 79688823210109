import React, {useContext, useEffect, useState} from "react";
import styles from "./modal.module.css"
import Modal from 'react-bootstrap/Modal';
import AlertHopHigh from "../../../images/login-to-read.svg";
import AlertCoinStories from "../../../images/login-and-redeem.svg";
import AlertWriteStories from "../../../images/to-write-stories.svg";
import AlertRecordVideos from "../../../images/to-record-videos.svg";
import LoginForm from "../header/loginForm";
import SignUp from "../header/signUpForm";
import ForgotPassword from "../header/forgotPassword";
import {RegisterSuccess} from "../header/successMsgModal";
import { authContext } from "../../services/authContext";

export default function ModalPop({modal, modalFn, storyType, loginModal, registerModal, shortDescription}) {
  const [show, setShow] = useState();
  const [description, setDescription] = useState();
  const [alertImage, setAlertImage] = useState();
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [successMsg , setSuccessMsg] = useState(false);
  const [purpleModal, setPurpleModal] = useState(false);
  const [formData, setFormData] = useState("");

  const handleClose = () => {
    setShow(false);
    modalFn();
  };

  const handleShow = () => setShow(true);

  useEffect(()=>{
    if(storyType == "hophigh_picks_story"){
      setDescription("Login to read the best <br/>hand picked stories by <br/>our HopHigh team");
      setAlertImage(AlertHopHigh);
      setPurpleModal(true);
    } else if(storyType == "coin_story") {
      setDescription("Login and redeem your<br/> accumulated points to<br/> read coin stories");
      setAlertImage(AlertCoinStories);
      setPurpleModal(true);
    } else if(storyType == "WRITE NOW") {
      setDescription("To write stories please <br/>Login with your registered ID<br/> or create a new ID");
      setAlertImage(AlertWriteStories)
      setPurpleModal(true);
    } else if(storyType == "hophigh_picks_video") {
      setDescription("To record or view videos please Login with your registered ID <br/>or create a new ID");
      setAlertImage(AlertRecordVideos)
      setPurpleModal(true);
    } else if(storyType == "Record Now") {
      setDescription("To record or view videos please Login with your registered ID <br/>or create a new ID");
      setAlertImage(AlertRecordVideos)
      setPurpleModal(true);
    } else if(storyType == "loginCard") {
      loginfn();
    } else if(storyType == "registerCard") {
      registerfn();
    } else if(storyType == "socialModal") {
      setDescription(shortDescription);
      setAlertImage(AlertHopHigh);
      setPurpleModal(true);
    }  else if(storyType == "personal-dairy") {
      setDescription("To write in personal dairy please <br/>Login with your registered ID<br/> or create a new ID");
      setAlertImage(AlertWriteStories);
      setPurpleModal(true);
    } else {
      setPurpleModal(false);
    }
    
    if(loginModal){
      loginfn()
      setRegister(false);
      setShow(modal)
    }
    
    if(registerModal){
      registerfn();
      setLogin(false);
      setShow(modal);
    }
    setShow(modal);
  },[])
  
  const registerfn = ()=>{
    setRegister(true);
    setLogin(false); 
    setPurpleModal(false);
    setSuccessMsg(false);
    setForgotPassword(false);
  }

  const loginfn = ()=>{
    setLogin(true);
    setRegister(false);
    setPurpleModal(false);
    setForgotPassword(false);
    setSuccessMsg(false);
  }


  const forgotPasswordFn = ()=>{
    setForgotPassword(true);
    setLogin(false);
    setSuccessMsg(false);
    setRegister(false);
    setPurpleModal(false);
  }

  
  const successDivFn = (data)=> {
    setSuccessMsg(true);
    setForgotPassword(false);
    setLogin(false);
    setRegister(false);
    setFormData(data);
    setPurpleModal(false);
  }
 

  return (
    <>
    <Modal show={show}  dialogClassName={`hophighModal modal-dialog-centered ${purpleModal ? "purpleModal" :""}`} size="lg" onHide={handleClose}>
      <Modal.Header  >
      <h3 className="closebtn" onClick={handleClose}><i className={`bi bi-x-circle ${purpleModal ? "text-white" : "" }`} ></i></h3>
       </Modal.Header >
       <Modal.Body>
       {login ? <LoginForm forgotPassword={forgotPasswordFn} createAccount={registerfn} closeModal={()=>setShow(false)}/>
       : register? <SignUp successContainer={successDivFn} login={loginfn}/> 
       : successMsg ? <RegisterSuccess data={formData} login={loginfn}/> 
       : forgotPassword ? <ForgotPassword login={loginfn}/>
       :<PurpleModal image={alertImage} description={description} register={registerfn} loginfn={loginfn}/>}   
       </Modal.Body>
    </Modal>
    </>
);
}

export function PurpleModal({image, description, loginfn, register}) {
return(
<div className="row">
<div className="col-md-6">
  <img
    src={image}
    className="d-block float-md-end img-fluid"
    alt="hophigh Register"
  />
</div>
<div className="col-md-6">
<h4 className="mt-2 text-white" style={{lineHeight: "35px"}} dangerouslySetInnerHTML={{__html: description}}></h4>
<div className="mt-4 text-center">
<span><a className="text-white pe-4" onClick={loginfn} style={{cursor:"pointer"}}>Login</a></span>
<span><a className="custombtn text-white border border-white rounded-pill p-1 px-3" onClick={register} style={{cursor:"pointer"}}>Register</a></span>
</div>
</div>
</div>
)
}

export function Tnc({hideFn}) {
  const [show, setShow] = useState(true);
  const {terms_condition} = useContext(authContext);
  const handleClose = () => {
    setShow(false);
    hideFn();
  };
  return(
    <>
    <Modal show={show} size="lg" dialogClassName={`hophighModal modal-dialog-centered`} onHide={handleClose}>
      <Modal.Header  >
      <h3 className="closebtn" onClick={handleClose}><i className={`bi bi-x-circle`}></i></h3>
       </Modal.Header >
       <Modal.Body>
       <h4 className="mt-2 text-center">{terms_condition.title}</h4>
      <div className="termsContentBox" dangerouslySetInnerHTML={{ __html: terms_condition.body }}></div>
       <div className="text-center mt-5"><a className="custButton pe-4" onClick={handleClose}>Close</a></div>
       </Modal.Body>
    </Modal>
    </>
  )
  }


