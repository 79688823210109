import React, { useEffect, useState, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { PostData } from '../../services/fetchData';
import { Api_Endpoints } from '../../../endpoint';
import { authContext } from "../../services/authContext";
 

export default function CardSearch({ form, filter, type, showHidefn, genreType }) {
  const { school_list, grade_list, genre_list, restStories, readHopHighStories, readCoinStories, hophigh_picks_video, restExpressVideoGrade, storiesFrmSchool, storiesFrmGrade } = Api_Endpoints;
  /*const options = [
      { value: '-Select-', label: 'Select' },
      { value: 'St. Xaviers High School', label: 'St. Xaviers High School' },
      { value: 'Bal Mohan Vidya Mandir', label: 'Bal Mohan Vidya Mandir' },
      { value: 'Little Angels English High School', label: 'Little Angels English High School' }
    ];*/
  const {div_list, grade_List, school_List, genre_List, genre_vid_List} = useContext(authContext);
  const [grade, setGrade] = useState('');
  const [school, setSchool] = useState('');
  const [genre, setGenre] = useState('');
  const [newdata, setNewdata] = useState();
  const [inputValue, setValue] = useState('');
  const [schoolList, setSchoolList] = useState('');
  const [gradeList, setGradeList] = useState('');
  const [genreList, setGenreList] = useState('');

  // handle input change event
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setValue(inputValue);
    return inputValue;
  };

  // handle selection
  const handleChange = (value, e) => {
  }

  const handleSubmit = (e) => {
    console.log(type)
    e.preventDefault();
    showHidefn();
      const req = {
        "genre_id": genre == "" ? "all" : genre,
        "page": 0,
        "grade_id": grade == "" ? "all" : grade,
        "school": school == "" ? "all" : school,
        "user_id": localStorage.getItem("uid") || 0
      }

      if ((type !== "coin_story") && (type !== "hophigh_picks_story")) {
        req["type"] = type;
      }

     // let reqURl = type == "hophigh_picks_story" ? readHopHighStories : type == "hophigh_picks_video" ? hophigh_picks_video : type == "video_form_school" || "video_form_grade" ? restExpressVideoGrade : type == "coin_story" ? readCoinStories :  restStories;
      let reqURl ;
      switch (type) {
        case "hophigh_picks_story":
          reqURl = readHopHighStories;
          req["type"] = "story";
          break;
        case "hophigh_picks_video":
          reqURl = hophigh_picks_video;
          req["type"] = "video";
          break;
        case "video_form_school":
          reqURl = storiesFrmSchool;
          req["type"] = "video";
          break;
        case "video_from_grade":
          reqURl = storiesFrmGrade;
          req["type"] = "video";
        break;
        case "stories_form_school":
          reqURl = storiesFrmSchool;
          req["type"] = "story";
          break;
        case "stories_from_grade":
          reqURl = storiesFrmGrade;
          req["type"] = "story";
          break;
        case "coin_story":
          reqURl = readCoinStories;
          break;
        default:
        reqURl = restStories;
      }
      PostData(reqURl, "GET", req).then(result => {
          filter(result, req);
      },
        error => {
      })
  }

  const FormElem = form.map(
    (item, i) => {
      if (item == "school") {
        return (
          <div className="col-md-3 col-lg-3 mb-3 mb-md-0" key={"0" + i}>
            <Select
              options={school_List}
              placeholder="Select School"
              isSearchable
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(value) => { setSchool(value.value) }}
            />

          </div>
        )
      } else if (item == "grade") {
        return (
          <div className="col-md-3 col-lg-3 col-xl-2 mb-3 mb-md-0" key={"1" + i}>
            <Select
              className="react-select-container"
              placeholder="Select Grade"
              isSearchable
              classNamePrefix="react-select"
              options={grade_List}
              onChange={(value) => { setGrade(value.value) }}
            />
          </div>
        )
      } else if (item == "genre") {
        return (
          <div className="col-md-3 col-lg-3 col-xl-2 mb-3 mb-md-0" key={"2" + i}>
            <Select
              className="react-select-container"
              placeholder="Select Genre"
              classNamePrefix="react-select"
              isSearchable
              options={genreType == "Video" ? genre_vid_List : genre_List}
              onChange={(value) => { setGenre(value.value) }}
            />
          </div>
        )
      }
    })

  return (
    <form onSubmit={handleSubmit}>
      <div className="row cardSearch pt-3 pb-4 mb-3">
        {form &&
          FormElem}
        <div className="col-12 col-md-2">
          <div className="nav-link px-2 pt-0">
            <button type="submit" className="custButton md">Search</button>
          </div>
        </div>
      </div>
    </form>
  )
}