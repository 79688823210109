import React from "react";
import BottomBorder from "../../../images/colourfullborder.svg"

export default function RegisterTitles(props) {
  return (
    <div className="col-md">
      <h2 className="textColorSecondary mb-0">
        <b>{props.title}</b>
      </h2>
      <img 
      src={BottomBorder}
      />
    </div>
  );
}
