import React, { useContext, useEffect } from "react";
import { authContext } from "../../services/authContext";
import Dropdown from "react-bootstrap/Dropdown";
import { BrowserRouter, NavLink } from "react-router-dom";
import { PostData } from "../../services/fetchData";
import DropNotification from "./dropNotification";
import { Api_Endpoints, base_url } from "../../../endpoint";
import axios from "axios";
import Bounce from "react-reveal/Bounce";

export default function LoggedIn({ userName }) {
  const {profileImage, logOutfn} = useContext(authContext);

  const logOutToken = localStorage.getItem("logOutToken");
  const csrfToken = localStorage.getItem("X-CSRF-Token");
  const { logout } = Api_Endpoints;

  const Logoutfn = () => {
    if ("uid" in localStorage) {
      const uid = localStorage.getItem("uid");

      const logOUTnew = logout + "/" + uid;

      fetch(logOUTnew, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Cache: "no-cache",
          Authorization: "Basic" + " " + "YWRtaW46YWRtaW4=",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let username, password;
          if("username" && "password" in localStorage){
            username = localStorage.getItem('username');
            password = localStorage.getItem('password');
          }
          localStorage.clear();
          if(username && password) {
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);
          }
          logOutfn();
        });
    }
  };
  useEffect(()=>{

  },[profileImage]);


  return (
    <div className="nav d-flex align-items-center float-lg-end">
      <DropNotification />
      <Dropdown className="userProfiledropblock" drop="left" >
        <Dropdown.Toggle
          as={"a"}
          className={"profileDropDown"}
          id="dropdown-basic"
        >
          <span className="userProfileIcon">
            {userName &&
              <img
                src={`${base_url}${profileImage}`}
                width="40"
                height="40"
                className="rounded-circle"
                alt="HopHigh"
              />
            }
          </span>
          <span className="d-none d-xl-inline">{userName && userName.field_name[0].value}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Bounce>
            <NavLink to="/planner">
              {" "}
              <Dropdown.Item as={"span"}>My Planner</Dropdown.Item>
            </NavLink>
            <NavLink to="/profile">
              <Dropdown.Item as={"span"}>My Profile</Dropdown.Item>
            </NavLink>
            {userName && userName.roles[0].target_id == "teacher" ?
              <NavLink to="/leaderboard">
                {" "}
                <Dropdown.Item as={"span"}>Leaderboard</Dropdown.Item>
              </NavLink>
              : userName && userName.roles[0].target_id == "student" ?
                <NavLink to="/dashboard">
                  {" "}
                  <Dropdown.Item as={"span"}>Dashboard</Dropdown.Item>
                </NavLink>
                : 
                  <>
                    <NavLink to="/leaderboard">
                      {" "}
                      <Dropdown.Item as={"span"}>Leaderboard</Dropdown.Item>
                    </NavLink>
                    <NavLink to="/dashboard">
                      {" "}
                      <Dropdown.Item as={"span"}>Dashboard</Dropdown.Item>
                    </NavLink>
                  </>
}

            <Dropdown.Item onClick={Logoutfn}>Sign Out</Dropdown.Item>
          </Bounce>
        </Dropdown.Menu>

      </Dropdown>

    </div>
  );
}
