import React,{useEffect, useState, useRef} from "react";
import Titles from "../titles/titles";
import SimilarStories from "./similar-videos";
import SocialActions from "../socialActions/socialActions";
import YoutubeEmbed from "./YoutubeEmbed";
import ReactPlayer from 'react-player/lazy'
import {PostData} from "../../services/fetchData";
import {base_url, Api_Endpoints} from '../../../endpoint';
import Report from "../report/report";
import {Helmet} from "react-helmet";

export default function SingleVideos(props) {
const [data, setData] = useState();    
const [uid, setUid] = useState();
const [videoID, setVideoID] = useState();
const [videoLink, setVideoLink] = useState(null);

const {match:{params}, location:{state}}= props;
const {postSocial} = Api_Endpoints;
const nid = params.nid;


useEffect(() => {
  if ("uid" in localStorage) {
    setUid(`${localStorage.getItem("uid")}`); 
  } else {
    setUid(null);
  }
  singleStoryCall();
  window.scrollTo({
    behavior: "smooth",
    top: 0
  });

},[nid])    


const singleStoryCall = ()=>{
  PostData(`${base_url}/filter-stories/${nid}?user_id=${uid}`, "GET", false).then(result => {
    setData(result.rows[0]);
    const videLink = result.rows[0].field_video_link;    
    const videoid =youTubeGetID(videLink);
    setVideoLink(result.rows[0].field_video_link)
    setVideoID(videoid);
    videoViewCall();
    },
    error => {
    });
}

const videoViewCall = () => {
  const req = {
    nid: nid,
    uid: localStorage.getItem("uid"),
    type: "view",
    status: true
  };

  const reqParam = JSON.stringify(req);
  PostData(
    postSocial,
    "Post",
    reqParam,
    true,
    true
  ).then(
    (result) => {
  
    },
    (error) => {
    }
  );
}

const youTubeGetID = (url)=> {
  var ID = '';
  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if(url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
    return ID;
}

return (
    <>
     <Helmet>
    <title>HopHigh Picks Videos</title>
    <meta name="description" content="Hophigh Picks Videos"/>
    </Helmet>
     <section className="container pt-0">
     {data &&
    <div className="row mt-3">
      <div className="col-lg-12">
       {/* <YoutubeEmbed embedId={videoID} /> */}
       <div className='player-wrapper'>
       <ReactPlayer
        className='react-player' 
        controls={true}
        url={videoLink}
        width='100%'
        height='100%'
        config={{
          youtube: {
            playerVars: { showinfo: 0}
          }
        }}
        />
       </div>
       </div>
    <div className="col-lg-12 mt-3 mt-lg-5">
    <span className=" d-lg-none">  
    <SocialActions
                  styles={`cardSocials backTransparent justify-content-start px-0`}
                  like={data.field_like}
                  view={data.field_view}
                  bookmark={data.field_bookmarks}
                  wishlist={data.field_wishlist}
                  bookmarkStatus={data.bookmark_status}
                  viewStatus={data.view_status}
                  likeStatus={data.like_status}
                  nid={nid}
                  type="videos"
                ></SocialActions>
      </span>
     <Titles title={data.title}/>  
     <div className="cardInfoBox justify-content-start px-0" style={{background:"transparent "}}>
      <span><b>Author:</b> <span className="count">{data.author}</span></span>
      <span className="px-md-4"><b>Genre:</b>  
      <span
                    className="count "
                    dangerouslySetInnerHTML={{ __html: data.field_genre }}
                  ></span>
                  </span>
      <span className="d-none d-lg-block">  
      <SocialActions
                  styles={`cardSocials backTransparent justify-content-start px-0`}
                  like={data.field_like}
                  view={data.field_view}
                  bookmark={data.field_bookmarks}
                  wishlist={data.field_wishlist}
                  bookmarkStatus={data.bookmark_status}
                  viewStatus={data.view_status}
                  likeStatus={data.like_status}
                  nid={nid}
                  type="videos"
                ></SocialActions>
     </span>
      <Report nid={nid} user={uid}></Report>
    </div> 
   
    {/* <SocialActions styles={`cardSocials backTransparent justify-content-start px-0`} like={data.field_like} view={data.field_view} bookmark={data.field_bookmarks} wishlist={data.field_wishlist} ></SocialActions> */}
 
    </div>
    </div>
    }
    </section>
    <section className="container-fluid"><hr className="row dashed mb-0"/></section>

    {data &&
      <SimilarStories 
      genre={data.field_genre} 
      genreid={data.gener_id}
      nid={nid} 
      type={data.field_stories_type} 
      tid={data.tid}/>
    }

    
    </>
    )
}