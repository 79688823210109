import React, {useContext, useEffect} from "react";
import { useForm} from "react-hook-form";
import Titles from "../titles/titles";
import LoginImage from '../../../images/loginImage.svg';
import { PostData } from "../../services/fetchData";
import {  Api_Endpoints } from '../../../endpoint';
import { authContext, AuthConsumer } from "../../services/authContext";
import $ from 'jquery';

export default function LoginForm({forgotPassword, createAccount, errorMsg, closeModal}) {
  const {handleAuthentication} = useContext(authContext);
  const [password, setPassword] = React.useState("");
  const [auth, setAuth] = React.useState(false);
  const [error, setError] = React.useState();
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePasswordVisiblity = () => {
    if(passwordShown){}
    setPasswordShown(passwordShown ? false : true);
  };

    const {login} = Api_Endpoints;
    const { register, control, handleSubmit, setValue, formState: { errors }, formState } = useForm({reValidateMode: 'onChange'});

    useEffect(()=>{
      if("username" && "password" in localStorage){
        setValue("userID", localStorage.getItem('username'));
        setValue("password", localStorage.getItem('password'));
      }
    },[])

    const onSubmit = (val) => {
    const req = {"name":val.userID, "pass":val.password}
    const reqParam = JSON.stringify(req);
    $.ajax({
    url: login,
    dataType: 'json',
    type: 'POST',
    contentType: 'application/json',
    data: reqParam,
    success: function(data) {
    closeModal(false);
    const basicAuth = baseAuthFn("admin", "admin");
    localStorage.setItem('X-CSRF-Token', data.csrf_token);
    localStorage.setItem('logOutToken', data.logout_token);
    localStorage.setItem('basicToken', "YWRtaW46YWRtaW4=");
    localStorage.setItem('uid', data.current_user.uid);
    localStorage.setItem('userID', data.current_user.name);
    handleAuthentication(data.current_user.uid);
    if(val.rememberPass){
      localStorage.setItem('username', val.userID);
      localStorage.setItem("password", val.password);
    }
    },
    error: function(jqXHR, textStatus, errorThrown){
      setError(jqXHR.responseJSON.message);
    }
    });

    }


  const baseAuthFn =(user, password)=>{
      var tok =  user + ':' + password;
      var hash = btoa(tok);
      return hash;
    }

    return (
      <>
      { error &&
      <div className="alert alert-danger text-danger" role="alert">
      <span><i className="bi bi-x-circle-fill text-danger"></i> {error}</span>
      </div>}
      <div className="row">
       <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0 customForm">
       <img src="../loginImage.svg" className="d-block float-end img-fluid" alt="HopHigh"/>
       </div>
       <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column justify-content-between">  
       <Titles title={"Login"}></Titles>
       <form className="customForm d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
       <div>
       <div className="form-floating mb-3">
       <input id="userID" className="form-control customforminput" placeholder="User ID" {...register('userID', { required: true, maxLength: 50 })} />
       <label htmlFor="userID" >User ID<span className="text-danger ps-1">*</span></label> 
       </div>
       {errors.userID && errors.userID.type === "required" && <span className="text-danger">This is required</span>}
       {errors.userID && errors.userID.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
       </div>
       <div> 
       <div className="form-floating mb-3">
       <input 
       type={passwordShown ? "text" : "password"}
       id="password" className="form-control customforminput" placeholder="Password" {...register('password', { required: true, maxLength: 50 })} />
       <label htmlFor="password">Password<span className="text-danger ps-1">*</span></label> 
       <i className={`eyeIcon bi ${passwordShown ? "bi-eye-slash" : "bi-eye"}`} onClick={togglePasswordVisiblity}></i>
       </div>
       {errors.password && errors.password.type === "required" && <span className="text-danger">This is required</span>}
       {errors.password && errors.password.type === "maxLength" && <span className="text-danger">Max length exceeded</span>}
       </div>
       <div className="mb-3 form-check mt-3">
      <input id="rememberPass" type="checkbox" className="form-check-input" {...register('rememberPass')} />
      <label className="form-check-label" htmlFor="rememberPass">Remember Me</label>
      <span><label className="form-check-label float-end "><a className="textColorSecondary text-decoration-none pe-auto" style={{cursor:"pointer"}} onClick={forgotPassword}>Forgot Password ?</a></label></span>
      </div> 
      <button type="submit" className="custButton mt-3" data-bs-dismiss={auth? "modal": ""}>Login</button>
       </form>
      <p className="mt-4 mt-md-2 mb-0">Not registered yet?&nbsp;<span>
       <a className="textColorSecondary pe-auto" onClick={createAccount} style={{cursor:"pointer"}}><b>Create an account</b></a>
      </span>
      </p>
      </div>      
      </div>
      </>
    );
  }


  
