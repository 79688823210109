import React, { useState, useEffect } from "react";
import { PostData } from "../services/fetchData";
import TopBanner from "../common/banner/topBanner";
import Stories from "../common/stories/stories";
import { Api_Endpoints} from "../../endpoint";
import LoaderCustom from "../common/loaders/loaderCustom";
import {Helmet} from "react-helmet";


export default function AboutUs() {
  const [data, setData] = useState();
  const { about_us } = Api_Endpoints;

  useEffect(() => {
    window.scrollTo(0, 0);
    PostData(about_us, "GET").then(
      (result) => {
        setData(result);
      },
      (error) => {
      }
    );
    return ()=>{
      setData(null)
    }
  }, []);
  return <>{data ? <AboutUsPage data={data} /> : <LoaderCustom></LoaderCustom> }</>;
}
function AboutUsPage({ data }) {
  const moduleArray = data.display_weight;
  const {banner, storie} = data;
  
  return (
    <>
      <Helmet>
      <title>HopHigh - About</title>
      <meta name="description" content="Know more about our concept and philosophy"/>
      </Helmet>
      <TopBanner data={banner} />
      <section className="container">
       <Stories data={storie}/>
      </section>
    </>
  );
}

