import React, { useMemo, useState, useEffect, useContext } from "react";
import { PostData } from "../services/fetchData";
import { Api_Endpoints, base_url } from "../../endpoint";
import UserImage from "../../images/userImage.svg";
import Titles from "../common/titles/titles";
import Accordion from "react-bootstrap/Accordion";
import AccountAssistance from "../common/accountAssistance/account-assistance";
import BottomBorder from "../../images/colourfullborder.svg";
import DataGrid from "../common/dataGrid/dataGrid";
import Counters from "../common/counters/counters";
import { authContext } from "../services/authContext";
import Table from "../common/table/table";
import Batches from "../common/batches/batches";
import { NavLink, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Coins from "../common/coins/coins";
import Profilebox from "../common/misc/profilebox";
import NoContent from "../common/misc/noContent";
import Modal from "react-bootstrap/Modal";
import Fade from "react-reveal/Fade";

export default function Dashboard() {
  const [stories, setStories] = useState();
  const [videos, setVideos] = useState();
  const [bookmarks, setSetBookmarks] = useState();
  const [followShow, setFollowShow] = useState(false);
  const [followingShow, setFollowingShow] = useState(false);  
  const [my_followers, setFollowers] = useState();
  const [my_followings, setFollowings] = useState();

  const { dashboard, dashboard_story, dashboard_video, dashboard_bookmarks, followers, followings, searchProfile, leaderboardlist, leaderboardgroup, leaderboard_Detailedlist } = Api_Endpoints;
  const { profileData, authenticated } = useContext(authContext);
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [profileInfo, setProfileInfo] = useState([]);
  const location = useLocation();
  const [leaderboardGrouplist, setLeaderboardGrouplist] = useState();
  const [leaderboardDetailedList, setLeaderboardDetailedList] = useState();
  const [detailShow, setDetailShow] = useState(false);

  useEffect(() => {
    if ("uid" in localStorage) {
      setUid(localStorage.getItem("uid"));
    } else {
      setUid(null);
    }

    PostData(
      `${leaderboardlist}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setLeaderboardGrouplist(result);
      },
      (error) => {
      }
    );

    const userId = localStorage.getItem("userID");
    PostData(searchProfile + userId,
      "GET"
    ).then(
      (result) => {
        setProfileInfo(result);
      },
      (error) => {
      }
    );


    PostData(
      `${dashboard_story}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setStories(result.rows);
      },
      (error) => {
      }
    );

    PostData(
      `${dashboard_video}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setVideos(result.rows);
      },
      (error) => {
      }
    );

    PostData(
      `${followers}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setFollowers(result.followers);
      },
      (error) => {
      }
    );

    PostData(
      `${followings}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setFollowings(result.following);
      },
      (error) => {
      }
    );

    PostData(
      `${dashboard_bookmarks}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setSetBookmarks(result.rows);
      },
      (error) => {
      }
    );

    /*PostData(dashboard, "POST").then(
      (result) => {
        setData(result);
      },
      (error) => {
        console.log("error");
      }
    );*/
  }, []);
  const handleGreet = () => {
    var day = new Date();
    var hr = day.getHours();
    if (hr >= 0 && hr < 12) {
      return "Good Morning!";
    } else if (hr == 12) {
      return "Good Noon!";
    } else if (hr >= 12 && hr <= 17) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  }

  const groupDetailHandler = (nid, group) => {
    //setGroupName(group)
    PostData(
      `${leaderboard_Detailedlist}${nid}?_format=json`,
      "GET"
    ).then(
      (result) => {
        setLeaderboardDetailedList(result);
        setDetailShow(true);
        
      },
      (error) => {
      }
    );

  }

  const storyColumns = useMemo(() => [
    {
      Header: "Title",
      accessor: "title",
      url: true,
      Cell: ({ row }) => {

        return (
          row.original.status == "Published" ? 
          <Link  dangerouslySetInnerHTML={{__html:row.original.title}} to={{ pathname: `stories/${row.original.nid}` }}></Link>
          :
          <span dangerouslySetInnerHTML={{__html:row.original.title}}></span>
        )
      }
    },
    {
      Header: "Genre",
      accessor: "genre",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          row.original.status == "Rejected"
          ? <div className="txt-red">{row.original.status}{row.original.field_moderation_message && <span className="tipMsg">{row.original.field_moderation_message}</span>}</div>
          : row.original.status == "Draft"
          ? <div className="txt-orange">{row.original.status}{row.original.field_moderation_message && <span className="tipMsg">{row.original.field_moderation_message}</span>}</div>
          : <span>{row.original.status}</span>
        )
      }
    },
    {
      Header: "likes",
      accessor: "field_like",
    },
    {
      Header: "shares",
      accessor: "field_share",
    },
    {
      Header: "Views",
      accessor: "field_view",
    },
  ]);

  const bookmarkColumns = useMemo(() => [
    {
      Header: "Story/Video Title",
      accessor: "title",
      Cell: ({ row }) => {
        const pathType = row.original.format === "Story" ? "stories" : row.original.format === "Video" ? "videos" : "coinstories";
        return (
          <Link dangerouslySetInnerHTML={{__html:row.original.title}} to={{ pathname: `${pathType}/${row.original.nid}` }}></Link>
        )
      }
    },
    {
      Header: "Genre",
      accessor: "genre",
    },
    {
      Header: "User ID",
      accessor: "field_user_id",
    },
    {
      Header: "Format",
      accessor: "format",
    },
  ]);

  const groupColumns = useMemo(() => [
    {
      Header: "Group Name",
      accessor: "title",
      url: true,
      Cell: ({ row }) => {

        return (
          <NavLink to="#" onClick={() => groupDetailHandler(row.original.nid, row.original.name)}>{row.original.name}</NavLink>
        )
      }
    },
    {
      Header: "Owner",
      accessor: "authorName",
    },
  ]);

  const groupDetailColumns = useMemo(() => [
    {
      Header: "User Name",
      accessor: "username",
      Cell: ({ row }) => {
        return (
          <div className="position-relative">{row.original.username !== "" ? row.original.username : row.original.email}
          {row.original.username !== "" && row.original.status == "Pending" ?  <span className="batch">Pending</span> : ""}
         {row.original.username === "" ? <span className="batch">Pending</span> : ""}
          </div>

        )
      }
    },
    {
      Header: "Read Coins",
      accessor: "coins.read_coins",
    },
    {
      Header: "Write Coins",
      accessor: "coins.write_coins",
    },
    {
      Header: "Express Coins",
      accessor: "coins.express_coins",
    },
    {
      Header: "Other Coins",
      accessor: "coins.other_coins",
    },
    {
      Header: "Total Coins",
      accessor: "coins.overall_coins",
    },
  ]);


  const followHandler = () => {
    setFollowShow(true)
  }

  const followingHandler = () => {
    setFollowingShow(true)
  } 

  const handleModalClose = () => {
    setFollowShow(false);
  }
  const handleFollowingModalClose = () => {
    setFollowingShow(false);
  }

  return (
    <>
      <Helmet>
        <title>HopHigh - Dashboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <section className="container">
        <div className="row ">
          <div className="col-md-10 d-flex ">
            <Profilebox profileInfo={profileInfo} profileData={profileData} />
          </div>
          <div className="col-md-2">
            <Coins location={location} profileData={profileData} />
          </div>
        </div>
      </section>
      
      <section className="container">
        <Accordion defaultActiveKey="0" className="customAccordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="col-6">
                <h2 className="textColorSecondary mb-0">
                  <b>My Followers</b>
                </h2>
                <img src={BottomBorder} />
              </div>
              <div className="col-6">
                <h2 className="textColorSecondary mb-0">
                  <b>I am following</b>
                </h2>
                <img src={BottomBorder} />
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="follower-box col-6">
                  <a onClick={followHandler}>
                  {my_followers && my_followers !== null ? my_followers.length : "0"}
                  <span>Follower{my_followers && my_followers.length >= 1 ?  's' : ''}</span>
                  </a>
                  {/* <Batches data={my_followers} /> */}
                </div>
                <div className="following-box col-6">
                <a onClick={followingHandler}>
                  {my_followings && my_followings !== null ? my_followings.length : "0"}
                  <span>Following</span>
                  </a>
                  {/* <Batches data={my_followings} /> */}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <Titles title="My Stories" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="datagrid">
                {stories && stories.length !== 0 ?
                  <Table
                    data={stories}
                    columns={storyColumns}
                    setPageSize={`10`}
                  />
                  :
                  <NoContent nodatatext={`story`} />
                }
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <Titles title="My Videos" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="datagrid">
                {videos && videos.length !== 0 ?
                  <Table
                    data={videos}
                    columns={storyColumns} />
                  :
                  <NoContent nodatatext={`video`} />
                }
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <Titles title="Bookmarked stories/videos" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="datagrid">
                {bookmarks && bookmarks.length !== 0 ?
                  <Table
                    data={bookmarks}
                    columns={bookmarkColumns}
                  />
                  :
                  <NoContent nodatatext={`bookmark`} />
                }
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <Titles title="You are part of this group" />
            </Accordion.Header>
            <Accordion.Body>
            <div className={detailShow ? "d-none" : "d-block"}>
          {leaderboardGrouplist && leaderboardGrouplist.length !== 0 ?
           <Fade>
            <div className="datagrid">
              <Table
                data={leaderboardGrouplist}
                columns={groupColumns}
                setPageSize={`10`}
              />
            </div>
            </Fade>
            :
            <NoContent nodatatext={`nocreatedgroups`} />
          }
        </div>
        <div className={detailShow ? "d-block datagrid" : "d-none datagrid"}>
          {leaderboardDetailedList && leaderboardDetailedList.length !== 0 ?
            <>
            <Fade>
            <Table
              data={leaderboardDetailedList}
              columns={groupDetailColumns}
              setPageSize={`10`}
            />
            <div className="text-center mt-3">
            <button className="custButton hollow" onClick={()=>setDetailShow(false)}>Back</button>
            </div>
            </Fade>
            </>
            :
            <NoContent nodatatext={`groups`} />
          }
        </div>
             
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <AccountAssistance />
      </section>
      <Modal show={followShow} dialogClassName={`followModal hophighModal modal-dialog-centered`} size="md" onHide={handleModalClose}>
        <Modal.Header>
          <h3><span className="closebtn" onClick={handleModalClose}><i className={`bi bi-x-circle`} ></i></span></h3>
        </Modal.Header >
        <Modal.Body className="pt-0">
          <Titles title={`${my_followers && my_followers.length} people follow me`}></Titles>
          <Batches data={my_followers} />
        </Modal.Body>
      </Modal>
      <Modal show={followingShow} dialogClassName={`followModal hophighModal modal-dialog-centered`} size="md" onHide={handleFollowingModalClose}>
        <Modal.Header>
          <h3><span className="closebtn" onClick={handleFollowingModalClose}><i className={`bi bi-x-circle`} ></i></span></h3>
        </Modal.Header >
        <Modal.Body className="pt-0">
          <Titles title={`I am following ${my_followings && my_followings.length} people`}></Titles>
          <Batches data={my_followings} />
        </Modal.Body>
      </Modal>
    </>
  );
}
