import $ from 'jquery';

export function PostData(url, type, userData, csrf=false, auth, contentType='application/json') { 
      var $request;
      if ($request != null){ 
          $request.abort();
          $request = null;
      }

      $request = $.ajax({
        url: url,
        dataType: 'json',
        type: type,
        contentType: contentType,
        data: userData,
        beforeSend: function(xhr) {
            if(auth && "basicToken" in localStorage){
              var basicToken = localStorage.getItem('basicToken')
              xhr.setRequestHeader("Authorization", "Basic"+" "+basicToken);
            }
            if(csrf && "X-CSRF-Token" in localStorage){
              var csrfToken = localStorage.getItem('X-CSRF-Token');
              xhr.setRequestHeader("X-CSRF-Token", csrfToken);
            }
        },
        success: function(data) {
        },
        error: function(jqXHR, textStatus, errorThrown){
        }
    });
    return $request
 }