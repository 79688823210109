import React from "react"
import ContentLoader from "react-content-loader"

const LoaderCustom = (props) => (
  <div className="container d-flex mt-2 customloader-home">
  <ContentLoader 
    speed={2}
    viewBox="0 0 600 980"
    backgroundColor="#efecec"
    style={{ width:"100%", height:"auto"}}
    foregroundColor="#ecebeb"
    {...props} 
    >
    <rect x="7" y="2" rx="18" ry="18" width="576" height="211" /> 
    <rect x="271" y="150" rx="0" ry="0" width="1" height="0" /> 
    <rect x="50" y="229" rx="0" ry="0" width="2" height="1" /> 
    <rect x="47" y="231" rx="0" ry="0" width="1" height="0" /> 
    <rect x="41" y="234" rx="5" ry="5" width="506" height="7" /> 
    <rect x="40" y="279" rx="5" ry="5" width="506" height="7" /> 
    <rect x="40" y="264" rx="5" ry="5" width="506" height="7" /> 
    <rect x="41" y="249" rx="5" ry="5" width="506" height="7" /> 
    <rect x="8" y="318" rx="9" ry="9" width="263" height="104" /> 
    <rect x="293" y="320" rx="5" ry="5" width="288" height="7" /> 
    <rect x="292" y="365" rx="5" ry="5" width="288" height="7" /> 
    <rect x="292" y="350" rx="5" ry="5" width="288" height="7" /> 
    <rect x="293" y="335" rx="5" ry="5" width="288" height="7" /> 
    <rect x="291" y="388" rx="9" ry="9" width="82" height="29" /> 
    <rect x="10" y="454" rx="9" ry="9" width="132" height="122" /> 
    <rect x="11" y="590" rx="4" ry="4" width="130" height="7" /> 
    <rect x="11" y="602" rx="4" ry="4" width="80" height="7" /> 
    <rect x="156" y="453" rx="9" ry="9" width="132" height="122" /> 
    <rect x="157" y="589" rx="4" ry="4" width="130" height="7" /> 
    <rect x="157" y="601" rx="4" ry="4" width="80" height="7" /> 
    <rect x="302" y="451" rx="9" ry="9" width="132" height="122" /> 
    <rect x="303" y="587" rx="4" ry="4" width="130" height="7" /> 
    <rect x="303" y="599" rx="4" ry="4" width="80" height="7" /> 
    <rect x="450" y="450" rx="9" ry="9" width="132" height="122" /> 
    <rect x="451" y="586" rx="4" ry="4" width="130" height="7" /> 
    <rect x="451" y="598" rx="4" ry="4" width="80" height="7" /> 
    <rect x="7" y="627" rx="18" ry="18" width="578" height="122" /> 
    <rect x="9" y="772" rx="9" ry="9" width="132" height="122" /> 
    <rect x="10" y="908" rx="4" ry="4" width="130" height="7" /> 
    <rect x="10" y="920" rx="4" ry="4" width="80" height="7" /> 
    <rect x="155" y="771" rx="9" ry="9" width="132" height="122" /> 
    <rect x="156" y="907" rx="4" ry="4" width="130" height="7" /> 
    <rect x="156" y="919" rx="4" ry="4" width="80" height="7" /> 
    <rect x="301" y="769" rx="9" ry="9" width="132" height="122" /> 
    <rect x="302" y="905" rx="4" ry="4" width="130" height="7" /> 
    <rect x="302" y="917" rx="4" ry="4" width="80" height="7" /> 
    <rect x="449" y="768" rx="9" ry="9" width="132" height="122" /> 
    <rect x="450" y="904" rx="4" ry="4" width="130" height="7" /> 
    <rect x="450" y="916" rx="4" ry="4" width="80" height="7" />
  </ContentLoader>
  
  </div>
)

export default LoaderCustom;

