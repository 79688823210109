import React, { useContext, useEffect, useState, useRef } from 'react';
import BgQuotes from "../common/bgQuotes/bgQuotes";
import {PostData} from "../services/fetchData";
import {Api_Endpoints} from "../../endpoint";
import TopBanner from '../common/banner/topBanner';
import Stories from '../common/stories/stories';
import VideoBlock from '../common/video/videoBlock';
import YoutubeEmbed from "../common/video/YoutubeEmbed";
import { SwatchesPicker } from "react-color";
import Titles from '../common/titles/titles';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import LoaderCustom from '../common/loaders/loaderCustom';
import Modal from "react-bootstrap/Modal"
import * as material from 'material-colors'
import { authContext } from '../services/authContext';
import NotLoginCard from '../common/card/notLoginCard';
import {Helmet} from "react-helmet";
import { Tnc } from "../common/modalPopup/modalPopup";


const {express, personalDiary, storiesFrmSchool, storiesFrmGrade, restHoppingVideo, restExpressVideoGrade} = Api_Endpoints;

export default function Express() {
  const {authenticated} = useContext(authContext)
  const [data, setData] = useState();
  const [hopHighVideoData, setHopHighVideoData] = useState();
  const [videosFromSchool, setVideosFromSchool] = useState();
  const [videosFromGrade, setVideosFromGrade] = useState();

  let uid = ("uid" in localStorage) ? localStorage.getItem('uid') : 0; 
  let restURLParam = `&genre_id=all&grade_id=all&school=all&user_id=${uid}`;


  const getData = ()=>{
    Promise.all([
      init_API(),
      hopHigh_picks_video_API(),
      video_form_school_API(),
      video_from_grade_API()
      ]).then(function(newData){
      setData(newData[0]);
      setHopHighVideoData(newData[1]);
      setVideosFromSchool(newData[2]);
      setVideosFromGrade(newData[3]);
   });  
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const unsubscribe = getData(); //subscribe
    return ()=>{
      setData(null);
    }; //unsubscribe
  },[authenticated]);

 

  const init_API = function () {
    return PostData(express, "GET")
  }

  const hopHigh_picks_video_API = function (pageNo=0) {
    return PostData(restHoppingVideo+restURLParam+"&page="+pageNo, "GET")
  } 

  const video_form_school_API = function (pageNo=0) {
    return PostData(storiesFrmSchool+restURLParam+"&type=video&page="+pageNo, "GET")
  }

  const video_from_grade_API = function (pageNo=0) {
    return PostData(storiesFrmGrade+restURLParam+"&type=video&page="+pageNo, "GET")
  }

  const pagerFn = (val,type,reqparam)=>{
    if(reqparam){
      restURLParam =  `?genre_id=${reqparam.gener_id}&grade=${reqparam.grade}&school_name${reqparam.school_name}&nid=`
    }
    switch(type) {
      case "hophigh_picks_video":
        hopHigh_picks_video_API(val).then(function(data){
          setHopHighVideoData(data);
        });
        break;
      case "video_form_school":
        video_form_school_API(val).then(function(data){
          setVideosFromSchool(data);
        });
        break;
      case "video_from_grade":
        video_from_grade_API(val).then(function(data){
          setVideosFromGrade(data);
        });
        break;
      default:
        // code block
    }
    }
  return (
    <React.Fragment>
    <Helmet>
    <title>HopHigh - Express</title>
    <meta name="description" content="It’s not complete until you express it!"/>
    </Helmet>
      { data ?
      <ExpressPage authenticated={authenticated} data={data} hopHighVideoData={hopHighVideoData} videosFromSchool={videosFromSchool} videosFromGrade={videosFromGrade} pagerval={pagerFn} /> :
      <LoaderCustom></LoaderCustom> 
      }
    </React.Fragment>
  );
}

function ExpressPage ({data, hopHighVideoData, videosFromSchool, videosFromGrade, pagerval, authenticated}) {
  const {grade_List, genre_List, genre_vid_List} = useContext(authContext);
  const [color, setColor] = useState("#fafafa");
  const [value, setValue] = useState("");
  const [gradeList, setGradeList] = useState('');
  const [genreList, setGenreList] = useState('');
  const [show, setShow] = useState("");
  const [preview, setPreview] = useState("");
  const [pdescrp, setPdescrp] = useState({});
  const [tnc, setTnc] = useState(false);

  const { register, control, getValues, handleSubmit, reset, formState: { errors }, formState } = useForm();
  const { isDirty, isValid } = formState;

  const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const el2 = useRef();

  useEffect(() => {
    if(grade_List && genre_List){
    setGradeList(grade_List.filter(
      item => item.value !== "All"
    ));
    setGenreList(genre_List.filter(
      item => item.label !== "All"
    ));
  }
  },[genre_List, grade_List]);
  
  const swatchPickerStyles = {
    default: {
      picker: { // See the individual picker source for which keys to use
        boxShadow: 'none',
        height: 'auto',
      },      
      color: {
        width: '60px',
        height: '35px',
        cursor: 'pointer',
        background: color,
        marginBottom: '5px',
      },
      overflow: {
        height: 'auto',
        overflowY: 'auto',
      },
      body: {
        padding: '6px 0 6px 0px',
      },
    },
    group: {
      paddingBottom: '10px',
      width: '60px',
      float: 'left',
      marginRight: '15px',
    },
    
  }
  const onSubmit =(data)=>{
     if("uid" in localStorage){
    const uid = localStorage.getItem('uid');
    setPreview(false);
    const req = {
       "type": [{"target_id": "stories"}],
       "title": [{"value": data.titleOfTheVideo}],
       "field_genre": [{"target_id": data.genre.value}],
       "field_story_grade": [{"target_id": data.grade.value}],
       "field_bg_color": [{"value":  color}],      
       "field_short_description": [{"value": data.shortDescription, "format": "basic_html"}],       
       "field_video_link": [{"uri":data.shareyourvideo}],
       "field_stories_type": [{"value": "video" }],       
       "field_user_id": [
        {
            "target_id": uid
        }
      ]
      }
     const reqParam = JSON.stringify(req);
     PostData(personalDiary, "POST", reqParam, true, true).then((result) => {
     setShow(true);
     reset(result);
       },(error) => {
     }
     );
    }
  }
  const moduleArray = data.display_weight;
  const {modules} = data; 
  const {banner, storie, midbanner, footbanner, footer_banner_2} = data;


  const handleChange = (color, event) => {
    setColor(true);
  };

  const handleChangeComplete = (data) => {
    if (data.hex !== color) {
      setColor(data.hex)
    }
  }

  const youTubeGetID = (url)=> {
    var ID = '';
    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
      return ID;
  }

  const previewStory = ()=>{

    setShow(true);
    setPreview(true);
    const {shareyourvideo, titleOfTheVideo} = getValues();
    const videoid =youTubeGetID(shareyourvideo);
    const previewContent = {
      pre_title : titleOfTheVideo,
      pre_desc : videoid
    }
    
    setPdescrp(previewContent);
  } 

  
  const  handleClose = ()=>{
    setShow(false);
  }
  

  const componentList = moduleArray.map(
    (item, i) => {
      if (item == "banner" && modules.banner){
        return (
            <TopBanner data={banner} key={"s"+i} pageName={"Record Now"} click={()=> scrollToDiv(el2)}/>
            );
      } else if(item == "storie" && modules.storie){
        return(
        <section className="container" key={"t"+i}>
        <Stories data={storie} />  
        </section>
        )
      } else if(item == "hopping_video" && modules.hopping_video && hopHighVideoData){
        return(
        <section className="container" key={"v"+i}>
         <VideoBlock  data={hopHighVideoData} title="Hophigh Picks Video" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["school", "grade", "genre"]} type="hophigh_picks_video"/>
        </section>
        )
      } else if(item == "midbanner" && modules.midbanner){
        return ( 
          <section className="container-fluid" key={"A"+i}>
          <BgQuotes data={midbanner} />
          </section>
        )
      } else if(item == "video_form_school" && modules.video_form_school && videosFromSchool){
        return ( 
          <section className="container" key={"B"+i}>
          {authenticated ?
          <VideoBlock  data={videosFromSchool} title="Videos from your school" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["grade", "genre"]} type="video_form_school"/>
          : <NotLoginCard title="Videos from your school"/>
          }
          </section>
        )
      } else if(item == "footbanner" && modules.footbanner){
        return ( 
          <section className="container-fluid" key={"C"+i}>
          <BgQuotes data={footbanner} />
          </section>
        )
      } else if(item == "video_form_your_grade" && modules.video_form_your_grade && videosFromGrade){
        return ( 
          <section className="container" key={"f"+i}>
        {authenticated ?
        <VideoBlock  data={videosFromGrade} title="Videos from your grade" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["genre"]} type="video_from_grade"/>
        : <NotLoginCard title="Videos from your grade"/>
        }
        </section>
        )
      } else if(item == "footer_banner_2" && modules.footer_banner_2){
        return ( 
          <section className="container-fluid" key={"E"+i}>
          <BgQuotes  data={footer_banner_2}/>
          </section>
        )
      }
    }
  );
  return (
    <React.Fragment >
     {componentList}
     <section className="container" ref={el2}>
        <Titles title="Get going and upload a new video created by you"></Titles>
        <div className="px-lg-5">
        <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-12 col-md-4">
                <div>
                  <label className="form-label mb-0">
                    Grade<span className="text-danger"> *</span>
                  </label>
                  <p className="text-muted mb-2" style={{ fontSize: "0.7rem" }}>
                    Choose the grade for which the story is recommended
                  </p>
                </div>
                <div className="row">
                  <div className="col-12">
                  <Controller
                  name="grade"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select 
                  {...field} 
                    placeholder="Choose"
                    options={gradeList}
                    
                    className="react-sel-form"
                    classNamePrefix="react-sel-form"
                    value= {field.value || ""}
                  />}
                />  
                {errors.grade && errors.grade.type === "required" && <div className="text-danger">This is required</div>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 offset-md-2">
                <div>
                  <label className="form-label mb-0">
                    Genre<span className="text-danger"> *</span>
                  </label>
                  <p className="text-muted mb-2" style={{ fontSize: "0.7rem" }}>
                    Choose the genre of the story
                  </p>
                </div>
                <div className="row">
                  <div className="col-12">
                  <Controller
                  name="genre"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select 
                  {...field} 
                    placeholder="Choose"
                    options={genre_vid_List}
                    className="react-sel-form"
                    classNamePrefix="react-sel-form"
                    value= {field.value || ""}
                  />}
                /> 
                {errors.genre && errors.genre.type === "required" && <div className="text-danger">This is required</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
              <div className="form-floating mb-3">
        <input id="titleOfTheVideo"
        placeholder="Type the title of the video"
        className="form-control customforminput" {...register('titleOfTheVideo', { required: true, maxLength: 250 })} />
        <label htmlFor="titleOfTheVideo">Title of the story</label>

      {errors.titleOfTheVideo && errors.titleOfTheVideo.type === "required" && <span className="text-danger">This is required</span>}
      {errors.titleOfTheVideo && errors.titleOfTheVideo.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
            </div>
               </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
        <div className="form-floating mb-3">
        <input id="shortDescription"
        placeholder="Write a short Description of the video that will excite your readers."
        className="form-control customforminput" {...register('shortDescription', { required: true, maxLength: 350 })} />
        <label htmlFor="shortDescription" >Short Description of the video</label>
        </div>
      {errors.shortDescription && errors.shortDescription.type === "required" && <span className="text-danger">This is required</span>}
      {errors.shortDescription && errors.shortDescription.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
                 </div>
            </div>
            <label className="form-label">Cover thumbnail color</label>
            <SwatchesPicker
              styles={swatchPickerStyles}
              width="100%"
              color={color}
              colors={[[material.red['300']],
              [material.pink['300']],
              [material.purple['300']],
              [material.deepPurple['300']],
              [material.indigo['300']],
              [material.blue['300']],
              [material.lightBlue['300']],
              [material.cyan['300']],
              [material.teal['300']],
              [material.green['300']],
              [material.lightGreen['300']],
              [material.lime['300']],
              [material.yellow['300']],
              [material.amber['300']],
              [material.orange['300']],
              [material.deepOrange['300']],
              [material.brown['300']],
              [material.blueGrey['300']],
              ['#D9D9D9'],
              ['#FFEDDB']
            ]}
              onChangeComplete={handleChangeComplete}
              onChange={handleChange}
            >
            </SwatchesPicker>
            <div className="row">
              <div className="col-12">
              <div className="form-floating mb-3">
        <input id="shareyourvideo"
        placeholder="Paste the video link here"
        className="form-control customforminput" {...register('shareyourvideo', {required: true, maxLength: 1000 })} />
        <label htmlFor="shareyourvideo">Share your video link( Upload your videos on youtube or vimeo and share.)</label>
        </div>
      {errors.shareyourvideo && errors.shareyourvideo.type === "required" && <span className="text-danger">This is required</span>}
      {errors.shareyourvideo && errors.shareyourvideo.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
                 </div>
            </div>
            <div className="row g-3 mt-2">
              <div className="col-12 col-lg-6">
              <div className="form-check">
      <input id="tnc" type="checkbox" className="form-check-input" {...register('tnc', { required: true})} />
      <label className="form-check-label" style={{fontSize: "0.7rem"}}>
      <a href="#" onClick={()=>setTnc(true)}>*Terms &amp; Condtions apply.</a>
      </label>
      {errors.tnc && errors.tnc.type === "required" && <span className="text-danger">This is required</span>}
      </div> 
              </div>
              <div className="col-12 col-lg-6 ">
                <button
                  type="submit"
                  className="custButton ms-md-5 float-end float-md-end"
                >
                  Submit Video
                </button>
                <button type="button" onClick={previewStory} disabled={!isDirty || !isValid} className="custButton hollow float-start float-md-end">
                  Preview Video
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {tnc && 
      <Tnc hideFn={()=>setTnc(false)}/>
      }  
      <Modal show={show} dialogClassName={`previewVideoModal hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
     <Modal.Header  >
     <h3><span className="closebtn"  onClick={handleClose}><i className={`bi bi-x-circle`} ></i></span></h3>
      </Modal.Header >
      <Modal.Body className="pt-0">
      {preview ?
      <> 
       <Titles title={pdescrp.pre_title}/>
      <br/>
       <div> <YoutubeEmbed embedId={pdescrp.pre_desc} /></div>
      </>
      :
      <> 
      <i className="bi bi-check-circle-fill text-success  h1"></i>
      <h3>Your video successfully submitted</h3>
      </>
       }
      </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}