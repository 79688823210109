import React, { useEffect, useState } from 'react';
import CardBlock from "../common/card/cardBlock";
import {PostData} from "../services/fetchData";
import {Api_Endpoints} from '../../endpoint';

export default function SimilarEpubStories({genre, nid, type, tid, genreid}) {
  const {restEpubStories} = Api_Endpoints;
    const [similarStory, setSimilarStory] = useState();   
    useEffect(() => {
        similarStoriesCall();
        window.scrollTo({
          behavior: "smooth",
          top: 0
        });
      
      },[genre, nid, type])    

      const reqParam = {
        "field_genre_value":genre,
        "field_grade_value":"",
        "nid":"",
        "field_stories_type_value":"" 
      }  

      const similarStoriesCall = (pageNo=0)=>{
        PostData(`${restEpubStories}/${nid}?&page=${pageNo}&genre_id=${genreid}`, "GET", false).then(result => {
        setSimilarStory(result);
        },
        error => {
        })
      }

      
          
    return (
    <>
    <section className="container">
    {similarStory && 
    <CardBlock data={similarStory} title="Similar Stories" noDataTitle="similar" cardFn={similarStoriesCall}/>  
    }
    </section>
    </>
    )
}