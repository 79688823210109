import React, { useState, useEffect } from "react";
import { useForm} from "react-hook-form";
import Titles from "../common/titles/titles";
import { PostData } from "../services/fetchData";
import TopBanner from "../common/banner/topBanner";
import { Api_Endpoints} from "../../endpoint";
import LoaderCustom from "../common/loaders/loaderCustom";
import {Helmet} from "react-helmet";
import Alert from "react-bootstrap/Alert";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function ContactUs() {
  const [data, setData] = useState();
  const { contact_us, contact_form } = Api_Endpoints;

  useEffect(() => {
    window.scrollTo(0, 0);
    PostData(contact_us, "GET").then(
      (result) => {
        setData(result);
      },
      (error) => {
      }
    );
  }, []);

  
  


  return <>
  <Helmet>
    <title>Contact HopHigh</title>
    <meta name="description" content="Do drop us a line with your feedback or send us your love. Just fill in the form below"/>
    </Helmet>
  {data ? <ContactUsPage data={data} /> : <LoaderCustom></LoaderCustom> }</>;
}
function ContactUsPage({ data }) {
  const moduleArray = data.display_weight;
  const { register, control, handleSubmit, reset, formState: { errors }, formState } = useForm();
  const { contact_us, contact_form } = Api_Endpoints;
  const [show, setShow] = useState(false);

  const onSubmit = (data) => {
    const req = {
      "contact_form":[
          {
              "target_id":"contact_us"
          }
      ],
      "name":[
          {
              "value":data.name
          }
      ],
      "mail":[
          {
              "value":data.email
          }
      ],
      "subject":[
          {
              "value":"HopHigh contact form"
          }
      ],
      "message":[
          {
              "value":data.description
          }
      ],
      "field_mobile_number": [
          {
              "value" : data.mobile
          }
      ],
      "recipient": [
          {
              "target_id" : 1
          }
      ]
  }
    const reqParam = JSON.stringify(req);

    PostData(contact_form, "POST", reqParam, true, true).then((result) => {

      reset()
      setShow(true)
      setTimeout(function () {
  
        // Closing the alert
        setShow(false)
    }, 3000);
    }, (error) => {
    }
    );


  }
  const {
    banner,
    address
  } = data;



  return (
    <>
  
        <TopBanner data={banner} />
  
 
      <section className="container ">
        <Titles title="Contact Us"></Titles>
        <div className="px-lg-5">
        <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-md-6">
              <div className="form-floating mb-3">
        <input id="name"
        placeholder="name"
        className="form-control customforminput" {...register('name', { required: true, maxLength: 200 })} />
        <label htmlFor="name">Name <span className="text-danger">*</span></label>
        </div>
      {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
      {errors.name && errors.name.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
            </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
              <div className="form-floating mb-3">
        <input id="email"
        type="email"
        placeholder="Email"
        className="form-control customforminput" {...register('email', { required: true, maxLength: 80 })} />
                    <label htmlFor="email" >Email <span className="text-danger">*</span></label>
                    </div>
      {errors.email && errors.email.type === "required" && <span className="text-danger">This is required</span>}
      {errors.email && errors.email.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
                </div>
                <div className="col-6">
              <div className="form-floating mb-3">
        <input id="mobile"
        type="text"
        placeholder="mobile"
        className="form-control customforminput" {...register('mobile', { required: true, maxLength: 10 })} />
                    <label htmlFor="email" >Mobile No</label>
                    </div>
      {errors.mobile && errors.mobile.type === "required" && <span className="text-danger">This is required</span>}
      {errors.mobile && errors.mobile.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
                </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
              <div className="form-floating mb-3">
        <input id="description"
        type="text"
        placeholder="Description"
        className="form-control customforminput" {...register('description', { required: true })} />
                    <label htmlFor="email" >Description <span className="text-danger">*</span></label>
                    </div>
      {errors.description && errors.description.type === "required" && <span className="text-danger">This is required</span>}
      {/* {errors.description && errors.description.type === "maxLength" && <span className="text-danger">Max length exceeded</span> } */}
                </div>
            </div>
            <div className="row g-3 mt-2">
              <div className="col-12 ">
                <button
                  type="submit"
                  className="custButton ms-md-5 float-start float-md-end">
                  Submit
                </button>
      
              </div>
            </div>
          </form>
        </div>
      </section>
      { address &&
      <section className="container">
      <Titles title={address.children.content.title}></Titles>
      <p className="mt-3" dangerouslySetInnerHTML={{__html: address.children.content.body}}></p>
      </section>}
      <ToastContainer className="position-fixed p-3" position={`bottom-center`}>
        <Toast onClose={() => setShow(false)} bg="success" show={show} delay={7000} autohide >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Thank you for contacting us!</strong>
          </Toast.Header>
          <Toast.Body>
          <h6>We're looking forward to helping as quickly as we can. In the meantime, enjoy reading stories... </h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
