import React, { useEffect, useState, useContext, } from "react";
import { useHistory } from "react-router-dom";

import BgQuotes from "../common/bgQuotes/bgQuotes";
import { PostData } from "../services/fetchData";
import { Api_Endpoints } from "../../endpoint";
import TopBanner from "../common/banner/topBanner";
import CardBlock from "../common/card/cardBlock";
import VideoBlock from "../common/video/videoBlock";
import Titles from "../common/titles/titles";
import { authContext } from "../services/authContext";
import { base_url } from "../../endpoint";
import ModalPop from "../common/modalPopup/modalPopup";
import LoaderCustom from "../common/loaders/loaderCustom";
import { Helmet } from "react-helmet";
const { hophigh_picks, search_filter_story, search_filter_video, searchProfile, postSocial } = Api_Endpoints;

export default function Search(props) {

  const {
    match: { params },
    location: { state },
  } = props;

  const { selectedUserId } = state.userSel;
  const { selectedSchool } = state.schoolSel;
  const { selectedGrade } = state.gradeSel;
  const { selectedGenre } = state.genreSel;
  const { id } = state;
  const { type } = state;
  const [data, setData] = useState();
  const [filteredStory, setFilteredStory] = useState();
  const [filteredVideo, setFilteredVideo] = useState();
  const [profileInfo, setProfileInfo] = useState([]);
  const [wishlistCount, setWishlistCount] = useState();
  const [wishlistStatus, setwishlistStatus] = useState();
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [showLoader, setShowLoader] = useState(true);
  /*const storyURL = `${search_filter_story}?uid=${selectedUserId}&school_name=${selectedSchool}&grade=${selectedGrade}&genre=${selectedGenre}&page=0`;
  const videoURL = `${search_filter_video}?uid=${selectedUserId}&school_name=${selectedSchool}&grade=${selectedGrade}&genre=${selectedGenre}&page=0`;*/
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowLoader(true);
    if ("uid" in localStorage) {
      setUid(localStorage.getItem("uid"));
    } else {
      setUid(null);
    }
    let isSubscribe = true;
    
    Promise.all([
      story_filter_API(),
      video_filter_API(),
    ]).then(function (newData) {
      setFilteredStory(newData[0]);
      setFilteredVideo(newData[1]);
      setShowLoader(false);
    });
    if(selectedUserId) {
      PostData(`${searchProfile}${selectedUserId}&current_user_id=${uid}`, "GET").then((profdata)=>{
        setProfileInfo(profdata)
      })
    } 
    return function cleanUp() {
      isSubscribe = false
    }
  }, [id, type, wishlistCount]);





  const profile_API = function () {
    return PostData(`${searchProfile}${selectedUserId}&current_user_id=${uid}`, "GET");
  };


  const story_filter_API = function (pageNo = 0) {
    return PostData(`${search_filter_story}?uid=${selectedUserId}&school=${selectedSchool ? selectedSchool : "all"}&grade_id=${selectedGrade ? selectedGrade : "all"}&genre_id=${selectedGenre ? selectedGenre : "all"}&page=${pageNo}`, "GET");
  };

  const video_filter_API = function (pageNo = 0) {
    return PostData(`${search_filter_video}?uid=${selectedUserId}&school=${selectedSchool ? selectedSchool : "all"}&grade_id=${selectedGrade ? selectedGrade : "all"}&genre_id=${selectedGenre ? selectedGenre : "all"}&page=${pageNo}`, "GET");
  };

  const pagerFn = (val, type) => {
    switch (type) {
      case "hophigh_picks_story":
        story_filter_API(val).then(function (data) {
          setFilteredStory(data);
        });
        break;
      case "hophigh_picks_video":
        video_filter_API(val).then(function (data) {
          setFilteredVideo(data);
        });
        break;
      default:
      // code block
    }
  }
  
  return (
    <React.Fragment>
      <Helmet>
        <title>HopHigh - Search</title>
        <meta name="description" content="Search by user-ID or stories" />
      </Helmet>
      {showLoader &&
      <LoaderCustom />
      }
      {filteredStory && filteredVideo && !showLoader &&
        <SearchPage
        uid={uid}
        filteredStory={filteredStory}
        filteredVideo={filteredVideo}
        profileInfo={profileInfo}
        pagerval={pagerFn}
        type={type}
        followCount={wishlistCount}
        followStatus={wishlistStatus}
      /> 
      }
    </React.Fragment>
  );
}

function SearchPage({ uid, filteredStory, filteredVideo, pagerval, profileInfo, type, followCount, followStatus }) {
  const [wishlistCount, setWishlistCount] = useState(profileInfo.length !== 0 ? profileInfo[0].field_wishlist : "");
  const [wishlistStatus, setwishlistStatus] = useState(profileInfo.length !== 0 ? profileInfo[0].wishlist_profile_status : "");
  const { authenticated } = useContext(authContext);
  const [modal, setModal] = useState();

  useEffect(() => {
    //console.log(profileInfo[0].wishlist_profile_status)
  }, [wishlistCount, wishlistStatus])

  const folFn = (result) => {
    setWishlistCount(result.wishlist)
    setwishlistStatus(result.field_wishlist_profile_status)
  }

  const followFn = () => {
    // console.log(wishlistStatus)
    if (authenticated) {
      const req = {
        to: profileInfo[0].name,
        uid: localStorage.getItem('uid'),
        type: "wishlist",
        status: wishlistStatus == "1" ? false : true
      };

      const reqParam = JSON.stringify(req);
      PostData(
        postSocial,
        "Post",
        reqParam,
        true,
        true
      ).then(
        (result) => {
          folFn(result);
        },
        (error) => {
        }
      );
    } else {
      setModal(true);
    }
  }

  const modalfn = () => {
    setModal(!modal)
  }

  const handleGreet = () => {
    var day = new Date();
    var hr = day.getHours();
    if (hr >= 0 && hr < 12) {
      return "Good Morning!";
    } else if (hr == 12) {
      return "Good Noon!";
    } else if (hr >= 12 && hr <= 17) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  };

  return (
    <React.Fragment>
      {profileInfo.length !== 0 && type.rdTypeVal == "userId" &&
        <section className="container">
          <div className="row ">
            <div className="col-12 col-md-10  d-flex justify-content-between">
              <div className="image-item">
                <img className="profileimg" src={`${base_url}${profileInfo[0].user_picture}`} /></div>
              <div className="flex-fill align-self-center ps-3 position-relative">
                <h3 className="purpleColor"><b> {profileInfo[0].field_name}</b>
                {profileInfo[0].verified === "1" ? <i class="bi bi-patch-check-fill"></i> : ""}
                </h3>
                <p className="mb-1">Grade : <b>{profileInfo[0].field_grade}</b></p>
                <p className="d-block d-md-none mb-1">Followers: <b>{wishlistCount}</b></p>
           {Number(profileInfo[0].uid) !== Number(uid) ?
                <a className="d-inline d-md-none custButton mobileSize hollow" onClick={followFn}>{wishlistStatus == "1" ? "Unfollow" : "Follow"}</a>
            : ""
              }
                </div>
            </div>
            <div className="d-none d-md-block col-md-2 text-center">
              <div className="float-md-end purpleColor text-center">
                <h1 className="mb-0 purpleColor"><b>{wishlistCount}</b></h1>
                {Number(profileInfo[0].uid) !== Number(uid) ?
                <a className="custButton hollow" onClick={followFn}>{wishlistStatus == "1" ? "Unfollow" : "Follow"}</a>
                : "Followers"
              }
              </div>
            </div>
          </div>
        </section>
      }
      <section className="container">
        {filteredStory.pager.total_items !== 0 ?
          <CardBlock
            data={filteredStory}
            title="Stories"
            noDataTitle="search"
            type="hophigh_picks_story"
            cardFn={(val, type) => pagerval(val, type)}
          />
          :
          <>
            <Titles title="Stories" />
            <h3 className="mt-2 text-center">No stories found</h3>
          </>
        }
      </section>
      <section className="container">
        {filteredVideo.pager.total_items !== 0 ?
          <VideoBlock
            data={filteredVideo}
            title="Videos"
            noDataTitle="search"
            type="hophigh_picks_video"
            cardFn={(val, type) => pagerval(val, type)}
          />
          :
          <>
            <Titles title="Videos" />
            <h3 className="mt-2 text-center">No videos found</h3>
          </>
        }
      </section>
      {modal &&
        <ModalPop modal={modal} modalFn={modalfn} storyType={"socialModal"} shortDescription="To like, bookmark, follow the story please login with your registered ID or create a new ID" />
      }
    </React.Fragment>
  );
}
