import React from 'react';
import { Api_Endpoints, base_url } from "../../../endpoint";


export default function Profilebox({ profileInfo, profileData }) {
    const handleGreet = () => {
        var day = new Date();
            var hr = day.getHours();
            if (hr >= 0 && hr < 12) {
              return "Good Morning!";
            } else if (hr == 12) {
              return "Good Noon!";
            } else if (hr >= 12 && hr <= 17) {
              return "Good Afternoon!";
            } else {
              return "Good Evening!";
            }
      }
    return (
        <>
          {profileInfo.length !== 0 &&
           <div className="image-item">
            <img className="profileimg" src={`${base_url}${profileInfo[0].user_picture}`}/></div>
          }
            <div className="align-self-center ps-3">
              <h3 className="purpleColor">
                <b>{handleGreet()} {profileData ? profileData.field_name[0].value : ""}</b>
              </h3>
              <p>Let's Begin the day with a new story</p>
            </div>
        </>
    )
}
