import React from "react";
import {Helmet} from "react-helmet";
 

export default function PageNotFound() {
 return (
	 <>
	  <Helmet>
    <title>HopHigh - Page not found</title>
    <meta name="description" content="Page not found"/>
    </Helmet>
    <section className="page_404">
	<div className="container">
		<div className="row">	
		<div className="col-sm-12 ">
		<div className="col-8 offset-2 text-center">
		<div className="four_zero_four_bg">
			<h1 className="text-center ">404</h1>
		
		
		</div>
		
		<div className="contant_box_404">
		<h3 className="h2">
		Look's like you're lost
		</h3>
		
		<p>the page you are looking for is not available!</p>
		
		<a className="custButton" href="https://www.hophigh.in">Go to Home</a>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
</>
 )   
}
