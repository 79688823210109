import React, { useState, useEffect, useContext, useRef } from "react";
import { PostData } from "../services/fetchData";
import { Api_Endpoints, base_url } from "../../endpoint";
import Titles from "../common/titles/titles";
import { useForm, Controller, set } from "react-hook-form";
import Select from "react-select";
import Accordion from 'react-bootstrap/Accordion';
import AccountAssistance from '../common/accountAssistance/account-assistance';
import { authContext } from "../services/authContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Coins from "../common/coins/coins";
import ImageCropper from "../common/imageCropper/imageCropper"
import { getOrientation } from 'get-orientation/browser'
import { getRotatedImage } from '../common/imageCropper/canvasUtils';

const uid = localStorage.getItem('uid');
const { my_profile, uploadeProfilePic, postProfilePic, changePassword, searchProfile, lostPasswordReset } = Api_Endpoints;

export default function Profile() {
  const fileUpload = useRef(null);
  const { profileData, handleAuthentication, div_list, grade_List, school_List} = useContext(authContext);
  const {profileImage, setProfileImage, userprofilefn} = useContext(authContext);
  const [data, setData] = useState();
  const [show, setShow] = useState();
  const [showChangePwd, setShowChangePwd] = useState();
  const [showImageCropper, setShowImageCropper] = useState();
  const [disable, setDisable] = useState(true);
  const [personalDetails, setPersonalDetails] = useState(true);
  const { register, control, handleSubmit, setValue, formState: { errors }, formState } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 } } = useForm();
  const [base_64, setBase_64] = useState();
  const [gradeList, setGradeList] = useState();
  const [gradeTypeList, setGradeTypeList] = useState();
  const [schoolList, setSchoolList] = useState(school_List);
  const [imageType, setImageType] = useState('');
  const [images, setImages] = React.useState();
  const [imageName, setImageName] = useState('');
  const [password, setpassword] = useState("");
  const [schoolCompList, setSchoolCompList] = useState(school_List);
  const [schoolName, setSchoolName] = useState("");
  const [error, setError] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [changePwdShown, setChangePwdShown] = useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [success, setSuccess] = useState("");
  const [otherSchool, setOtherSchool] = useState(false);
  const [proPwdShown, setProPwdShown] = useState(false);

  const maxNumber = 1;
  const location = useLocation();

  console.log(profileData)

  useEffect(()=>{
    if(div_list && grade_List){
    setGradeList(grade_List.filter(
      item => item.value !== "All"
    ));
    setGradeTypeList(div_list.filter(
      item => item.value !== "All"
    ));
    setSchoolList(school_List.filter(
      item => item.value !== "All"
    ));
    setSchoolCompList(school_List);
  }
  },[grade_List, div_list]);
  const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }
  const readFile = (file) => {
    setImageName(file.name)
    setImageType(file.type)
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  const handleCameraClick = () => {
   
    fileUpload.current.click();
  }
  
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setImageSrc(imageDataUrl)
      setShowImageCropper(true)
    }
  }

  const croppedResultHandler = (dataURL) => {
    setImages(dataURL)
    const base64Data = dataURL.replace(/^data:image\/\w+;base64,/, "");
    setBase_64(base64Data);
    setShowImageCropper(false)
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleChangePwdVisiblity = () => {
    setChangePwdShown(changePwdShown ? false : true);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userID");
    if (base_64) {
      setImages(images)
      imageAPICall();
    } else {
      PostData(searchProfile + userId,
        "GET"
      ).then(
        (result) => {
         setImages(base_url + result[0].user_picture)
        },
        (error) => {
        }
      );
    }
  },[base_64]);

  const toggleProfPwdVisiblity = () => {
    setProPwdShown(proPwdShown ? false : true);
  };

 /* const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImageName(imageList[0].file.name)
    setImageType(imageList[0].file.type)

    const base64Data = imageList[0].data_url.replace(/^data:image\/\w+;base64,/, "");
    setBase_64(base64Data);
  };*/


  const imageAPICall = () => {
    const imgReq = {
      "_links": {
        "type": {
          "href": "https://api.hophigh.in/rest/type/file/image"
        }
      },
      "filename": [
        {
          "value": imageName
        }
      ],
      "filemime": {
        "value": imageType
      },
      "uri": [
        {
          "value": `public://${imageName}`
        }
      ],
      "type": {
        "target_id": "file"
      },
      "data": [
        {
          "value": base_64
        }
      ]
    }

    const reqParam = JSON.stringify(imgReq);

    PostData(uploadeProfilePic, "POST", reqParam, true, true, "application/hal+json").then((result) => {
      postProfileImage(result);
    }, (error) => {
     // console.log("error");
    }
    );
  }

  const postProfileImage = (result) => {
    const imgPost = JSON.stringify({
      "uid": [
        {
          "value": localStorage.getItem("uid")
        }
      ],
      "uri": [
        {
          "value": result.uri[0].value
        }
      ]
    })
    PostData(postProfilePic, "POST", imgPost, true, true).then((result) => {
      //setProfileImage(`/sites/default/files/${imageName}`);
      userprofilefn(uid)
    }, (error) => {
     // console.log("error");
    }
    );
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseChangePwd = () => {
    setShowChangePwd(false);
    setSuccess("");
    setValue2("newpassword", "");
    setError("");
  }

  const handleImageCropperClose = () => {
    setShowImageCropper(false);
  }

  const handleSubmitCode = (data) => {
    const userId = localStorage.getItem("uid");
      setError('');
      const req = {
            "name": profileData.name[0].value,
            "pass": data.password,
            "new_pass": data.newpassword
      };
      console.log(data)
      const reqParam = JSON.stringify(req);

      PostData(lostPasswordReset, "POST", reqParam, true, true).then((result) => {
        setSuccess("Password changed successfully!!");
          setTimeout(function(){ 
            setShowChangePwd(false)
          }, 1500);
      }, (error) => {
        setError(error)
      }
      );
     
  };

  const handleGreet = () => {
    var day = new Date();
    var hr = day.getHours();
    if (hr >= 0 && hr < 12) {
      return "Good Morning!";
    } else if (hr == 12) {
      return "Good Noon!";
    } else if (hr >= 12 && hr <= 17) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  }
  const other = register('other', { required: false })

  const onSubmit = (data) => {
    console.log(data)
    const req = {
      "field_birth_date": [
        {
          "value": new Date(data.birthday).toISOString().split(".")[0] + "+00:00"
        }
      ],
      "field_country": [
        {
          "value": data.country
        }
      ],
      "field_gender": [
        {
          "value": data.genderRd
        }
      ],
      "field_grade": [
        {
          "value": data.grade.value ? data.grade.value : profileData.field_grade[0].value
        }
      ],
      "field_user_grade": [
        {
          "target_id": data.grade.value ? data.grade.value : profileData.field_grade[0].value
        }
      ],
      "field_grade_type": [
        {
          "value": data.gradeType.value ? data.gradeType.value : profileData.field_grade_type[0].value
        }
      ],
      "field_i_am": [
        {
          "value": data.iama
        }
      ],
      "field_name": [
        {
          "value": data.name
        }
      ],
      "field_school_id": [
        {
          "value": data.uniqueSchoolId
        }
      ],
      "field_school": [
        {
          "target_id": data.schoolName.value
        }
      ],
      "field_school_location": [
        {
          "value": data.schoolLocation
        }
      ],
      "field_school_name": [
        {
          "value": data.otherSchoolName
        }
      ],
      "mail": [
        {
          "value": data.email
        }
      ],
      "field_diary_pass": [
        {
          "value": data.field_diary_pass
        }
      ]
    }
    const reqParam = JSON.stringify(req);

    PostData(my_profile + uid + "?_format=json", "PATCH", reqParam, true, true).then((result) => {
      setData(result);
      setShow(true);
      setDisable(true);
      handleAuthentication(uid);
    }, (error) => {
    }
    );
  };

  useEffect(() => {
    var profileArray;
    if (profileData) {
      console.log(profileData)
      profileArray = [
        { name: 'name', value: profileData.field_name[0].value },
        { name: 'userid', value: profileData.name[0].value ? profileData.name[0].value : "" },
        { name: 'email', value: profileData.mail.length > 0 ? profileData.mail[0].value : "" },
        { name: 'genderRd', value: profileData.field_gender.length > 0 ? profileData.field_gender[0].value : "" },
        { name: 'iama', value: profileData.field_i_am.length > 0 ? profileData.field_i_am[0].value : "" },
        { name: 'birthday', value: profileData.field_birth_date.length > 0 ? new Date(profileData.field_birth_date[0].value) : "" },
        { name: 'uniqueSchoolId', value: profileData.field_school_id.length > 0 ? profileData.field_school_id[0].value : "" },
        { name: 'schoolName', value: profileData.field_school.length > 0 ? profileData.field_school[0].target_id.toString() : "" },
        { name: 'otherSchoolName', value: profileData.field_school_name.length > 0 ? profileData.field_school_name[0].value : "" },        
        { name: 'schoolLocation', value: profileData.field_school_location.length > 0 ? profileData.field_school_location[0].value : "" },
        { name: 'country', value: profileData.field_country.length > 0 ? profileData.field_country[0].value : "" },
        { name: 'grade', value: profileData.field_user_grade.length > 0 ? profileData.field_user_grade[0].target_id.toString() : "" },
        { name: 'gradeType', value: profileData.field_grade_type.length > 0 ? profileData.field_grade_type[0].value : "" },
        { name: 'field_diary_pass', value: profileData.field_diary_pass.length > 0 ? profileData.field_diary_pass[0].value : "" }
      ]
      profileArray.forEach(({ name, value }) => setValue(name, value))
    }
  }, [profileData]);

  
  const editProfileFn = (val) => {
    setDisable(!disable);
  }

  const handleShow = () => setShowChangePwd(true);

 
const setSchoolLocationHandler = (selectedVal) => {
  schoolCompList.forEach((d,i) =>{ 
    const iCount = ++i;
    setSchoolName(selectedVal);
    setValue('schoolName', selectedVal);
    if(d.value == selectedVal.value){
      setValue('schoolLocation', d.address)
    }
    /*if(iCount == newData[0].length){
    schoolList_options.push({"value":"Other", "label":"Other"});
  }*/
  })
}

const otherschoolFn = (val)=>{
  setOtherSchool(val);
  setValue('schoolLocation', "");
  setValue('schoolName', "")
}
  return (
    <>
      <Helmet>
        <title>HopHigh - User Profile</title>
        <meta name="description" content="User profile information" />
      </Helmet>
      <section className="container">
        <div className="row ">
          <div className="col-md-10  d-flex">
            <input
              type="file"
              ref={fileUpload}
              onChange={(event)=>onFileChange(event)}
              onClick={(event)=> {
                event.target.value = null
              }}
              style={{ display: "none" }}
              accept="image/*" />
            <div className="upload__image-wrapper">
              <div className=" position-relative">
                <div className="image-item">
                {images && <img src={images} alt="" width="100" className="profileimg" />}
                </div>
                <div className="image-item__btn-wrapper">
                  <i className="bi bi-camera camericon" style={{ cursor: "pointer" }} onClick={handleCameraClick}></i>
                  {/*  <button type="button" ><i class="bi bi-camera camericon"></i></button>*/}
                </div>
              </div>
            </div>
            <div className="align-self-center ps-3">
              <h3 className="purpleColor"><b>{handleGreet()} {profileData ? profileData.field_name[0].value : ""}</b></h3>
              <p>Let's Begin the day with a new story</p>
              <div className="btn btn-link text-decoration-none"  onClick={handleShow}>Change Password</div>
            </div>
          </div>
          <div className="col-md-2">
          <Coins location={location} profileData={profileData} />
          </div>
        </div>
      </section>
      <section className="container">
        <Accordion defaultActiveKey="0" className="customAccordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header><Titles title="Personal Details" /></Accordion.Header>
            <Accordion.Body>
              <div className="row ">
                <div className="px-md-2">
                  <div className="text-end">
                    <span className="cursor-pointer" onClick={() => editProfileFn("personalDetails")}><i className="bi bi-pencil pe-1 fs-5"></i>Edit Profile</span>
                  </div>
                  <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="form-floating mb-md-3">
                          <input id="name"
                            placeholder="name"
                            className="form-control customforminput" disabled={disable} {...register('name', { required: true })} />
                          <label htmlFor="name">Name <span className="text-danger">*</span></label>
                        </div>
                        {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-md-3">
                          <input id="userid"
                            placeholder="User Id"
                            className="form-control customforminput" disabled={disable} {...register('userid', { required: true })} />
                          <label htmlFor="userid">User Id <span className="text-danger">*</span></label>
                        </div>
                        {errors.userid && errors.userid.type === "required" && <span className="text-danger">This is required</span>}
                      </div>
                    </div>
                    <div className="row mb-md-3">
                      <div className="col-md-6">
                        <div className="form-floating mb-md-3">
                          <input id="email"
                            type="email"
                            placeholder="Email"
                            className="form-control customforminput" disabled={disable} {...register('email', { required: true })} />
                          <label htmlFor="email" >Email <span className="text-danger">*</span></label>
                        </div>
                        {errors.email && errors.email.type === "required" && <span className="text-danger">This is required</span>}
                        {errors.email && errors.email.type === "maxLength" && <span className="text-danger">Max length exceeded</span>}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="birthday">Birthday</label>
                        <Controller
                          name="birthday"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => <DatePicker
                            disabled={disable}
                            className="form-control customforminput"
                            selected={field.value}
                            onChange={(date) => setValue("birthday", date)}
                          />}
                        />
                        {errors.birthday && errors.birthday.type === "required" && <span className="text-danger">required</span>}
                      </div>
                    </div>
                    <div className="row mb-md-3">
                      <div className="col-md-6">
                        <div><label className="form-label">Gender </label></div>
                        <div className="form-check form-check-inline">
                          <input type="radio" id="male" className="form-check-input" disabled={disable} value="male" {...register('genderRd', { required: true })} />
                          <label className="form-check-label" htmlFor="male">Male</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input type="radio" id="female" className="form-check-input" disabled={disable} value="female" {...register('genderRd', { required: true })} />
                          <label className="form-check-label" htmlFor="female">Female</label>
                        </div>
                        {errors.genderRd && errors.genderRd.type === "required" && <span className="text-danger">required</span>}
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-md-3">
                          <input
                            type={passwordShown ? "text" : "password"}
                            id="passcode"
                            placeholder="passcode"
                            className="form-control customforminput" disabled={disable} {...register('field_diary_pass', { required: true, pattern: /^^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,30}$/i })} />
                          <label htmlFor="name">Diary Passcode <span className="text-danger">*</span></label>
                          <i className={`eyeIcon bi ${passwordShown ? "bi-eye-slash" : "bi-eye"}`} onClick={togglePasswordVisiblity}></i>
                        </div>
                        {errors.field_diary_pass && errors.field_diary_pass.type === "required" && <span className="text-danger">This is required</span>}
                        {errors.field_diary_pass && errors.field_diary_pass.type === "pattern" && <span className="text-danger">7 to 30 characters, atleast one numeric digit and a special character</span> }
                      </div>
                    </div>
                    <div className="row g-3 mt-2">
                      <div className="col-12">
                        <button
                          type={disable ? "button" : "submit"}
                          disabled={disable}
                          className="custButton ms-md-5  float-md-end">
                          Update Profile
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header><Titles title="My School Details" /></Accordion.Header>
            <Accordion.Body>
              <div className="row ">
                <div className="px-md-2">
                  <div className="text-end">
                    <span className="cursor-pointer" onClick={() => editProfileFn("schoolDetails")}><i className="bi bi-pencil pe-1 fs-5"></i>Edit Profile</span>
                  </div>
                  <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-3  mb-md-3">
                      <div className="col-md">
      <label htmlFor="schoolName" className={otherSchool ? "d-none": "form-label mb-0"}>School Name</label>
      <div className="row g-3 ">
       <div className="col-md-8 ">
        {otherSchool ?
        <>
        <div className="form-floating " >
         <input type="textbox" id="otherSchoolName" placeholder="Other School" className="form-control customforminput"  {...register('otherSchoolName',  { required: true, maxLength: 60 })} />
         <label htmlFor="otherSchoolName">Other School</label>
         </div>
         {errors.otherSchoolName && errors.otherSchoolName.type === "required" && <span className="text-danger">This is required</span>}
        </>
        :
        <>
        {schoolList &&
        <Controller
        name="schoolName"
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
        <Select 
        {...field}
        placeholder="Select School Name"
        classNamePrefix="react-select"
        className="react-select-container selectBorder0"
        onChange={(value) => setSchoolLocationHandler(value)}
        options={schoolList}
        isDisabled={disable}
        value={schoolList.find((c) => {
          return c.value === field.value
        })}
        />}
       />}
      {errors.schoolName && errors.schoolName.type === "required" && <div className="text-danger">This is required</div>}
       </>
       }
       </div>
        
       
         <div className="col-md-4 position-relative">
         <div className="form-check position-absolute" style={{bottom: "0px"}}>
      <input id="other" type="checkbox"  className="form-check-input" ref={other.ref} disabled={disable} onChange={e => otherschoolFn(e.target.checked)}  />
      <label className="form-check-label" htmlFor="other">
      Other
      </label>
      {errors.other && errors.other.type === "required" && <span className="text-danger">This is required</span>}
         </div>
         </div>
         </div>
      
      </div>
                      <div className="col-md-6">
                        <div>
                          <label className="form-label mb-0">Grade <span className="text-danger"> *</span></label>
                        </div>
                        <div className="row g-3 mb-md-3">
                          <div className="col-6">
                            {gradeList &&
                              <Controller
                                name="grade"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Select
                                  {...field}
                                  placeholder="Std"
                                  className="react-sel-form"
                                  classNamePrefix="react-sel-form"
                                  isDisabled={disable}
                                  options={gradeList}
                                  value={gradeList.find((c) => {
                                    return c.value === field.value
                                  }
                                  )}
                                />}
                              />}
                            {errors.grade && errors.grade.type === "required" && <div className="text-danger">This is required</div>}
                          </div>
                          <div className="col-6">
                            {gradeTypeList &&
                              <Controller
                                name="gradeType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Select
                                  {...field}
                                  placeholder="Div"
                                  className="react-sel-form"
                                  classNamePrefix="react-sel-form"
                                  isDisabled={disable}
                                  options={gradeTypeList}
                                  value={gradeTypeList.find((c) => c.value === field.value)}
                                />}
                              />}
                            {errors.gradeType && errors.gradeType.type === "required" && <div className="text-danger">This is required</div>}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md">
      <div className="form-floating">
      <input 
      id="schoolLocation" 
      className="form-control customforminput" 
      readOnly = {!otherSchool}
      disabled={disable}
      placeholder="School Location" {...register('schoolLocation', { required: true, maxLength: 255 })} />
      <label htmlFor="schoolLocation">School Location</label>
      </div>
      {errors.schoolLocation && errors.schoolLocation.type === "required" && <span className="text-danger">required</span>}
      {errors.schoolLocation && errors.schoolLocation.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      </div>
                      
                      <div className="col-md-6">
                        <div className="form-floating mb-md-3">
                          <input id="country"
                            type="country"
                            placeholder="Country"
                            className="form-control customforminput" disabled={disable} {...register('country', { required: true })} />
                          <label htmlFor="country" >Country <span className="text-danger">*</span></label>
                        </div>
                        {errors.country && errors.country.type === "required" && <span className="text-danger">This is required</span>}
                      </div>
                    </div>
                    <div className="row g-3 mt-2">
                      <div className="col-12 ">
                        <button
                          type="submit"
                          disabled={disable}
                          className="custButton ms-md-5 float-start float-md-end">
                          Update Profile
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <AccountAssistance />
      </section>
      <Modal show={showImageCropper} dialogClassName={`CropperModal hophighModal modal-dialog-centered`} size="md" onHide={handleImageCropperClose}>
        <Modal.Header  >
          <h3><span className="closebtn" onClick={handleImageCropperClose}><i className={`bi bi-x-circle`} ></i></span></h3>
        </Modal.Header >
        <Modal.Body className="pt-0 text-center">
          <ImageCropper imageUpload={imageSrc} croppedResultHandler={croppedResultHandler} />
        </Modal.Body>
      </Modal>

      <Modal show={show} dialogClassName={`hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
        <Modal.Header  >
          <h3><span className="closebtn" onClick={handleClose}><i className={`bi bi-x-circle`} ></i></span></h3>
        </Modal.Header >
        <Modal.Body className="pt-0 text-center">
          <i className="bi bi-check-circle-fill text-success  h1"></i>
          <h3>Profile Successfully Updated</h3>
        </Modal.Body>
      </Modal>
      <Modal
        show={showChangePwd}
        dialogClassName="hophighModal"
        size="md"
        onHide={handleCloseChangePwd}
      >
        <Modal.Header>
          <h3 className="closebtn" onClick={handleCloseChangePwd}>
            <i className="bi bi-x-circle"></i>
          </h3>
        </Modal.Header>
        <Modal.Body>
          {error &&
            <div className="alert alert-danger text-danger" role="alert">
              <span><i className="bi bi-x-circle-fill text-danger"></i> {error}</span>
            </div>}
            {success &&
            <div className="alert alert-success text-success" role="alert">
              <span><i className="bi bi-check-circle-fill text-success"></i> {success}</span>
            </div>}
          <Titles title={`Change Password`}></Titles>
          <form onSubmit={handleSubmit2(handleSubmitCode)} className="customForm py-4 d-flex flex-column align-content-center justify-content-center">
             <div className="form-floating mb-3">
              <input
                type={proPwdShown ? "text" : "password"} 
                id="password"
                className="form-control customforminput"
                placeholder="Current Password"
                {...register2('password', { required: true })}
              />
               <i className={`eyeIcon bi ${proPwdShown ? "bi-eye-slash" : "bi-eye"}`} onClick={toggleProfPwdVisiblity}></i>
              <label htmlFor="password">
                Enter your current Password
                <span className="text-danger ps-1">*</span>
              </label>
            </div>
            {errors2.newpassword && errors2.newpassword.type === "required" && <span className="text-danger">This is required</span>}
            <div className="form-floating mb-3">
              <input
               type={changePwdShown ? "text" : "password"} 
                id="confirmpassword"
                className="form-control customforminput"
                placeholder="New Password"
                {...register2('newpassword', { required: true, maxLength: 30, pattern: /^^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,30}$/i})}
              />
               <i className={`eyeIcon bi ${changePwdShown ? "bi-eye-slash" : "bi-eye"}`} onClick={toggleChangePwdVisiblity}></i>
              <label htmlFor="confirmpassword">
               Enter your new password
                <span className="text-danger ps-1">*</span>
              </label>
            </div>
            {errors2.newpassword && errors2.newpassword.type === "required" && <span className="text-danger">This is required</span>}
            {errors2.newpassword && errors2.newpassword.type === "maxLength" && <span className="text-danger">Max length exceeded</span>}
            {errors2.newpassword && errors2.newpassword.type === "pattern" && <span className="text-danger">7 to 30 characters, atleast one numeric digit and a special character</span>}
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="custButton mt-3"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

