import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Api_Endpoints} from "../../../endpoint";
import { PostData } from "../../services/fetchData";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useForm, Controller } from "react-hook-form";

export default function Report({nid, user}) {
  const { report } = Api_Endpoints;
  const [showDisplay, setShowDisplay] = useState("none");
  const [showToast, setShowToast] = useState(false);
  const [desc, setDesc] = useState("");
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    formState,
  } = useForm();

  const handleClose = () => {
    setShowDisplay("none");
  }
  const showModalHandler = () =>{
    showDisplay === "block" ? setShowDisplay("none") : setShowDisplay("block")
  }

  const descChangeHandler = (e) => {
    setDesc(e.target.value)
  }
  const onSubmitHandler = (data) => {
    const req = {
    
      "type": [
          {
              "target_id": "report"
          }
      ],
      "title": [
          {
              "value": "Report"
          }
      ],
      "body": [
          {
              "value": data.desc
          }
      ],
      "field_user_id": [
          {
              "target_id": user
          }
      ],
        "field_node_id": [
          {
              "target_id": nid
          }
      ]
  }
    const reqParam = JSON.stringify(req);

    PostData(report, "POST", reqParam, true, true).then((result) => {


      setDesc("")
      setShowToast(true)
      setTimeout(() => {
        setShowDisplay("none");
      
      }, 1000);
      setValue('desc',""); 

    }, (error) => {
    }
    );


  }

  return (
    <>
      <div className="ms-auto reportIcon">
        <span><a onClick={showModalHandler}><i className="bi bi-exclamation-triangle"></i><span>Report</span></a></span>
        <div className="reportPop" style={{"display": showDisplay}}>
      <h6>Why are you reporting this story? <span>Please mention your reason in the below box with your User ID</span></h6>
      <form className="customForm"  onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row mb-3">
              <div className="form-floating">
                <input id="desc"
                  placeholder="Description"
                  onChange={descChangeHandler}
                  className="form-control customforminput" 
                  {...register('desc', { required: true})}
                  />
                <label htmlFor="name">Description <span className="text-danger">*</span></label>

              </div>
              {errors.desc && errors.desc.type === "required" && <span className="text-danger">This is required</span>}
            </div>
            <div className="row">
              <div className="col-12 ">
                <button
                  type="submit"
                  className="custButton ms-md-5 float-start float-md-end">
                  Report
                </button>
      
              </div>
            </div>
      </form>
      </div>
      </div>
      <ToastContainer className="position-fixed p-3" position={`bottom-center`}>
        <Toast onClose={() => setShowToast(false)} bg="success" show={showToast} delay={3000} autohide >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Report</strong>
          </Toast.Header>
          <Toast.Body>
          <h6>Your report has been sent successfully. We will take the required action at the earliest </h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}