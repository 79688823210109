import React from 'react';
import { NavLink } from "react-router-dom";

export default function Coins({ profileData, location }) {

    return (
        <>
            <div className="float-end purpleColor smartbox text-center">
                <h1 className="mb-0">
                    <b>{profileData && profileData.field_coin_count[0].value}</b>
                </h1>
                <p className="mb-0">Coins Earned</p>
                <NavLink 
                to={{
                    pathname: "/coindetails",
                    state: { prevPath: location.pathname.substr(1,) }
                  }}
                exact={true} 
                className="textColorPrimary text-decoration-underline" 
                style={{ fontSize: "12px" 
                }}>
                    Click here to see details
                </NavLink>
            </div>
        </>
    )
}
