import React, { useContext, useEffect, useState } from "react";
import Titles from "../titles/titles";
import Card from "../card/card";
import CardSearch from "../card/cardSearch";
import { authContext } from "../../services/authContext";
import NoDataCard from '../card/noDataCard';
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import { Spinner } from 'react-bootstrap';

export default function CardBlock({
  data,
  title,
  noDataTitle,
  form,
  loadMore = true,
  type,
  coinStory,
  cardFn,
}) {
  const { authenticated } = useContext(authContext);
  const [newdata, setNewdata] = useState(data.rows);
  const [pager, setPager] = useState(data.pager);
  const [currentPage, setCurrentPage] = useState();
  const [showBtn, setShowBtn] = useState();
  const [searchToggle, setsearchToggle] = useState(false);
  const [cardReqParam, setCardReqParam] = useState();
  const [spin, setSpin] = useState(false);

  const filterData = (item, reqParam) => {
    setCardReqParam(reqParam);
    if(item.rows){

    }
   
    setNewdata(item.rows);
    setPager({current_page:0});
    if(item.pager.next_page == 0){
      setShowBtn(false);
    } else {
      setShowBtn(true);
    }
  };

  useEffect(() => {
    setPager(data.pager);

    const page = data.pager.current_page;
    if (data.pager.current_page >= 1) {
      setNewdata((prevArray) => [...prevArray, ...data.rows]);
    } else {
      setNewdata(data.rows);
    }
    if (data.pager.next_page == 0) {
      setCurrentPage(page);
      setShowBtn(false);
    } else {
      setShowBtn(true);
    }
    return ()=>{
      setSpin(false);
    }
  }, [data]);
  
  const pagerFn = () => {
    setSpin(true);
    const page = pager.current_page + 1;
    setCurrentPage(page);
    cardFn(page, type, cardReqParam);
  };
  return (
    <div>
      <div style={{'position':'relative'}} className="row">
      <Fade>  <Titles title={title}></Titles></Fade>
        {form &&
        <span className="searchfilter d-block d-md-none" onClick={()=>setsearchToggle(!searchToggle)}><i className={`bi ${searchToggle ? "bi-funnel-fill": "bi-funnel"}`}></i></span>
        }
      </div>
    
      <div className={searchToggle ? "d-block": "d-none d-md-block" }>
      {form && <CardSearch genreType="Story" form={form} filter={filterData} showHidefn={()=>setsearchToggle(false)} type={type} />}
      </div>
     
      <div className="row my-3 gap-0 gap-md-0">
        {newdata.length !== 0 ?
          newdata.map((item, i) => {
            return (
<Fade bottom key={i}>
              <div className="col-3 g-2 g-md-4 col-md-6 my-2 col-lg-3" key={i}>
                <Card 
                  coinStory={coinStory}                 
                  data={item}
                  type={type}
                  like_status={item.like_status}
                  bookmark_status={item.bookmark_status}
                  wishlist_status={item.wishlist_status}
                  view_status={item.view_status}
                  author={item.author}
                  bgcolor={item.field_bg_color}
                  like={item.field_like}
                  bookmark={item.field_bookmarks}
                  share={item.field_share}
                  view={item.field_view}
                  wishlist={item.field_wishlist}
                  textColor={item.field_text_color}
                  key={i}
                  index={i}
                  title={item.title}
                  description={item.field_short_description}
                  genre={item.field_genre}
                  genre_bg_color={item.field_color}
                  nid={item.nid}
                  field_stories_type={item.field_stories_type}
                />
              </div></Fade>
            );
          }) :  <Fade><NoDataCard nodatatext={noDataTitle}/></Fade>}
      </div>
      
      {loadMore && showBtn && (
      <div className="d-flex align-content-center justify-content-center pt-3 pb-3  mx-auto">
      <button type="button" className="custButton hollow" onClick={pagerFn}>
      { spin ?
     <>
     <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      />
     <span className="ps-2">Loading...</span>
     </>
     :
      <span>LOAD MORE STORIES</span>
      }
     </button>
     </div>
     )}
      
    </div>
  );
}
