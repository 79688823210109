import React, { useState, useContext, useEffect, useRef  } from "react";
import { useForm, Controller, useController  } from "react-hook-form";
import Stories from "../common/stories/stories";
import Titles from "../common/titles/titles";
import { PostData } from "../services/fetchData";
import { SwatchesPicker } from "react-color";
import TopBanner from "../common/banner/topBanner";
import { Api_Endpoints} from "../../endpoint";
import Select from "react-select";
import LoaderCustom from "../common/loaders/loaderCustom";
import { authContext } from "../services/authContext";
import Modal from "react-bootstrap/Modal";
import * as material from 'material-colors';
import Editor from "./editor";
import FileBase64 from "../common/fileBase64/filebase64";
import ModalPop from "../common/modalPopup/modalPopup";
import {Helmet} from "react-helmet";
import { Tnc } from "../common/modalPopup/modalPopup";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';

const {write, personalDiary, grade_list ,genre_list, uploadeProfilePic, postStoryPic} = Api_Endpoints;
const ButtonCustomList = [
  ['undo', 'redo'],
  ['font', 'fontSize'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list'],
  ['link'],
  ['fullScreen', 'showBlocks', 'codeView']
];
export default function Write() {
  const [data, setData] = useState();

  useEffect(() => {
  window.scrollTo(0, 0);
  PostData(write, "GET").then(
      (result) => {
        setData(result);
      },
      (error) => {
      }
    );
    return()=>{
      setData(null);
    }
  }, []);

  return <>
  <Helmet>
    <title>HopHigh - Write</title>
    <meta name="description" content="Write for fun, or build a career, HopHigh always stands by your side."/>
    </Helmet>
  {data ? <WritePage data={data} /> : <LoaderCustom></LoaderCustom> }
  </>;
}

function WritePage({ data}) {
  const {authenticated, grade_List, genre_List} = useContext(authContext);
  const inputRef = useRef(null);
  const moduleArray = data.display_weight;
  const [color, setColor] = useState("#fafafa");
  const [show, setShow] = useState("");
  const [preview, setPreview] = useState("");
  const [pdescrp, setPdescrp] = useState({});
  const [modal, setModal] = useState();
  const [uriValue, setUriValue] = useState('');
  const [files, setFiles] = useState();
  const [base_64, setBase_64] = useState();
  const [imageType, setImageType] = useState('');
  const [images, setImages] = React.useState([]);
  const [imageName, setImageName] = useState('');
  const [gradeList, setGradeList] = useState(grade_List);
  const [genreList, setGenreList] = useState(genre_List);
  const [tnc, setTnc] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const { register, control, getValues, handleSubmit, setError, reset, formState: { errors }, formState, setValue, clearErrors } = useForm();
  const { isDirty, isValid } = formState;

  const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const el2 = useRef();
  const {description, titleOfTheStory, shortDescription, genre } = getValues();

  useEffect(() => {
    if(grade_List && genre_List){
    setGradeList(grade_List.filter(
      item => item.value !== "All"
    ));
    setGenreList(genre_List.filter(
      item => item.label !== "All"
    ));
  }
  },[grade_List, genre_List]);

  useEffect(()=>{
    if(base_64){
     // setImages([{data_url:base_64}])
      imageAPICall();
    } 
  },[base_64]);

 

  const getFiles = (files)=> {

   const baseURL = files.base64;
    setImages(baseURL)
  
    setImageName(files.name)
   setImageType(files.type)
   const base64Data = files.base64.replace(/^data:image\/\w+;base64,/, "");
  setBase_64(base64Data);
 // imageAPICall()
  }

  const imageAPICall = () => {
    const imgReq = {
     "_links": {
         "type": {
             "href": "https://api.hophigh.in/rest/type/file/image"
         }
     },
     "filename": [
         {
             "value": imageName
         }
     ],
     "filemime": {
         "value": imageType
     },
     "uri": [
         {
             "value": `public://${imageName}`
         }
     ],
     "type": {
         "target_id": "file"
     },
     "data": [
         {
             "value": base_64
         }
     ]
 }
   
 const reqParam = JSON.stringify(imgReq);

 PostData(uploadeProfilePic, "POST", reqParam, true, true, "application/hal+json").then((result) => {

  // postProfileImage(result);
  setUriValue(result.uri[0].value)
  setFiles(result.uri[0].url)
     },(error) => {
     }
   );
 }

 const postStoryImage = (result) => {

   const imgPost = JSON.stringify({
     "nid": [
           {
               "value": result.nid[0].value
           }
       ],
       "uri": [
           {
               "value": uriValue
           }
       ]
   })

   if(uriValue !== ""){
   PostData(postStoryPic, "POST", imgPost, true, true).then((result) => {
       },(error) => {
       }
     );

    }
 }


  const onSubmit = (data) => {
    console.log(data)
    if(authenticated){
    const uid = localStorage.getItem('uid');
    setPreview(false);
    const req = {
      "type": [{"target_id": "stories"}],
      "title": [{"value": data.titleOfTheStory}],
      "body": [{"value": data.description,"format": "full_html"}],
      "field_story_grade": [{"target_id": data.grade.value}],
      "field_genre": [{"target_id": data.genre.value}],      
      "field_bg_color": [{"value":  color}],
      "field_images":[
        {
          "target_type": "file",
          "url": files
       }
      ],
      "field_short_description": [{"value": data.shortDescription, "format": "basic_html"}],
      "field_stories_type": [{"value": "story"}],
      "field_user_id": [
        {
            "target_id": uid
        }
    ],  
    }
    const reqParam = JSON.stringify(req);
   PostData(personalDiary, "POST", reqParam, true, true).then((result) => {
    postStoryImage(result)
    setShowToast(true);
    reset(result);
    setValue("description", "");
    setError("description", {
      type: "minLength",
      message: "",
    });
    clearErrors();
      },(error) => {
        console.log(error)
      }
    );
   }else{
    setModal(true);
   }
  };

  const previewStory = ()=>{
    setShow(true);
    setPreview(true);
    const previewContent = {
      pre_title : titleOfTheStory,
      pre_shortDesc : shortDescription,
      pre_desc : description
    }
    setPdescrp(previewContent);
  } 

  const  handleClose = ()=>{
    setShow(false);
  }
  

  const swatchPickerStyles = {
    default: {
      picker: { // See the individual picker source for which keys to use
        boxShadow: 'none',
        height: 'auto',
      },      
      color: {
        width: '60px',
        height: '35px',
        cursor: 'pointer',
        background: color,
        marginBottom: '5px',
      },
      overflow: {
        height: 'auto',
        overflowY: 'auto',
      },
      body: {
        padding: '6px 0 6px 0px',
      },
    },
    group: {
      paddingBottom: '10px',
      width: '60px',
      float: 'left',
      marginRight: '15px',
    },
    
  }

  const {
    banner,
    storie
  } = data.children;

  const handleChange = (color, event) => {
    setColor(true);
  };

  const modalfn = ()=>{
    setModal(!modal)
  }

  const handleChangeComplete = (data) => {
    if (data.hex !== color) {
      setColor(data.hex)
    }
  }

  console.log(errors);

  return (
    <>
      <TopBanner data={banner} pageName={"WRITE NOW"} click={()=> scrollToDiv(el2)}/>
      {storie &&
      <section className="container pt-0">
        <Stories data={storie} />
      </section>}
      <section className="container" ref={el2}>
        <Titles title={"Start a new story"}></Titles>
        <div className="px-lg-5">
          
        <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
        <div className="col-12 col-md-4">
                <div>
                  <label className="form-label mb-0">
                    Grade<span className="text-danger"> *</span>
                  </label>
                  <p className="text-muted mb-2" style={{ fontSize: "0.7rem" }}>
                    Choose the grade for which the story is recommended
                  </p>
                </div>
                <div className="row">
                  <div className="col-12">
                  <Controller
                  name="grade"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select 
                  {...field} 
                    placeholder="Choose"
                    options={gradeList}
                    className="react-sel-form"
                    classNamePrefix="react-sel-form"
                    value= {field.value || ""}
                  />}
                />  
                {errors.grade && errors.grade.type === "required" && <div className="text-danger">This is required</div>}
                </div>
                </div>
              </div>
              <div className="col-12 col-md-4 offset-md-2">
                <div>
                  <label className="form-label mb-0">
                    Genre<span className="text-danger"> *</span>
                  </label>
                  <p className="text-muted mb-2" style={{ fontSize: "0.7rem" }}>
                    Choose the genre of the story
                  </p>
                </div>
                <div className="row">
                  <div className="col-12">
                  <div className="row">
                  <div className="col-12">
                  <Controller
                  name="genre"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select 
                  {...field} 
                    placeholder="Choose"
                    options={genreList}
                    className="react-sel-form"
                    classNamePrefix="react-sel-form"
                    value= {field.value || ""}
                  />}
                /> 
                {errors.genre && errors.genre.type === "required" && <div className="text-danger">This is required</div>}
                </div>
                </div>
                  </div>
                </div>
              </div>
        </div> 
        
            <div className="row mb-3">
              <div className="col-md-6">
              <div className="form-floating mb-3">
        <input id="titleOfTheStory"
        placeholder="Type the title of the story"
        className="form-control customforminput" {...register('titleOfTheStory', { required: true, maxLength: 250 })} />
        <label htmlFor="titleOfTheStory">Title of the story</label>
        </div>
      {errors.titleOfTheStory && errors.titleOfTheStory.type === "required" && <span className="text-danger">This is required</span>}
      {errors.titleOfTheStory && errors.titleOfTheStory.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
            </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
              <div className="form-floating mb-3">
        <input id="shortDescription"
        placeholder="Write a short Description of the story that will excite your readers."
        className="form-control customforminput" {...register('shortDescription', { required: true, maxLength: 350 })} />
                    <label htmlFor="shortDescription" >Short Description of the story</label>
                    </div>
      {errors.shortDescription && errors.shortDescription.type === "required" && <span className="text-danger">This is required</span>}
      {errors.shortDescription && errors.shortDescription.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
                </div>
            </div>
            <div className="row mb-3">
            <div className="col-12">
            <label className="form-label">Upload Story Image</label>
            <div className="input-group mb-3">
            <FileBase64
        multiple={ false }
        className="form-control"
        onDone={getFiles} />
            </div>
            </div>
            </div>
            <label className="form-label">Cover thumbnail color</label>
            <SwatchesPicker
              styles={swatchPickerStyles}
              width="100%"
              color={color}
              colors={[[material.red['300']],
              [material.pink['300']],
              [material.purple['300']],
              [material.deepPurple['300']],
              [material.indigo['300']],
              [material.blue['300']],
              [material.lightBlue['300']],
              [material.cyan['300']],
              [material.teal['300']],
              [material.green['300']],
              [material.lightGreen['300']],
              [material.lime['300']],
              [material.yellow['300']],
              [material.amber['300']],
              [material.orange['300']],
              [material.deepOrange['300']],
              [material.brown['300']],
              [material.blueGrey['300']],
              ['#D9D9D9'],
              ['#FFEDDB']
            ]}
              onChangeComplete={handleChangeComplete}
              onChange={handleChange}>
            </SwatchesPicker>
            <label className="form-label">
              Story<span className="text-danger ps-1">*</span>
            </label>
            <input type="hidden" {...register('hidden_id', {})} value={1}/>
            <Editor
              name="description"
              control={control}
              placeholder="Write a Description..."
              genreName={genre}
            />
        {errors.description && errors.description.type === "required" && <span className="text-danger">This is required</span>}
        {errors.description && errors.description.type === "validate" && <span className="text-danger">This is required</span>}
        {errors.description && errors.description.type === "minLength" && <span className="text-danger">{`Minimum ${ genre && genre.value == "19" ? "350" : "500"} words required`}</span>}
        
        <div className="row g-3 mt-2">
        <div className="col-12 col-lg-6">
        <div className="form-check">      
        <input id="tnc" type="checkbox" className="form-check-input" {...register('tnc', { required: true})} />
        <label className="form-check-label" style={{fontSize: "0.7rem"}}>
        <a href="#" onClick={()=>setTnc(true)}>*Terms &nbsp; Condtions apply.</a>
        </label>
        {errors.tnc && errors.tnc.type === "required" && <span className="text-danger">This is required</span>}
        </div> 
               </div>
              <div className="col-12 col-lg-6 ">
                <button
                  type="submit"
                  className="custButton ms-md-5 float-end float-md-end">
                  Submit Story
                </button>
                <button type="button" onClick={previewStory} disabled={!isDirty || !isValid}  className="custButton hollow float-start float-md-end">
                Preview Story
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Modal show={show} dialogClassName={`previewModal hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
     <Modal.Header  >
     <h3><span className="closebtn"  onClick={handleClose}><i className={`bi bi-x-circle`} ></i></span></h3>
      </Modal.Header >
      <Modal.Body className="pt-0">
      <Titles title={pdescrp.pre_title}/>
      <br/>
      <div dangerouslySetInnerHTML={{__html:pdescrp.pre_desc}}></div>
      </Modal.Body>
      </Modal>
      {modal &&
      <ModalPop modal={modal} modalFn={modalfn} storyType={"socialModal"} shortDescription="To write stories please Login with your registered ID or create a new ID"/>
      }
      {tnc && 
      <Tnc hideFn={()=>setTnc(false)}/>
      }  
      <ToastContainer className="position-fixed p-3" position={`bottom-center`}>
        <Toast onClose={() => setShowToast(false)} bg="success" show={showToast} delay={3000} autohide >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>
          <h6>Your story successfully submitted </h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
