import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Redirect, Link, Switch, browserHistory } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from "../src/components/home/home";
import Read from "../src/components/read/read";
import Express from "../src/components/express/express";
import Write from "../src/components/write/write";
import PersonalDiary from './components/personal-diary/personal-diary';
import Stories from './components/common/stories/single-stories';
import Videos from './components/common/video/single-video';
import Header from "../src/components/common/header/header";
import Footer from "../src/components/common/footer/footer";
import HopHighPicks from './components/hophigh_picks/hophigh-picks';
import CoinStories from './components/coin-stories/coin-stories';
import Auth from './components/services/authAccess';
import ContactUs from './components/contact-us/contact_us';
import AboutUs from './components/about-us/about-us';
import Profile from './components/profile/profile';
import Dashboard from './components/dashboard/dashboard';
import Leaderboard from './components/dashboard/leaderboard';
import Search from './components/search/search';
import Planner from './components/myplanner/myplanner';
import PrivateRoute from './components/services/privateRoute';
import PageNotFound from './components/pageNotFound/pagenotfound';
import PrivacyPolicy from './components/privacypolicy/privacypolicy';
import Epubstories from './components/coin-stories/epub-coin-stories';
import EpubReaderstories from './components/coin-stories/epubReader-coinStories';
import { Helmet } from "react-helmet";
import CoinDetails from './components/coin-details/coin-details';
import "./css/customstyle.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Route from 'react-router-hooks';
import { PostData } from './components/services/fetchData';
import { Api_Endpoints } from './endpoint';
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';
import JoinGroup from './components/common/misc/joinGroup';
import EpubViewerBox from './components/coin-stories/epub-viewer'

const { readStory } = Api_Endpoints;

const Root = () => {

  const [show, setShow] = useState(false);
  const [totalCoins, setTotalCoin] = useState();
  const [showToast, setShowToast] = useState(false);


  const req = {
    "uid": localStorage.getItem('uid'),
    "nid": '',
    "status": "stop"
  }

  const handleClose = () => {
    setShow(false);
  }

  const reqParam = JSON.stringify(req);

  const stopFn = () => {
    PostData(`${readStory}`, "Post", reqParam, true, true).then(
      (result) => {
        if (result.reading_points) {
          setTotalCoin(result.reading_points);
          setShowToast(true)
        }
      },
      (error) => {
      }
    );
  }

  return (
    <>
      <App>
        <BrowserRouter>
          <Helmet>
            <meta charSet="utf-8" />
            <title>HopHigh</title>
            <meta name="description" content="Read on and Dream on! At HopHigh, we set no limits to your imagination." />
          </Helmet>
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/read" exact component={Read} />
            <Route path="/express" exact component={Express} />
            <Route path="/write" exact component={Write} />
            <Route path="/personaldiary" exact component={PersonalDiary} />
            <Route path="/hophighpicks" exact component={HopHighPicks} />
            <Route path="/coinstories" exact component={CoinStories} />
            {/* <Route  path="/epubstories" exact component={Epubstories}/> */}
            <Route path="/stories/:nid" component={Stories} onLeave={stopFn} />
            <Route path="/videos/:nid" component={Videos} />
            <Route path="/join/:gid" component={JoinGroup} />
            <Route path="/contactus" exact component={ContactUs} />
            <Route path="/privacypolicy" exact component={PrivacyPolicy} />
            <Route path="/aboutus" exact component={AboutUs} />
            <PrivateRoute path="/coinstories/:nid" component={EpubReaderstories}/>
            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/leaderboard" exact component={Leaderboard} />
            <PrivateRoute path="/coindetails" exact component={CoinDetails} />
            <Route path="/search" location={{ state: { type: { rdTypeVal: "stories" } } }} exact component={Search} />
            <Route path="/user" location={{ state: { type: { rdTypeVal: "stories" } } }} exact component={Search} />
            <PrivateRoute path="/planner" exact component={Planner} />
            <Route component={PageNotFound} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </App>
      {/* <Modal show={show} dialogClassName={`hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
  <Modal.Header  >
  <h3><span className="closebtn"  onClick={handleClose}><i className={`bi bi-x-circle`} ></i></span></h3>
  </Modal.Header >
  <Modal.Body className="pt-0 text-center">
  <h5>Great!! Total Coins you achieved is</h5>
  <h3>{totalCoins}</h3>
  </Modal.Body>
  </Modal> */}
      <ToastContainer className="position-fixed p-3" position={`bottom-end`}>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Coins Earned</strong>
          </Toast.Header>
          <Toast.Body>
            <div className="pyro">
              <div className="before"></div>
              <div className="after"></div>
            </div>
            <h6>Great!! <span>{totalCoins}</span> Coins achieved for reading </h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

ReactDOM.render(<div><Auth><Root /></Auth></div>, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
