import React from "react";
import Titles from "../titles/titles";
import { NavLink } from "react-router-dom";
import {Api_Endpoints, domain_name} from "../../../endpoint";

export default function Stories({data, cssClass}) {
return (
<>
{data.map((item,i) =>{
   return(
     <React.Fragment key={"S"+i}>  
    <div className="row mt-5">
          <Titles title={item.children.content.title}></Titles>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img
              src={`${domain_name}${item.children.content.icon}`}
              layout="fill"
              className="d-block w-100 mt-3 img-fluid"
           />
          </div>
          <div className="col-md-12 col-lg-5 gx-5">
            <div className={`mt-3 ${cssClass}`} dangerouslySetInnerHTML={{__html:item.children.content.body}}>
            </div>

           {/* { item.children.content.button &&
           <div className="nav-link px-2">
              <NavLink to="/aboutus" className="custButton">{item.children.content.button}</NavLink>
            </div>
            } */}
          </div>
        </div>   
        </React.Fragment>
   ) 
    })}

    </>
    )
}