import React from "react";


export default function AccountAssistance() {
  
  return (
      <>
<p className="rounded-pill text-center p-3 mt-5" style={{border:"1px solid #ed1c24"}}>
For any account related assistance please email in detail to <b>contact@hophigh.in</b>. We shall get back to you soon.
</p>
 </>
  );
}
