import React, { useEffect, useState, useContext, useRef } from 'react';
import {PostData} from "../services/fetchData";
import {Api_Endpoints} from "../../endpoint";
import TopBanner from '../common/banner/topBanner';
import CardBlock from '../common/card/cardBlock';
import LoaderCustom from '../common/loaders/loaderCustom';
import { authContext } from '../services/authContext';
import {Helmet} from "react-helmet";

export default function CoinStories() {
  const {authenticated} = useContext(authContext);
  const [data, setData] = useState();
  const [coinStory, setCoinStory] = useState();
  const {coin_stories, readCoinStories, coinStories} = Api_Endpoints;
  //const url = restStories+"?field_genre_value=&field_grade_value=&nid=&field_stories_type_value="
  const url = `${coinStories}`;
  let uid = ("uid" in localStorage) ? `&user_id=${localStorage.getItem('uid')}` : 0; 
  let restURLParam = "grade=&genre_id=&school_name=&user_id="+uid;


  const getData = ()=>{
    Promise.all([
      init_API(),
      coin_story_API(),
      ]).then(function(newData){
      setData(newData[0]);
      setCoinStory(newData[1]);
   });
  }

  useEffect(() => {
    getData(); //subscribe
    return ()=>{
      setData(null);
    }; //unsubscribe
  },[authenticated])

  const init_API = function () {
    return PostData(coin_stories, "GET")
  }
  
  const coin_story_API = function (pageNo=0) {

    return PostData(`${url}?${restURLParam}&page=${pageNo}${uid}`, "GET")
  } 

  const pagerFn = (val, type,reqparam)=>{
    if(reqparam){
    restURLParam = `&grade=${reqparam.grade}&gener_id=${reqparam.gener_id}&school_name=${reqparam.school_name}${uid}`; 
    }
    coin_story_API(val).then(function(data){
      setCoinStory(data);
    });
  }

  return (
    <React.Fragment>
        <Helmet>
    <title>HopHigh Coin Stories and Videos</title>
    <meta name="description" content="Redeem to Read. Redeem your coins to read these best paid stories."/>
    </Helmet>
      { data ? 
      <CoinStoriesPage data={data} pagerval={pagerFn} coinStory={coinStory}/> : <LoaderCustom></LoaderCustom> 
      }
    </React.Fragment>
  );
}

function CoinStoriesPage ({data, coinStory, pagerval}) {
  const moduleArray = data.display_weight;
  const {modules} = data; 
  const {banner} = data.children;
  const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const el2 = useRef();

  const componentList = moduleArray.map(
    item => {
      if (item === "banner" && modules.banner) {
        return <TopBanner key="01" data={banner} pageName={"coin_story"} click={()=> scrollToDiv(el2)} />;
        } else if(item == "Coin Story" && modules.coin_story && coinStory){
        return (
        <section className="container" key="02"  ref={el2}>
        <CardBlock data={coinStory} title="Coin Stories" noDataTitle="search" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} coinStory={true}  form={["genre"]} type="coin_story"/>  
        </section>
        );
      }
    }
  );
  return (
    <React.Fragment>
     {componentList}
    </React.Fragment>
  );
}