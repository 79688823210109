   import React,{useState, useEffect, useContext} from "react";
   import BgQuotes from "../common/bgQuotes/bgQuotes";
   import Counters from "../common/counters/counters";
   import Titles from "../common/titles/titles";
   import SMCardBlock from "../common/card/smCardBlock";
   import HeroBanner from "../common/banner/heroBanner";
   import HomeColumns from "./homeCols";
   import Stories from "../common/stories/stories";
   import PersonalDiary from "../common/personalDiary/personalDiary";
   import {PostData} from "../services/fetchData";
   import {Api_Endpoints, base_url} from "../../endpoint";
   import CardBlock from '../common/card/cardBlock';
   import LoaderHome from "../common/loaders/loaderHome";
   import { authContext } from "../services/authContext";
   import Reveal from "react-reveal/Reveal";
   import Bounce from "react-reveal/Bounce";
   import Zoom from "react-reveal/Zoom";
   import Fade from "react-reveal/Fade";
   
   export default function Home() {
    const [data, setData] = useState();
    const {home, homeHophighStories} = Api_Endpoints;
    const {authenticated} = useContext(authContext);
    const [uid, setUid] = useState(0);


    useEffect(() => {
    if("uid" in localStorage){
      setUid(localStorage.getItem('uid'));
    } else {
      setUid(0);
    }
    PostData(home, "GET").then(result => {
      setData(result);
    },
    error => {
    });
    
    return()=>{
      setData(null);
    }

    },[authenticated])
     return (
       <React.Fragment>

        {data ?
        <HomePage data={data} uid={uid} /> : <LoaderHome/>
        }
       </React.Fragment>
     );
   }

  function HomePage ({data, uid}) {
    const [hophighData, setHophighData] = useState();
    const [coinStoryData, setCoinStoryData] = useState();
    const {modules} = data; 
    const {coinStories, hophigh_picks_story} = Api_Endpoints;
    const {banner, coin_story, storie, rwe, personal_diary, midbanner, hopping_content, footbanner, countings} = data.children;
    const queryParam = "&grade=&gener_id=&school_name=&";

    const hopHigh_picks_API = function (pageNo=0) {
      const reqURL = `${hophigh_picks_story}?page=${pageNo}${queryParam}stories_type=hophigh_picks_story&nid=&user_id=${uid}`;
      PostData(reqURL, "GET").then(result => {
        setHophighData(result);
      },
      error => {
      })
    } 

    const coin_Stories_API = function (pageNo=0) {
       const reqURL = `${coinStories}?page=${pageNo}${queryParam}user_id=${uid}`;
       PostData(reqURL, "GET").then(result => {
        setCoinStoryData(result);
      },
      error => {
      })
    } 

    useEffect(() => {
    hopHigh_picks_API();
    coin_Stories_API();
    return()=>{
      setCoinStoryData(null);
    }
    },[data])
     
    const pagerFn = (val, type)=>{
      switch(type) {
      case "coin_story":
      coin_Stories_API(val);
      case "hophigh_picks_story":
      hopHigh_picks_API(val);
      break;
      default:
      }
    }  

    return (
    <React.Fragment> 
      {modules.banner &&
    <section className="container pt-0">
    <HeroBanner data={banner}/>
    </section>
     }

    <section className="container">
    <Fade>
    <Stories data={storie}/>
    </Fade>
    </section>
     
     {countings &&  
      <section className="container">
      <Counters data={countings}/>
      </section>
      }

      {personal_diary &&
      <section className="container">
        <Fade>
       <PersonalDiary data={personal_diary}/> 
       </Fade>
      </section>
      }

      <section className="container">
      <Fade bottom >
      <div className="row mt-5 d-flex justify-content-between">
     
        {rwe.map((item, i) => {
          return (
            <HomeColumns
              key={item.entity_id}
              index={i}
              title={item.children.content.title}
              imgSrc={`${base_url}${item.children.content.icon}`}  
              txtButton={item.children.content.button}
              description={item.children.content.body}
            />
          );
        })}
   
      </div>
      </Fade>
      </section>
      <section className="container-fluid">
      <BgQuotes data={midbanner}/>
      </section>
      {hophighData &&
        <section className="container">
      <Fade bottom>
      <CardBlock data={hophighData} title="HopHigh Picks" noDataTitle="search" loadMore={true} cardFn={(val,type)=>pagerFn(val, type)} type="hophigh_picks_story" coinStory={false} />
      </Fade>
      </section>
      }
      <section className="container-fluid">
        <BgQuotes data={footbanner} />
      </section>
      {coinStoryData &&
      <section className="container">
      <Fade bottom>
      <CardBlock data={coinStoryData} title="Coin Stories" noDataTitle="search" loadMore={true} cardFn={(val,type)=>pagerFn(val, type)} coinStory={true} type="coin_story" />
      </Fade>
      </section>
      }
       <section className="container">
       <Fade bottom>
       <Titles title={`Are you an author?`}></Titles>
       <p>If you are an author looking to publish your stories before the right audience, reach out to us at <a href="mailTo:iamintrested@hophigh.in">contact@hophigh.in</a></p>
      </Fade>
       </section>
       </React.Fragment>
     );
   }



   
   
  
   
   