import React, { useEffect, useState } from 'react';

export default function Chapters({ rendition, renderChapters, labelDisplay, redeemChapter, chapterAllowed, redeemType }) {
  const [tocs, setTocs] = useState([]);
  useEffect(() => {
    if (!rendition)
      return;
    const { book } = rendition;
    book.ready.then(() => {
      const { navigation } = book;
      const { toc } = navigation;
      setTocs(toc);
    });
  }, [rendition]);

  const gotoChapter = (tocCurrent) => () => {
    labelDisplay(tocCurrent.label)
    if (redeemType == "Chapter") {
      const chapterFound = chapterAllowed.find(chapter => chapter.name === tocCurrent.label.trim())

     // const chapterFound = chapterAllowed.find(chapter => chapter === tocCurrent.label.trim())
      if (chapterFound) {
        rendition.display(tocCurrent.href);
      } else {
        redeemChapter(tocCurrent, rendition)
      }
    } else if (redeemType == "Book") {
      rendition.display(tocCurrent.href);
    }
  };

  return (
    <div className="chapters">
      {renderChapters
        ? renderChapters(tocs, rendition)
        : tocs.map((toc, index) => {
          const { label, subitems } = toc;
          const purchasedStatus = chapterAllowed.findIndex(chapter => chapter.name === label.trim());
          return (
            <>
              <div key={index}>
                <p className="chapter" onClick={gotoChapter(toc)}>
                  {index}: {label} {purchasedStatus == -1 ? <span><i className="bi bi-lock-fill"></i></span> : ''}
                </p>
                {subitems && subitems.length !== 0 ?
                  <ol>
                    {subitems.map((subtoc, sIndex) => {
                      const { label, subItems } = subtoc;
                      return (<li key={sIndex}><a key={sIndex} onClick={gotoChapter(subtoc)}> {label} </a></li>);
                    })}
                  </ol>
                  :
                  ""
                }
              </div>
            </>
          )
        })}
    </div>
  )
}
