import React, { useEffect, useState, useContext, useRef } from 'react';
import BgQuotes from "../common/bgQuotes/bgQuotes";
import {PostData} from "../services/fetchData";
import {Api_Endpoints} from "../../endpoint";
import TopBanner from '../common/banner/topBanner';
import CardBlock from '../common/card/cardBlock';
import VideoBlock from '../common/video/videoBlock';
import LoaderCustom from '../common/loaders/loaderCustom';
import { authContext } from '../services/authContext';
import {Helmet} from "react-helmet";

export default function HopHighPicks() {
  const {authenticated} = useContext(authContext);
  const [data, setData] = useState();
  const [hoppingContent, setHoppingContent] = useState();
  const [hoppingVideo, setHoppingVideo] = useState();
  const {hophigh_picks, restStories, hophigh_picks_story, hophigh_picks_video} = Api_Endpoints;

  let uid = ("uid" in localStorage) ? localStorage.getItem('uid') : 0; 
  let url = `?_format=json&genre_id=all&grade_id=all&school=all&user_id=${uid}`;

  const getData = () => {
    Promise.all([
      init_API(),
      hopHigh_picks_API(),
      hophigh_picks_video_API(),
      ]).then(function(newData){
      setData(newData[0]);
      setHoppingContent(newData[1]);
      setHoppingVideo(newData[2]);
    });  
  }

  useEffect(() => {
    getData(); //subscribe
    return ()=>{
      setData(null);
    }; //unsubscribe
  },[authenticated]);

  const init_API = function () {
    return PostData(hophigh_picks, "GET")
  }
  
  const hopHigh_picks_API = function (pageNo=0) {
    const reqURL = uid ? `${url}&page=${pageNo}` : `${url}&page=${pageNo}`;
    return PostData(`${hophigh_picks_story}${reqURL}`, "GET")
  } 

  const hophigh_picks_video_API = function (pageNo=0) {
    const reqURL = uid ? `${url}&page=${pageNo}` : `${url}&page=${pageNo}`;
    return PostData(`${hophigh_picks_video}${reqURL}`, "GET")
  }

  const pagerFn = (val,type,reqparam) => {
    if(reqparam){
    url = `?grade_id=${reqparam.grade}&genre_id=${reqparam.gener_id}&nid=${reqparam.nid}&school=${reqparam.school_name}&user_id=${uid}`;
    } 

    switch(type) {
      case "hophigh_picks_story":
        hopHigh_picks_API(val).then(function(data){
          setHoppingContent(data);
        });
        break;
      case "hophigh_picks_video":
        hophigh_picks_video_API(val).then(function(data){
          setHoppingVideo(data);
        });
        break;
      default:
    }
  }

  return (
    <React.Fragment>
       <Helmet>
    <title>HopHigh Picks Stories and Videos</title>
    <meta name="description" content="Most loved. Most adored. Read stories most popular among our viewers."/>
    </Helmet>
      { data ? 
      <HopHighPicksPage data={data} pagerval={pagerFn} hoppingContent={hoppingContent} hoppingVideo={hoppingVideo}/> :
      <LoaderCustom></LoaderCustom> 
      }
    </React.Fragment>
  );
}

function HopHighPicksPage ({data, hoppingContent, hoppingVideo, pagerval}) {
  const moduleArray = data.display_weight;
  const {modules} = data; 
  const {banner, midbanner} = data.children;

  const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const el2 = useRef();

  const componentList = moduleArray.map(
    item => {
      if (item == "banner" && modules.banner){
        return <TopBanner  key="01" data={banner} pageName={"hophigh_picks_story"} click={()=> scrollToDiv(el2)} />;
        } else if(item == "hopping_content" && modules.hopping_content && hoppingContent){
        return (
        <section key="02" className="container" ref={el2}>
        <CardBlock  data={hoppingContent} title="HopHigh Picks - Stories" noDataTitle="search" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["school", "grade", "genre"]} type="hophigh_picks_story"/>  
        </section>
         );
      } else if(item == "midbanner" && modules.midbanner){
        return ( 
        <section key="03" className="container-fluid">
        <BgQuotes data={midbanner}/>
        </section>
        )
      } else if(item == "hopping_video" && modules.hopping_video && hoppingVideo){
        return(
        <section key="04" className="container">
          <VideoBlock data={hoppingVideo} title="HopHigh Picks - Videos" noDataTitle="search" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["school", "grade", "genre"]} type="hophigh_picks_video"/>
        </section>
        )
      }
    }
  );

  return (
    <React.Fragment>
     {componentList}
    </React.Fragment>
  );
}