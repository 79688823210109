import React, { useContext, useState } from "react";
import { Api_Endpoints, domain_url } from "../../../endpoint";
import NoStory from "../../../images/nostory.png";
import NoBookmark from "../../../images/nobookmark.png";

export default function NoContent({ nodatatext, onClickEvent }) {
    const messages = {
        story: {
            content: `Seems like you have not started writing stories here..  <span>Let get started to <a href=${domain_url}write>write now</a>!!</span>`,
            thumb:NoStory
        },
        video: {
            content: `Haven't created any videos yet?  <span>Let's get started... <a href=${domain_url}express>Express Now!</a>!!</span>`,
            thumb:NoStory
        },
        bookmark: {
            content: "Seems like you have'nt bookmarked any story or video yet...  <span>Do it! this will help you find your favorite stories quicker !!</span>",
            thumb:NoBookmark
        },
        groups: {
            content: "You are not a part of any group yet...  <span>If you are added to any group we will notify you..</span>",
            thumb:NoBookmark
        },
        nocreatedgroups: {
            content: `Oops!! No group created yet...  <span>Create a group and start adding students to this group</span>`,
            thumb:NoBookmark
        }
    }
    return (
        <>

            <div className="noContent border-1 mb-md-5 mt-4">
                <img className="nodataimg" src={messages[nodatatext].thumb} />
                <h2 dangerouslySetInnerHTML={{ __html: messages[nodatatext].content }}></h2>
                {nodatatext === "nocreatedgroups"? 
                <a className="custButton" onClick={onClickEvent}>Create New Group</a>
                :
                ''
            }
            </div>

        </>
    )
}