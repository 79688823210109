export const domain_name = "https://api.hophigh.in/";
export const domain_url = "https://hophigh.in/";
export const base_url = "https://api.hophigh.in";


export const Api_Endpoints = {
    "home": `${base_url}/api/page/1`,
    "coin_stories": `${base_url}/api/page/77`,
    "read": `${base_url}/api/page/80`,
    "write": `${base_url}/api/page/76`,
    "hophigh_picks": `${base_url}/api/page/78`,
    "hophigh_picks_story": `${base_url}/hp-rest-hopping-stories`,
    "hophigh_picks_video": `${base_url}/hp-rest-hopping-video`,
    "personal_diary": `${base_url}/api/page/79`,
    "express": `${base_url}/api/page/87`,
    "login": `${base_url}/user/login?_format=json`,
    "signup": `${base_url}/entity/user?_format=json`,
    "forgot_password": `${base_url}/user/lost-password?_format=json`,
    "logout":`${base_url}/user-logout`,
    "about_us": `${base_url}/api/page/108`,
    "contact_us":`${base_url}/api/page/110`,
    "contact_form":`${base_url}/entity/contact_message?_format=json`,
    "privacy_policy":`${base_url}/api/page/1`,
    "readHopHighStories":`${base_url}/hp-rest-hopping-stories?_format=json`,
    "restStories":`${base_url}/related-rest-stories`,
    "storiesFrmGrade":`${base_url}/story-from-grade?_format=json`,
    "storiesFrmSchool":`${base_url}/story-from-school?_format=json`,
    "restEpubStories":`${base_url}/related-rest-stories-epub`,
    "readCoinStories":`${base_url}/rest-coin-stories`,
    "restHoppingVideo":`${base_url}/hp-rest-hopping-video?_format=json`,
    "restExpressVideoGrade":`${base_url}/hp-rest-express-video-grade`,
    "header":`${base_url}/api/menu/header-menu`,
    "footer":`${base_url}/api/menu/footer-menu`,
    "my_profile":`${base_url}/user/`,
    "dashboard":`${base_url}/user/dashboard`,
    "leaderboardlist":`${base_url}/rest-leaderboard`,
    "leaderboard_Detailedlist":`${base_url}/rest-leaderboard/detail/`,  
    "leaderboard_Modify":`${base_url}/modify-leaderboard/`,    
    "leaderboardgroup":`${base_url}/node?_format=json`,
    "school_list": `${base_url}/rest-schools`,
    "grade_list": `${base_url}/rest-grade-list`,
    "genre_list": `${base_url}/rest-gener`,
    "userProfile":`${base_url}/user/`,
    "personalDiary":`${base_url}/node?_format=json`,
    "updateStory":`${base_url}/node`,
    "notification":`${base_url}/user-notification/`,
    "planner":`${base_url}/user-event/`,
    "userDiary":`${base_url}/user-diary`,
    "search_filter_story": `${base_url}/filter-by-story-category`,
    "search_filter_video": `${base_url}/filter-by-video-category`,
    "dashboard_story": `${base_url}/rest-user-profile-story`,
    "dashboard_video": `${base_url}/rest-user-profile-video`,
    "dashboard_bookmarks": `${base_url}/rest-user-bookmarked-story`,
    "golbalToken":`${base_url}/session/token`,
    "searchProfile":`${base_url}/rest-user-profile?name=`,
    "searchUserID":`${base_url}/rest-user-name?`,
    "postSocial": `${base_url}/post-social`,
    "gradeType":`${base_url}/rest-grade-type`,
    "uploadeProfilePic":`${base_url}/entity/file?_format=hal_json`,
    "postProfilePic":`${base_url}/post-userimage`,
    "postStoryPic":`${base_url}/post-nodeimage`,
    "followers":`${base_url}/rest-myfollowers`,
    "followings":`${base_url}/rest-imfollowing`,
    "privacypolicy":`${base_url}/rest-privacy-policy`,
    "coinDetails":`${base_url}/rest-coinHistory`,
    "readStory":`${base_url}/api/read-story?_format=json`,
    "divList":`${base_url}/rest-division-list`,
    "changePassword":`${base_url}/user/`,
    "clearNotification":`${base_url}/read-notification/`,
    "report": `${base_url}/node?_format=json`,
    "epubStory": `${base_url}/filter-coinStory/`,
    "coinStories":`${base_url}/rest-coinStories`,
    "redeemCoins": `${base_url}/redeem-story?_format=json`,
    "loginCoins": `${base_url}/rest-check-in/`,
    "termsCondition": `${base_url}/rest-basic-content/terms-conditions`,
    "joinGroupAcceptRequest" : `${base_url}/register-to-leaderborad`,
    "lostPasswordReset" : `${base_url}/user/pass-reset?_format=json`
  }