import React, { useState, useEffect, useContext} from "react";
import { useForm, Controller } from "react-hook-form";
import Titles from "../titles/titles";
import { Api_Endpoints } from '../../../endpoint';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { addDays, subDays, subYears } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from 'date-fns';
import Tooltip from 'react-bootstrap/Tooltip';
import $ from 'jquery';
import { Tnc } from "../modalPopup/modalPopup";
import { authContext } from "../../services/authContext";

export default function SignUp({successContainer, login}) {
    const {div_list, grade_List, school_List} = useContext(authContext);
    const [otherSchool, setOtherSchool] = useState(false);
    const [error, setError] = React.useState();
    const [startDate, setStartDate] = useState("");
    const [tnc, setTnc] = useState(false);
    const [passwordShown, setPasswordShown] = React.useState(false);
    const [proPwdShown, setProPwdShown] = React.useState(false);
    const [subYr, setSubYr] = useState(20);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
    const toggleProfPwdVisiblity = () => {
      setProPwdShown(proPwdShown ? false : true);
    };
    const range = (start, end) => {
      return new Array(end - start).fill().map((d, i) => i + start);
    };

   const subyears = subYears(new Date(), subYr);
   const year = getYear(subyears);
    const years = range(year, getYear(new Date()) + 1, 1);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const CustomInput = React.forwardRef((props, ref) => {
      return (
        <div className="position-relative  w-100" style={{top: "0px",     paddingTop: "1.325rem"}}>
        <input type="text" className="form-control border-bottom customforminput" style={{height: "calc(2.2rem + 2px)", paddingLeft: "10px"}} onClick={props.onClick} ref={ref} value= {props.value || props.placeholder}/>
        <i className="bi bi-calendar position-absolute" style={{top: "27px", right: "10px"}} onClick={props.onClick}></i>
        </div>
      );
    });
  
  const { signup, school_list, grade_list, gradeType, divList} = Api_Endpoints;
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    formState,
  } = useForm();
  const [grade, setGrade] = useState('');
  const [school, setSchool] = useState('');
  const [newdata, setNewdata] = useState();
  const [schoolList, setSchoolList] = useState(school_List);
  const [gradeList, setGradeList] = useState();
  const [schoolCompList, setSchoolCompList] = useState(school_List);
  const [schoolName, setSchoolName] = useState("");
  const [divisionList, setDivisionList] = useState(div_list);

  useEffect(() => {
    setGradeList(grade_List.filter(
      item => item.value !== "All"
    ));
    setDivisionList(div_list.filter(
      item => item.value !== "All"
    ));
    setSchoolList(schoolList.filter(
      item => item.value !== "All"
    ));
  },[]);
  
  const other = register('other', { required: false })

    const onSubmit = (data) => {
      const req =   {
        "name": [
            {
                "value":  data.userID
            }
        ],
        "pass": [
            {
                "value": data.password
            }
        ],
        "mail": [
            {
                "value": data.email
            }
        ],
        "roles": [
            {
                "target_id": data.iamaRd
            }
        ],
        "field_birth_date": [
            {
                "value": new Date(data.birthday).toISOString().split(".")[0] + "+00:00"
            }
        ],
        "field_country": [
            {
                "value": data.country
            }
        ],
        "field_gender": [
            {
                "value": data.genderRd
            }
        ],
        "field_grade": [
            {
                "value": data.grade.value
            }
        ],
        "field_user_grade": [
          {
              "target_id": data.grade.value
          }
      ],
        "field_grade_type": [
            {
                "value": data.gradeType.value
            }
        ],
        "field_i_am": [
            {
                "value": data.name
            }
        ],
        "field_name": [
            {
                "value": data.name
            }
        ],
        "field_school_id": [
            {
                "value": ""
            }
        ],
        "field_school_location": [
            {
                "value": data.schoolLocation
            }
        ],
        "field_diary_pass": [
        {
                "value": data.profilePassword
            }
        ],
        "field_passcode_status": [
            {
                "value": data.profilePassword ? true : false
            }
        ],
        "field_school_name": [
            {
                "value":  otherSchool ? data.otherSchoolName : ""
            }
        ],
        "field_school": [
          {
              "target_id": data.schoolName ? data.schoolName.id : ""
          }
        ],
        "status":[
          {
          "value":true  
          }
        ]
    }
  
    if(data){
      const reqParam = JSON.stringify(req);
      $.ajax({
        url: signup,
        dataType: 'json',
        type: 'POST',
        contentType: 'application/json',
        data: reqParam,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("Authorization", 'Basic YWRtaW46YWRtaW4=');
          xhr.setRequestHeader("X-CSRF-Token", localStorage.getItem('globalToken'));
        },
        success: function(data) {
          successContainer(data);
        },
        error: function(jqXHR, textStatus, errorThrown){
        setError(jqXHR.responseJSON.message);
        }
    });
    }
    };

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Select your school grade(std)
      </Tooltip>
    );

    const setSchoolLocationHandler = (selectedVal) => {
      schoolCompList.forEach((d,i) =>{ 
      //  console.log(d);
        const iCount = ++i;
        setSchoolName(selectedVal);
        setValue('schoolName', selectedVal);
        if(d.value == selectedVal.value){
          setValue('schoolLocation', d.address)
        }
        /*if(iCount == newData[0].length){
        schoolList_options.push({"value":"Other", "label":"Other"});
      }*/
      })
    }

    const otherschoolFn = (val)=>{
      setOtherSchool(val);
      setValue('schoolLocation', "");
      setValue('schoolName', "")
    }

    return (
      <>
      { error &&
       <div className="alert alert-danger text-danger" role="alert">
        <span><i className="bi bi-x-circle-fill text-danger"></i> {error}</span>
        </div>}
      <div className="row">
      <div className="col-12 customForm">  
      <Titles title="Create a HopHigh account"/>
      <p className="mt-2">Already have an account? <a className="textColorSecondary pe-auto" onClick={login} style={{cursor:"pointer"}}><b>LOGIN</b></a></p>
      <form className="customForm"  onSubmit={handleSubmit(onSubmit)}>
      <div className="row g-3">
       <div className="col-md">
       <div className="form-floating">
      <input id="name" className="form-control customforminput" placeholder="Name" {...register('name', { required: true, maxLength: 30 })} />
      <label htmlFor="name" >Name</label>  
      </div>
      {errors.name && errors.name.type === "required" && <span className="text-danger">This is required</span>}
      {errors.name && errors.name.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
       </div>
      <div className="col-md">
      <div className="form-floating">
      <input id="userID" className="form-control customforminput" placeholder="User ID" {...register('userID', { required: true, maxLength: 30 })} />
      <label htmlFor="userID">User ID</label>
      </div>
      {errors.userID && errors.userID.type === "required" && <span className="text-danger">This is required</span>}
      {errors.userID && errors.userID.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      </div>
      </div>
      <div className="row g-3">
      <div className="col-md">
      <div><label className="form-label">Gender </label></div>
      <div className="form-check form-check-inline">
      <input type="radio" id="male" className="form-check-input" value="male" {...register('genderRd', { required: true})} />
      <label className="form-check-label" htmlFor="male">Male</label>
      </div>
      <div className="form-check form-check-inline">
      <input type="radio" id="female" className="form-check-input" value="female" {...register('genderRd', { required: true})} />
      <label className="form-check-label" htmlFor="female">Female</label>
      </div>
      {errors.genderRd && errors.genderRd.type === "required" && <span className="text-danger">required</span>}
       </div>
       <div className="col-md" >
      <div><label className="form-label">I am a</label></div>
      <div className="form-check form-check-inline">
      <input type="radio" id="student"  className="form-check-input" value="student" onClick={() => { setSubYr(20); setValue('birthday', "");}}  {...register('iamaRd', { required: true})} />
      <label className="form-check-label" htmlFor="student">Student</label>
      </div>
      <div className="form-check form-check-inline">
      <input type="radio" id="teacher" className="form-check-input" value="teacher"  onClick={() => {setSubYr(100); setValue('birthday', "");}} {...register('iamaRd', { required: true})} />
      <label className="form-check-label" htmlFor="teacher">Teacher</label>
      </div>
      {errors.iamaRd && errors.iamaRd.type === "required" && <span className="text-danger">required</span>}
       </div>
      </div>
      
      <div className="row g-3">
      <div className="col-md">
      <div className="form-floating mt-3">
      <input id="email" type="email" className="form-control customforminput" placeholder="Email" {...register('email', { required: true, maxLength: 30 })} />
      <label htmlFor="email">Email</label>
      </div>
      {errors.email && errors.email.type === "required" && <span className="text-danger">This is required</span>}
      {errors.email && errors.email.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      </div>
      <div className="col-md">
      
      <div className="form-floating  mt-3">
      <label htmlFor="birthday" className="birthdayLabel">Birthday</label>
      {/*<input id="birthday" type="date" className="form-control customforminput" placeholder="Birthday" {...register('birthday', { required: true, maxLength: 30 })} />*/}
      <Controller
    control={control}
    name='birthday'
    rules={{ required: true }}
    render={({ field }) => (
      <DatePicker
      selected={field.value}
      onChange={(date) => field.onChange(date)}
      dateFormat="dd-MM-yyyy"
      className="form-control customforminput"
      placeholderText="dd-mm-yyyy"
      startDate={startDate}
      showMonthDropdown
      showYearDropdown
      maxDate={addDays(new Date(), 0)}
      customInput={<CustomInput />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
       
          <select
            className="form-select form-select-sm"
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
          className="form-select form-select-sm"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

        </div>
      )}
      />
      )}
      />
      </div>
      {errors.birthday && errors.birthday.type === "required" && <span className="text-danger">required</span>}
      {errors.birthday && errors.birthday.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      </div>
      </div>
      <div className="row g-3">
      <div className="col-md">
      <label htmlFor="schoolName" className={otherSchool ? "d-none": "form-label mb-0"}>School Name</label>
      <div className="row g-3">
       <div className="col-md-8 ">
        {otherSchool ?
        <>
        <div className="form-floating mt-3" >
         <input type="textbox" id="otherSchoolName" placeholder="Other School" className="form-control customforminput" {...register('otherSchoolName',  { required: true, maxLength: 30 })} />
         <label htmlFor="otherSchoolName">Other School</label>
         </div>
         {errors.otherSchoolName && errors.otherSchoolName.type === "required" && <span className="text-danger">This is required</span>}
        </>
        :
        <>
        {schoolList &&
        <Controller
        name="schoolName"
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
        <Select 
        {...field}
        placeholder="Select School Name"
        classNamePrefix="react-select"
        className="react-select-container selectBorder0"
        onChange={(value) => setSchoolLocationHandler(value)}
        options={schoolList}
        value={schoolList.find((c) => {
          return c.id === field.id
        })}

        />}
       /> }
      {errors.schoolName && errors.schoolName.type === "required" && <div className="text-danger">This is required</div>}
       </>
       }
       </div>
        
         {/*otherSchool &&
         <div className="col-md-6">
         <div className="form-floating mb-3">
         <input type="textbox" id="otherSchoolName" placeholder="Other School" className="form-control customforminput"  {...register('otherSchoolName',  { required: true, maxLength: 30 })} />
         <label htmlFor="otherSchoolName">Other School</label>
         </div>
         {errors.otherSchoolName && errors.otherSchoolName.type === "required" && <span className="text-danger">This is required</span>}
         {errors.otherSchoolName && errors.otherSchoolName.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
         </div>
         */}
         <div className="col-md-4 position-relative">
         <div className="form-check position-absolute bottomPosition">
      <input id="other" type="checkbox"  className="form-check-input" ref={other.ref} onChange={e => otherschoolFn(e.target.checked)}  />
      <label className="form-check-label" htmlFor="other">
      Other
      </label>
      {errors.other && errors.other.type === "required" && <span className="text-danger">This is required</span>}
         </div>
         </div>
         </div>
      
      </div>
      <div className="col">
      <div>
        <label className="form-label mb-0">Grade 
        {/* <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
  >
    <i className="bi bi-info-circle-fill ps-1"></i>
  </OverlayTrigger> */}
       </label>
        </div> 
        <div className="row g-3">
         <div className="col-6">
         <Controller
          name="grade"
          control={control}
          rules={{ required: true }}
          render={({ field }) =>
          <Select 
          {...field} 
          classNamePrefix="react-select"
          className="react-select-container selectBorder0"
          placeholder="Select Grade" 
          options={gradeList}
          />
          }/>
        {errors.grade && errors.grade.type === "required" && <div className="text-danger">This is required</div>}
        </div>
        <div className="col-6">
         <Controller
          name="gradeType"
          control={control}
          rules={{ required: true }}
          render={({ field }) => 
          <Select 
            {...field} 
            placeholder="Select Div"
            className="react-select-container selectBorder0"
            classNamePrefix="react-select"
            options={divisionList} 
          />
          }
         /> 
         {errors.gradeType && errors.gradeType.type === "required" && <div className="text-danger">This is required</div>}
        </div>
        </div>
      </div>
     
      </div>
      <div className="row g-3">
      <div className="col-md">
      <div className="form-floating mt-3">
      <input 
      id="schoolLocation" 
      className="form-control customforminput" 
      readOnly = {!otherSchool}
      placeholder="School Location" {...register('schoolLocation', { required: true, maxLength: 255 })} />
      <label htmlFor="schoolLocation">School Location</label>
      </div>
      {errors.schoolLocation && errors.schoolLocation.type === "required" && <span className="text-danger">required</span>}
      {errors.schoolLocation && errors.schoolLocation.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      </div>
      <div className="col-md">
      <div className="form-floating mt-3">
      <input id="country" className="form-control customforminput" placeholder="Country" {...register('country', { required: true, maxLength: 30 })} />
      <label htmlFor="country" >Country</label>
      </div>
      {errors.country && errors.country.type === "required" && <span className="text-danger">required</span>}
      {errors.country && errors.country.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      </div>
      
      </div>
      <div className="row g-3">
      <div className="col-md">
      <div className="form-floating mt-3"> 
      <input 
      id="password" 
      type={passwordShown ? "text" : "password"}
      className="form-control customforminput" 
      placeholder="Password" {...register('password', { required: true, maxLength: 30, pattern: /^^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,30}$/i})} />
      <i className={`eyeIcon bi ${passwordShown ? "bi-eye-slash" : "bi-eye"}`} onClick={togglePasswordVisiblity}></i>
      <label htmlFor="password">Password</label>
      </div>
      {errors.password && errors.password.type === "required" && <span className="text-danger">This is required</span>}
      {errors.password && errors.password.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      {errors.password && errors.password.type === "pattern" && <span className="text-danger">7 to 30 characters, atleast one numeric digit and a special character</span> }
      </div>
      <div className="col-md">
      <div className="form-floating mt-3">
      <input id="profilePassword" 
      type={proPwdShown ? "text" : "password"} 
      className="form-control customforminput" placeholder="Profile Password" {...register('profilePassword', { required: true, maxLength: 30, pattern: /^^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,30}$/i})} />
      <i className={`eyeIcon bi ${proPwdShown ? "bi-eye-slash" : "bi-eye"}`} onClick={toggleProfPwdVisiblity}></i>
      <label htmlFor="profilePassword" >Profile Password</label>
      </div>
      {errors.profilePassword && errors.profilePassword.type === "required" && <span className="text-danger">This is required</span>}
      {errors.profilePassword && errors.profilePassword.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
      {errors.profilePassword && errors.profilePassword.type === "pattern" && <span className="text-danger">7 to 30 characters, atleast one numeric digit and a special character</span> }
      </div>
      </div>
      <div className="row g-3 mt-2">
      <div className="col-lg-8">
      <div className="form-check">
      <input id="tnc" type="checkbox" className="form-check-input" {...register('tnc', { required: true})} />
      <label className="form-check-label" style={{fontSize: "0.7rem"}}>
      <a href="#" onClick={()=>setTnc(true)}>*Terms &amp; Condtions apply.</a>
      </label>
      {errors.tnc && errors.tnc.type === "required" && <span className="text-danger">This is required</span>}
      </div> 
      </div>
      <div className="col-lg-4">
      <button type="submit" className="custButton float-md-end" >Create Account</button>
      </div>
      </div>
      </form>
      </div>
      </div> 
      {tnc && 
      <Tnc hideFn={()=>setTnc(false)}/>
      }      
      </>
    );
  }  

