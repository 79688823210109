import React from "react";
import BottomBorder from "../../../images/colourfullborder.svg"

export default function Titles(props) {
  return (
    <div className="col-sm-9">
      <h2 className="textColorSecondary mb-0">
        <b  dangerouslySetInnerHTML={{ __html: props.title }}></b>
      </h2>
      <img 
      src={BottomBorder}
      />
    </div>
  );
}
