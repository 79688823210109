import React, { useMemo, useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { PostData } from "../services/fetchData";
import { Api_Endpoints, base_url } from "../../endpoint";
import Titles from "../common/titles/titles";
import { authContext } from "../services/authContext";
import Table from "../common/table/table";
import Batches from "../common/batches/batches";
import { NavLink, useLocation, Link } from "react-router-dom";
import AccountAssistance from "../common/accountAssistance/account-assistance";
import { Helmet } from "react-helmet";
import Profilebox from "../common/misc/profilebox";
import NoContent from "../common/misc/noContent";
import Fade from "react-reveal/Fade";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export default function Leaderboard() {
  const [modal, setModal] = useState(false);
  const { leaderboardlist, leaderboardgroup, leaderboard_Detailedlist, leaderboard_Modify, searchProfile } = Api_Endpoints;
  const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const { profileData } = useContext(authContext);
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [nid, setNid] = useState([]);
  const [profileInfo, setProfileInfo] = useState([]);
  const [leaderboardGrouplist, setLeaderboardGrouplist] = useState();
  const [leaderboardDetailedList, setLeaderboardDetailedList] = useState();
  const [detailShow, setDetailShow] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [disable, setDisable] = useState(false);
  const [titleName, setTitleName] = useState("Create Group Name");
  const location = useLocation();
  let listing = "";

  useEffect(() => {
    if ("uid" in localStorage) {
      setUid(localStorage.getItem("uid"));
    } else {
      setUid(null);
    }

    PostData(
      `${leaderboardlist}/${uid}`,
      "GET"
    ).then(
      (result) => {
        setLeaderboardGrouplist(result);
      },
      (error) => {
      }
    );

    const userId = localStorage.getItem("userID");
    PostData(searchProfile + userId,
      "GET"
    ).then(
      (result) => {
        setProfileInfo(result);
      },
      (error) => {
      }
    );

  }, [detailShow]);




  const handleGreet = () => {
    var day = new Date();
    var hr = day.getHours();
    if (hr >= 0 && hr < 12) {
      return "Good Morning!";
    } else if (hr == 12) {
      return "Good Noon!";
    } else if (hr >= 12 && hr <= 17) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  }

  const groupDetailHandler = (nid, group) => {
    setNid(nid);
    setGroupName(group);
    PostData(
      `${leaderboard_Detailedlist}${nid}?_format=json`,
      "GET"
    ).then(
      (result) => {
        setLeaderboardDetailedList(result);
        setDetailShow(true);
        setValue('groupTitle', group);
      },
      (error) => {
      }
    );
  }

  const addStudentHandler = (nid, group) => {
    // setGroupName(group)
    // setNid(nid)
    setModal(true);
    setDisable(true);
    setValue('groupTitle', groupName);
    setTitleName("Add Students");
    // createGroupHandler();
    // PostData(
    //   `${leaderboard_Modify}`,
    //   "POST"
    // ).then(
    //   (result) => {
    //     setLeaderboardDetailedList(result);
    //     setDetailShow(true);

    //   },
    //   (error) => {
    //   }
    // );

  }

  const deleteStudentHandler = (email) => {
    const req = {
      "type": "delete",
      "email": email,
      "nid": nid
    }
    const reqParam = JSON.stringify(req);

    PostData(leaderboard_Modify, "POST", reqParam, true, true).then((result) => {
    }, (error) => {
    }
    );
  }



  const groupColumns = useMemo(() => [
    {
      Header: "Group Name",
      accessor: "title",
      url: true,
      Cell: ({ row }) => {

        return (
          <NavLink to="#" onClick={() => groupDetailHandler(row.original.nid, row.original.name)}>{row.original.name}</NavLink>
        )
      }
    },

  ]);

  const groupDetailColumns = useMemo(() => [
    {
      Header: "User Name",
      accessor: "username",
      Cell: ({ row }) => {
        return (
          <div className="position-relative">{row.original.username !== "" ? row.original.username : row.original.email}
            {row.original.verified === "1" ? <i className="bi bi-patch-check-fill"></i> : ""}
            {/* {row.original.username !== "" && row.original.status == "Pending" ?  <span className="batch">Pending</span> : ""}
         {row.original.username === "" ? <span className="batch">Pending</span> : ""} */}
          </div>

        )
      }
    },
    {
      Header: "Status",
      accessor: "verified",
      Cell: ({ row }) => {
        return (
          <div className="position-relative">
            {row.original.username && row.original.status === "Pending" ? "Pending" : row.original.username == "" && row.original.status === "Pending" ? "Pending" : "Accepted"}
          </div>
        )
      }
    },
    {
      Header: "Read Coins",
      accessor: "coins.read_coins",
    },
    {
      Header: "Write Coins",
      accessor: "coins.write_coins",
    },
    {
      Header: "Express Coins",
      accessor: "coins.express_coins",
    },
    {
      Header: "Other Coins",
      accessor: "coins.other_coins",
    },
    {
      Header: "Total Coins",
      accessor: "coins.overall_coins",
    },
    {
      Header: "Delete",
      accessor: "delete",
      /*Cell: ({ row }) => {
        return (
          <div>
            {row.original.name}
          <NavLink to="#" onClick={() => deleteStudentHandler(row.original.email)}> <i className="bi bi-x-circle-fill"></i></NavLink>
          </div>
        )
      }*/
      Cell: (tableProps) => (
        <span
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline"
          }}
          onClick={() => {
            const dataCopy = [...leaderboardDetailedList];
            dataCopy.splice(tableProps.row.index, 1);
            setLeaderboardDetailedList(dataCopy);
            deleteStudentHandler(tableProps.row.original.email);
          }}
        >
          Delete
        </span>
      )
    },
  ]);

  const onSubmit = (data) => {
    console.log(data)
    const req = {
      "type": [
        {
          "target_id": "dashboard"
        }
      ],
      "title": [
        {
          "value": data.groupTitle
        }
      ],
      "field_email_list": [
        {
          "value": data.emailList
        }
      ],
      "uid": [
        {
          "target_id": uid
        }
      ]
    }
    const reqParam = JSON.stringify(req);
    if (!disable) {
      PostData(leaderboardgroup, "POST", reqParam, true, true).then((result) => {
        setValue('emailList', '')
        setModal(false)
        setShowToast(true)
      }, (error) => {
      }
      );
    } else {
      const req = {
        "nid": nid,
        "email": data.emailList,
        "type": "add"
      }
      const reqParam = JSON.stringify(req);
      PostData(leaderboard_Modify, "POST", reqParam, true, true).then((result) => {
        setValue('emailList', '')
        setModal(false)
        setShowToast(true)
        groupDetailHandler(nid, groupName)
      }, (error) => {
      }
      );
    }
  }

  const createGroupHandler = () => {
    setValue('groupTitle', "");
    setModal(true);
    setTitleName("Create Group Name");
  }

  const handleModalClose = () => {
    setModal(false);
    setDisable(false);
  }

  return (
    <>
      <Helmet>
        <title>HopHigh - Leaderboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <section className="container">
        <div className="row ">
          <div className="col-md-10 d-flex ">
            <Profilebox profileInfo={profileInfo} profileData={profileData} />
          </div>
          <div className="col-md-2 text-center text-md-end mt-4 mt-md-5">
            <a className="custButton hollow" onClick={createGroupHandler}>Create A Group</a>
          </div>
        </div>
      </section>

      <section className="container">
        <Titles title={`Leader board ${detailShow && groupName ? groupName : ''}`}></Titles>
        <div className={detailShow ? "d-none" : "d-block"}>
          {leaderboardGrouplist && leaderboardGrouplist.length !== 0 ?
            <Fade>
              <div className="datagrid">
                <Table
                  data={leaderboardGrouplist}
                  columns={groupColumns}
                  setPageSize={`10`}
                />
              </div>
            </Fade>
            :
            <NoContent nodatatext={`nocreatedgroups`} onClickEvent={createGroupHandler} />
          }
        </div>
        <div className={detailShow ? "d-block datagrid" : "d-none datagrid"}>
          {leaderboardDetailedList && leaderboardDetailedList.length !== 0 ?
            <>
              <Fade>
                <Table
                  data={leaderboardDetailedList}
                  columns={groupDetailColumns}
                  setPageSize={`10`}

                />
                <div className="text-center mt-3">
                  <button className="custButton hollow" onClick={() => addStudentHandler()}>Add Student(s)</button> <button className="custButton hollow" onClick={() => setDetailShow(false)}>Back</button>
                </div>
              </Fade>
            </>
            :
            ''
          }
        </div>
        <AccountAssistance />
      </section>
      <Modal show={modal} dialogClassName={`createGroupMOdal hophighModal modal-dialog-centered`} size="md" onHide={handleModalClose}>
        <Modal.Header>
          <h3><span className="closebtn" onClick={handleModalClose}><i className={`bi bi-x-circle`} ></i></span></h3>
        </Modal.Header >
        <Modal.Body className="pt-0">
          <Titles title={titleName}></Titles>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-floating mb-3">
              <input type="text" id="groupTitle" disabled={disable} className="form-control customforminput" placeholder="Group Name" {...register('groupTitle', { required: true })} />
              <label htmlFor="groupTitle" >Group Name<span className="text-danger ps-1">*</span></label>
            </div>
            <div className="form-floating mb-3">
              <textarea className="txtArea" placeholder="Add students email id separated by comma...(aaa@example.com, bbb@example.com)" {...register('emailList', { required: true })} />
            </div>
            <button type="submit" className="custButton mt-3">{disable ? "Add Contact" : "Create Group"}</button>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer className="position-fixed p-3" position={`bottom-center`}>
        <Toast onClose={() => setShowToast(false)} bg="success" show={showToast} delay={4000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Group Created</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Congratulations!! You have successfully created a new group.</p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
