import React, { useEffect, useState, useContext} from 'react';
import BgQuotes from "../common/bgQuotes/bgQuotes";
import {PostData} from "../services/fetchData";
import {Api_Endpoints} from "../../endpoint";
import TopBanner from '../common/banner/topBanner';
import CardBlock from '../common/card/cardBlock';
import LoaderCustom from '../common/loaders/loaderCustom';
import { authContext } from '../services/authContext';
import NotLoginCard from '../common/card/notLoginCard';
import {Helmet} from "react-helmet";

export default function Read() {
  const {profileData, authenticated} = useContext(authContext);
  const [data, setData] = useState();
  const [coinStoriesData, setCoinStoriesData] = useState();
  const [hopHighData, setHopHighData] = useState();
  const [storiesFromSchool, setStoriesFromSchool] = useState();
  const [storiesFromGrade, setStoriesFromGrade] = useState();
  const {read, restStories, readHopHighStories, readCoinStories, coinStories, storiesFrmGrade, storiesFrmSchool} = Api_Endpoints;
  const url = `${restStories}?type=`;

  let uid = ("uid" in localStorage) ? localStorage.getItem('uid') : 0; 
  let restURLParam = "&grade_id=all&gener_id=all&school=all&user_id="+uid;
  let currSchool = profileData && profileData.field_school.length !== 0 ? profileData.field_school[0].target_id : "all"; 
  let currGrade = profileData && profileData.field_user_grade.length !== 0 ? profileData.field_user_grade[0].target_id : "all"; 
 
  const getData = ()=>{
    Promise.all([
      init_API(),
      coin_story(),
      hophigh_picks_story(),
      stories_form_school(),
      stories_from_grade()
      ]).then(function(newData){
      setData(newData[0]);
      setCoinStoriesData(newData[1]);
      setHopHighData(newData[2]);
      setStoriesFromSchool(newData[3]);
      setStoriesFromGrade(newData[4]);
   });
  }

  useEffect(() => {

   window.scrollTo(0, 0);
   currSchool = `${profileData && profileData.field_school.length !== 0 ? profileData.field_school[0].target_id : "all"}`; 
   currGrade = `${profileData && profileData.field_user_grade.length !== 0 ? profileData.field_user_grade[0].target_id : "all"}`; 
    getData();
    return ()=>{
      setData(null);
    }
  },[authenticated, profileData])


  const init_API = function () {
    return PostData(read, "GET")
  }

  const coin_story = function (pageNo=0) {
    return PostData(coinStories+"?&page="+pageNo+restURLParam, "GET")
  }
  
  const hophigh_picks_story = function (pageNo=0) {
    console.log("INNN")
    return PostData(readHopHighStories+"&page="+pageNo+restURLParam, "GET")
  } 

  const stories_form_school = function (pageNo=0) {
    return PostData(storiesFrmSchool+"&type=story&school="+currSchool+"&user_id="+uid, "GET")
  }

  const stories_from_grade = function (pageNo=0) {
    return PostData(storiesFrmGrade+"&type=story&grade_id="+currGrade+"&user_id="+uid, "GET")
  }
  
  const pagerFn = (val, type, reqparam)=>{
    console.log(reqparam)
    if(reqparam){
    restURLParam = `&grade_id=${reqparam.grade ? reqparam.grade : 'all'}&gener_id=${reqparam.gener_id ? reqparam.gener_id: 'all'}&school=${reqparam.school_name ? reqparam.school_name : 'all'}&user_id=${uid}`; 
    } 
    switch(type) {
    case "coin_story":
      coin_story(val, reqparam).then(function(data){
        setCoinStoriesData(data);
      });
      break;
    case "hophigh_picks_story":
      hophigh_picks_story(val, reqparam).then(function(data){
        setHopHighData(data);
      });
      break;
    case "stories_form_school":
      stories_form_school(val, reqparam).then(function(data){
        setStoriesFromSchool(data);
      });
      break;
    case "stories_from_grade":
      stories_from_grade(val, reqparam).then(function(data){
        setStoriesFromGrade(data);
      });
      break;
    default:
      // code block
  }
  }

  return (
    <React.Fragment>
      <Helmet>
      <title>HopHigh - Read</title>
      <meta name="description" content="Read on and Dream on! At HopHigh, we set no limits to your imagination."/>
      </Helmet>
      { data ?
      <ReadPage authenticated={authenticated} data={data} coinStoriesData={coinStoriesData} pagerval={pagerFn} hopHighData={hopHighData} storiesFromSchool={storiesFromSchool} storiesFromGrade={storiesFromGrade}/> :
      <LoaderCustom/> 
      }
    </React.Fragment>
  );
}

function ReadPage ({data, coinStoriesData, hopHighData, storiesFromSchool, storiesFromGrade, pagerval, authenticated }) {
  const moduleArray = data.display_weight;
  const {modules} = data; 
  const {banner, midbanner, footbanner, footer_banner_2} = data;

  const componentList = moduleArray.map(
    (item, i) => {
      if (item == "banner" && modules.banner){
        return <TopBanner data={banner} key={"ac"+i} />;
        } else if(item == "hopping_content" && modules.hopping_content && hopHighData){
        return (
        <section className="container" key={"bc"+i}>
        <CardBlock data={hopHighData} key={"aca"+i} title="HopHigh Picks" noDataTitle="search" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["school", "grade", "genre"]} type="hophigh_picks_story"/>  
        </section>
         );
      } else if(item == "midbanner" && modules.midbanner){
        return ( 
        <section className="container-fluid" key={"cc"+i}>
        <BgQuotes data={midbanner} key={"aaca"+i}/>
        </section>
        )
      } else if(item == "stories_form_your_school" && modules.storie && storiesFromSchool){
        return(
        <section className="container" key={i}>
        {authenticated ?
        <CardBlock  data={storiesFromSchool} key={"ssca"+i} title="Stories from your school" noDataTitle="search" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["grade", "genre"]} type="stories_form_school"/>  
        : <NotLoginCard title="Stories from your school"/>
        }
        </section>
        )
      }else if(item == "footbanner" && modules.footbanner){
        return (
        <section className="container-fluid" key={"dc"+i}>
        <BgQuotes data={footbanner} key={"qwca"+i}/>
        </section>
        );
      } else if(item == "grade_story" && modules.grade_story && storiesFromGrade){
        return(
        <section className="container" key={"ec"+i}>
          {authenticated ?
        <CardBlock data={storiesFromGrade} key={"tgca"+i}   title="Stories from your grade" noDataTitle="search" cardFn={(val,type,reqparam)=>pagerval(val, type, reqparam)} form={["genre"]} type="stories_from_grade"/>  
         : <NotLoginCard title="Stories from your grade"/>   
        }
        </section>
        )
      } else if(item == "footer_banner_2" && modules.footer_banner_2){
        return (
        <section className="container-fluid" key={"dc"+i}>
        <BgQuotes data={footer_banner_2} key={"qwca"+i}/>
        </section>
        );
      } else if(item == "coin_story" && modules.coin_story && coinStoriesData){
        return (
        <section className="container" key={"fc"+i}>
        <CardBlock data={coinStoriesData} key={"lkca"+i} title="Coin Stories" noDataTitle="search" form={["genre"]} cardFn={(val,type, reqparam)=>pagerval(val, type, reqparam)} coinStory={true} type="coin_story"/>  
      
        </section>
        )  
      }
    }
  );
  return (
    <React.Fragment key={"aca"}>     
     {componentList}
    </React.Fragment>
  );
}