import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import coinImg from "../../../images/coin.png";
import successImg from "../../../images/redeemSuccess.gif";
import { PostData } from "../../services/fetchData";
import Bounce from "react-reveal/Bounce";
import { authContext } from '../../services/authContext';
import { Api_Endpoints } from '../../../endpoint';
import { Spinner } from 'react-bootstrap';

export default function RedeemCoins({ title, chapterId, genre, gener_color, nid, uid, redeemType, requiredCoins, profileData, location, submitHandler, closeHandler }) {
    const { redeemCoins } = Api_Endpoints;
    const [disable, setDisable] = useState(true);
    const [spin, setSpin] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false);

    //   console.log(successStatus)
    const { coinBalance, setCoinBalance, userprofilefn } = useContext(authContext);


    useEffect(() => {
        if (coinBalance > requiredCoins) {
            setDisable(false);
        }
        return ()=>{
            setSpin(false);
          }
    }, [coinBalance]);

    const submitButtonHandler = () => {
         setSpin(true);
         const req = {
            "nid": nid,
            "userId": uid,
            "id": chapterId
        }
        const reqParam = JSON.stringify(req);
        PostData(`${redeemCoins}`, "Post", reqParam, true, true).then(
            (result) => {
                submitHandler(result);
                userprofilefn(uid);
                setSuccessStatus(true)
            },
            (error) => {
            }
        );
    }
    //  console.log(disable);
    return (
        <>
            {successStatus &&
                <div className="pyro">
                    <div className="before"></div>
                    <div className="after"></div>
                </div>
            }
            <div className="redeemBox">
                <div style={{ backgroundColor: gener_color }} className="redeemTop d-flex align-items-center">
                    <div className="col-8 col-md-9">
                        <h3>{title}</h3>
                        <p>Genre : <span dangerouslySetInnerHTML={{ __html: genre }}></span></p>
                    </div>
                    <div className="col-4 col-md-3">
                        <div className="storyPointBox">
                            <img className="storyCoin mb-1" width="20" src={coinImg} />
                            {requiredCoins}
                            <span>coins</span>
                            <span className="note position-absolute">per {redeemType}</span>
                        </div>
                    </div>
                </div>
                <div className="redeemBottom" 
                // style={{
                //     backgroundImage: successStatus ? `url(${successImg})` : '',
                //     backgroundPosition: "-10px -30px",
                //     backgroundRepeatY: "no-repeat",
                //     backgroundSize: "50%"
                // }}
                >
                    {successStatus && <div className='redeemSucessMsg'>Congratulations!<span>You have successfully redeemed coins.</span></div>}
                    {!successStatus && 
                    <div className="col-12">
                        <div className="totalPointBox">{coinBalance}<span>Your available coins</span></div>
                    </div>
}
                    <div className="buttonBox col-12">
                        <button className="custButton hollow" onClick={closeHandler}>Cancel</button>
                        <button className="custButton" disabled={disable} onClick={submitButtonHandler}>
                        { spin &&
                        <span className='pe-2'>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                       
                        />
                        </span>
                        }
                        Redeem {requiredCoins} Coins
                       </button>
                    </div>
                </div>
            </div>
        </>
    )
}
