import React, {useContext} from "react";
import {Route, Redirect} from 'react-router-dom';
import { authContext } from "./authContext";
import LoaderCustom from "../common/loaders/loaderCustom";

export default function PrivateRoute ({ component: Component, ...otherProps }) {
const { authenticated, isLoading } = useContext(authContext);
return(
     <Route
            {...otherProps}
            render={props => (
                !isLoading
                    ?
                    (
                        authenticated
                            ?
                            <Component {...props} />
                            :
                            <Redirect to={otherProps.redirectTo ? otherProps.redirectTo : '/'} />
                     )
                    :
                    <LoaderCustom />
            )}
        />
)
}