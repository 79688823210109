import React, { useEffect, useState } from 'react';
import {PostData} from "../services/fetchData";

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
/*
const  getAllEvents = () => {
 // const [newEvents, setInitEvents] = useState();
 let newEvents = [];

 // useEffect(() => {
    const req = {
        "uid": localStorage.getItem('uid'),
        "method": "GET"
    }
    const reqParam = JSON.stringify(req);
    PostData('https://api.hophigh.in/user-event', "POST", reqParam, true, true).then(result => {
      //setInitEvents(result);
      let decrpString = window.atob(result.js);
      newEvents = JSON.parse(decrpString);
     // newEvents = JSON.parse(result);
    },
    error => {
    })
  //},[])

  return newEvents
}

export const INITIAL_EVENTS = getAllEvents();*/

export function createEventId() {
  return new Date().getTime() + Math.random();
}
