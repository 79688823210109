import React, { useContext, useState } from "react";
import NoDataImg from "../../../images/empty.svg"

export default function NoDataCard({ nodatatext }) {
    const messages = {
        search: "Oops... we didn't find anything that matches this search.<span>Try search for something more general, change the filters...</span>",
        similar: "Oops... we didn't find anything similar here..",
        login: "To write stories please login with your registered ID or create a new ID"
    }
    return (
        <>

            <div className="nodatacard border-1 mb-md-5 mt-4">
                <img className="nodataimg" src={NoDataImg} />
                <h2 dangerouslySetInnerHTML={{ __html: messages[nodatatext] }}></h2>
            </div>

        </>
    )
}