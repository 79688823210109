import React, { useState, useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function Speech({ data }) {
  const [voiceIndex, setVoiceIndex] = useState(null);
  const [start, setStart] = useState(true);
  const [pause, setPause] = useState(true);
  const onEnd = () => {
   setStart(true);
  };
  const { speak, cancel, speaking, voices} = useSpeechSynthesis({onEnd,});
  const voice = voices[3] || null;
  const [startTp, setStartTp] = useState("Listen");
  const [pauseTp, setPauseTp] = useState("Pause");

  
  useEffect(()=>{    
  window.speechSynthesis.cancel();
  return function cleanUp() {
    window.speechSynthesis.cancel();
  }
  },[data])
 // console.log(data);
  const pausefn = ()=>{
    setPause(!pause);
    if(pause){
     window.speechSynthesis.pause();
     setPauseTp("Resume");
    } else {
      window.speechSynthesis.resume();
      setPauseTp("Pause");
    }
  }
 
  const speakfn = ()=>{
      setStart(speaking);
      if(start){
        speak({ text: data, voice });
        setStartTp("Stop");
        setPauseTp("Pause");
      } else {
        cancel();
        setPause(true);
        setPauseTp("Disabled");
        setStartTp("Listen");
      }
  }

  const startToolip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {startTp}
    </Tooltip>
  );

  const pauseToolip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {pauseTp}
    </Tooltip>
  );

  return (
    <span className="ms-auto speechIconsBar">
      <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 250 }}
    overlay={startToolip}
  >
    <a onClick={speakfn}>
         <i className={`bi ${start ? "bi-volume-up":"bi-volume-mute"}`}></i>
       </a>
  </OverlayTrigger>
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 250}}
    overlay={pauseToolip}
  >
      <a onClick={start? ()=>{}:pausefn} className={start?  "disabled":""} >
        <i className={`bi ${pause ?  "bi-pause": "bi-play"}`}></i>
      </a>
      </OverlayTrigger>
    </span>
    
  );
}

export default Speech;
