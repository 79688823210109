import React, { useState } from "react";


export default function Notification({Status}) {
    const [showToggle, setShowToggle] = useState(Status)
    const closeBtnHandler = () => {
        if(showToggle === "hide"){
            setShowToggle("Show")
        }else{
            setShowToggle("hide")
        }
      }
  return (
  <div className="position-fixed start-50 bottom-0 translate-middle-x p-3" style={{zIndex:"9999"}}>
  <div id="liveToast" className={`toast fade ${showToggle}`} role="alert" aria-live="assertive" aria-atomic="true">
    <div className="toast-header">
      <span><i className="bi bi-exclamation-triangle-fill"></i></span>
      <strong className="me-auto">Good news for our students!!</strong>
      <small>11 mins ago</small>
      <button type="button" onClick={closeBtnHandler} className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div className="toast-body">     	
Enable Online Transactions on your HDFC Bank Card instantly to avail the offer! Simply. Search for the keywords to learn more about each warning.
    </div>
  </div>
</div>
 
  );
}
