import React from "react";
import { NavLink } from "react-router-dom";
import BottomBorder from "../../images//yellowBlueBar.svg"

export default function HomeColumns(props) {
  return (
    <div className="col-md-4 col-lg-3 d-flex flex-column align-items-center align-items-lg-start mb-5 shadowEffect">
      <img className="img-fluid" layout="fill" style={{height:`180px`}} src={props.imgSrc} />
      <h2 className="textColorSecondary mt-2 mb-0">
        <b>{props.title}</b>
      </h2>
     <img layout="fill" src={BottomBorder} className="d-block" />
      <p className="mt-3" dangerouslySetInnerHTML={{__html:props.description}}>

      </p>
      <div className="nav-link px-2 ">
        <NavLink to={`/${props.title.toLowerCase()}`} className="custButton">{props.txtButton}</NavLink>
      </div>
    </div>
  );
}
