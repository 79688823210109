import React, { useEffect, useState } from 'react';
import VideoBlock from './videoBlock';
import {PostData} from "../../services/fetchData";
import {Api_Endpoints} from '../../../endpoint';

export default function SimilarStories({genre, nid, type, tid, genreid}) {
  const {restStories} = Api_Endpoints;

    const [similarStory, setSimilarStory] = useState();   
    useEffect(() => {
        similarStoriesCall();
        window.scrollTo({
          behavior: "smooth",
          top: 0
        });
      },[genre, nid, type])    

      const reqParam = {
        "field_genre_value":genre,
        "field_grade_value":"",
        "nid":"",
        "field_stories_type_value":"" 
      }  

      const similarStoriesCall = (pageNo=0)=>{
        PostData(`${restStories}/${nid}?&page=${pageNo}&genre_id=${genreid}&type=video`, "GET", false).then(result => {
          setSimilarStory(result);
      },
      error => {
      })
      }

return (
    <>
    <section className="container">
    {similarStory && 
    <VideoBlock data={similarStory} title="Similar Videos" noDataTitle="similar" cardFn={similarStoriesCall}/>  
    }
    </section>
    </>
    )
}