import React from "react";
import Titles from "../titles/titles";
import {Api_Endpoints, domain_name} from "../../../endpoint";
import { NavLink } from "react-router-dom";

export default function PersonalDiary({data}) {
   const personalDiary = data.map((item, i) =>{
        return (
        <React.Fragment key={"A"+i}>
        <div className="row" >
        <Titles
        title={item.children.content.title}  >
        </Titles >
        </div>
        <div className="row">
        <div className="col-md d-flex flex-column align-items-center align-items-md-start">
        <p className="mt-3" dangerouslySetInnerHTML={{__html:item.children.content.body}}>
        </p>
        <div className="nav-link px-2">
        <NavLink to="/personaldiary" className="custButton">Lets Begin</NavLink>
        </div>
        </div>
        <div className="col-md float-end">
        <img
        src={`${domain_name}${item.children.content.icon}`}
        layout="fill"
        className="d-block mt-3 float-end img-fluid"
        alt="HopHigh"
        />
        </div>
        </div>
        </React.Fragment>
        )
    })

   return (
   <>
   {personalDiary}
  </>
  );
}

