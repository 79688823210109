import React, { useEffect, useState, useRef } from "react";
import Titles from "../titles/titles";
import SocialActions from "../socialActions/socialActions";
import SimilarStories from "./similar-stories";
import { PostData } from "../../services/fetchData";
import { base_url } from "../../../endpoint";
import Speech from "../../common/speech/speech";
import LoaderCustom from "../../common/loaders/loaderCustom";
import { Api_Endpoints } from "../../../endpoint";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import CoinDetails from "../../coin-details/coin-details";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';
import 'suneditor/src/assets/css/suneditor.css';
import Report from "../report/report";


const { postSocial, readStory } = Api_Endpoints;

export default function SingleStories(props) {
  const [data, setData] = useState();
  const [textContent, setTextContent] = useState();
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [readCoins, setReadCoin] = useState();
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const {
    match: { params },
    location: { state },
  } = props;
  const nid = params.nid;

  useEffect(() => {
    if ("uid" in localStorage) {
      setUid(`${localStorage.getItem("uid")}`);
    } else {
      setUid(null);
    }
    singleStoryCall();
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, [nid, uid]);

  const handleClose = () => {
    setShow(false);
  }

  const req = {
    "uid": localStorage.getItem('uid'),
    "nid": 390,
    "status": "start"
  } 

  const reqParam = JSON.stringify(req);


  useEffect(() => {
    PostData(`${readStory}`, "Post", reqParam, true, true).then(
      (result) => {
        if (result.total_points) {
          setReadCoin(result.total_points);
          setShowToast(true)
        }
      },
      (error) => {
      }
    );

  }, []);

  const singleStoryCall = () => {
    PostData(`${base_url}/filter-stories/${nid}?user_id=${uid}`, "GET", false).then(
      (result) => {
        setData(result.rows[0]);
        let SpeechContent = result.rows[0].body;
        let strippedSpeechContent = SpeechContent.replace(/<[^>]+>/g, '');
        setTextContent(strippedSpeechContent);        
        storyViewCall();
      },
      (error) => {
      }
    );
  };

  const storyViewCall = () => {
    const req = {
      nid: nid,
      uid: localStorage.getItem("uid"),
      type: "view",
      status: true
    };

    const reqParam = JSON.stringify(req);
    PostData(
      postSocial,
      "Post",
      reqParam,
      true,
      true
    ).then(
      (result) => {
      },
      (error) => {
      }
    );
  }

  return (
    <>
     {data ? (
      <Helmet>
        <title>HopHigh Picks Stories</title>
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.field_short_description} />      
        <meta property="og:image" itemprop="image" content={base_url + data.field_images} />   
        <meta property="og:type" content="article" />    
        <link href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css" rel="stylesheet"/>
      </Helmet>
     )
    :
    <Helmet>
        <title>HopHigh Picks Stories</title>
        <meta name="description" content="If reading is your passion, HopHigh is the right place to be in." />
        <link href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css" rel="stylesheet"/>
    </Helmet>
    }
      <section className="container pt-0 ">
        {data ? (
          <div className="row mt-4">
            <div className="col-lg-12">
              {
                data.field_images !== 'NULL' ?
                  <div className="story-image-container">
                    <img className="story-image" src={base_url + data.field_images} />
                  </div>
                  :
                  ''
              }
              <Titles title={data.title} />
              <div
                className="cardInfoBox justify-content-start px-0 "
                style={{ background: "transparent " }}
              >
                <span>
                  <b>Author:</b> <span className="count">{data.author}</span>
                </span>
                <span className="px-md-4">
                  <b>Genre:</b>{" "}
                  <span
                    className="count "
                    dangerouslySetInnerHTML={{ __html: data.field_genre }}
                  ></span>
                </span>
                <span className="d-none d-lg-block">
                  <SocialActions
                    styles={`cardSocials backTransparent justify-content-start px-0`}
                    like={data.field_like}
                    view={data.field_view}
                    bookmark={data.field_bookmarks}
                    wishlist={data.field_wishlist}
                    bookmarkStatus={data.bookmark_status}
                    viewStatus={data.view_status}
                    likeStatus={data.like_status}
                    nid={nid}
                    type="stories"
                    socialImg={base_url + data.field_images}
                  ></SocialActions>
                </span>
                <Speech data={textContent}></Speech>
              </div>
              <div
                className="mt-3 sun-editor-editable"
                dangerouslySetInnerHTML={{ __html: data.body}}
              ></div>
              <div
                className="cardInfoBox justify-content-start px-0 "
                style={{ background: "transparent " }}
              >
                <span>
              <SocialActions
                styles={`cardSocials backTransparent justify-content-start px-0`}
                like={data.field_like}
                view={data.field_view}
                bookmark={data.field_bookmarks}
                wishlist={data.field_wishlist}
                bookmarkStatus={data.bookmark_status}
                viewStatus={data.view_status}
                likeStatus={data.like_status}
                nid={nid}
                type="stories"
                socialImg={base_url + data.field_images}
              ></SocialActions>
              </span>
              <Report nid={nid} user={uid}></Report>
              </div>
            </div>
          </div>
        ) : (
          <LoaderCustom></LoaderCustom>
        )}
      </section>
      <section>
        {data && (
          <SimilarStories
            genre={data.field_genre}
            genreid={data.gener_id}
            nid={nid}
            type={data.field_stories_type}
            tid={data.tid}
          />
        )}
      </section>
      <ToastContainer className="position-fixed p-3" position={`bottom-end`}>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000}  >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Coins Earned</strong>
          </Toast.Header>
          <Toast.Body>
          <h6>Great!! <span>{readCoins}</span> Coins achieved for reading </h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
