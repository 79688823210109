import React, {useContext, useState} from "react";
import Titles from "../titles/titles";
import ModalPop from "../modalPopup/modalPopup";
export default function NotLoginCard({title}) {
const [modal, setModal] = useState();
const [storyType, setStoryType] = useState();

const modalfn = (val)=>{
    setStoryType(val)
    setModal(!modal)
}     
return (
    <>
    <div className="row">
    <Titles title={title}></Titles>
    <div className="nocard border-1 mb-md-5 mt-4">
    <h2>To view {title}</h2>
    <span><a onClick={()=>modalfn("loginCard")} style={{cursor:"pointer"}}>Login</a>
    or
    <a onClick={()=>modalfn("registerCard")} style={{cursor:"pointer"}}>Register</a></span>
    
    {modal &&
    <ModalPop modal={modal}  modalFn={modalfn} storyType={storyType}/>
    } 
    </div>
    </div>
    </>
)
}