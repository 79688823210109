import React, { useMemo, useState, useEffect, useContext } from "react";
import { PostData } from "../services/fetchData";
import { Api_Endpoints } from "../../endpoint";
import Titles from "../common/titles/titles";
import AccountAssistance from "../common/accountAssistance/account-assistance";
import { authContext } from "../services/authContext";
import LoaderCustom from "../common/loaders/loaderCustom";
import Table from "../common/table/table";
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import Profilebox from "../common/misc/profilebox";
import DailyLoginCoins from "../common/coins/dailyLoginCoins";


export default function CoinDetails({location}) {
  const {searchProfile, coinDetails, loginCoins} = Api_Endpoints;
  const {profileData, authenticated } = useContext(authContext);
  const [profileInfo, setProfileInfo] = useState([]);
  const [data, setData] = useState();
  const [dailyCoins, setDailyCoins] = useState(null);
  let history = useHistory();
  let uid = "";

  useEffect(() => {
    uid = localStorage.getItem('uid');
    const userId = localStorage.getItem("userID");
    PostData(searchProfile+userId,
      "GET"
    ).then(
      (result) => {
        setProfileInfo(result);
      },
      (error) => {
      }
    );

    PostData(`${coinDetails}/${uid}?_format=json`,
      "GET"
    ).then(
    (result) => {
      setData(result);
      setDailyCoins(result.logins)
    },
    (error) => {
    }
    );
  }, []);

  const checkInHandler = () => {

    const uid = localStorage.getItem('uid');
    PostData(`${loginCoins}${uid}`, "GET").then(result => {
       // filter(result, req);
       setDailyCoins(result.logins)
    },
      error => {
    })
}



  const storyColumns = useMemo(() => [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Read",
      accessor: "read",
    },
    {
      Header: "Write",
      accessor: "write",
    },
    {
      Header: "Express",
      accessor: "express",
    },
    {
      Header: "Like",
      accessor: "like",
    },
    {
      Header: "Others",
      accessor: "others",
    },
    {
      Header: "Total",
      accessor: "total",
    }
  ]);

  return (
    <>
    <Helmet>
    <title>HopHigh - Dashboard</title>
    <meta name="description" content="Dashboard"/>
    </Helmet>
    {data ?
    <>
      <section className="container">
          <div className="row justify-content-end mt-1 pb-2">
          <div className="col-auto">
          <p className="btn btn-link textColorPrimary text-decoration-underline mb-0 text-uppercase" style={{ fontSize: "12px", cursor:"pointer" }} onClick={history.goBack}>Go Back to {location.state.prevPath}</p>
          </div>  
          </div>
        <div className="row ">
        <div className="col-md-10 d-flex ">
          <Profilebox profileInfo={profileInfo} profileData={profileData} />
          </div>
          <div className="col-md-2">
            <div className="float-end smartbox purpleColor text-center">
              <h1 className="mb-0">
              {data &&
              <b>{data.total_points.available_coins}</b>
              }
              </h1>
              <p className="mb-0">Coins Earned</p>
            </div>
          </div>
        </div>      
      </section>
      <section className="container">
      <DailyLoginCoins checkIn={checkInHandler} dailycoins={dailyCoins} title={`CheckIn`} />
      </section>
      <section className="container">
      {data &&
      <div className=" d-flex flex-wrap justify-content-between">
      <div className="bigFonts readColor">
        <h1>{data.total_points.read_coins}</h1>
        <h6>Read Coins</h6>
      </div>
      <div className=" bigFonts writeColor">
        <h1>{data.total_points.write_coins}</h1>
        <h6>Write Coins</h6>
      </div>
      <div className=" bigFonts expressColor">
        <h1>{data.total_points.express_coins}</h1>
        <h6>Express Coins</h6>
      </div>
      <div className=" bigFonts otherCoinColor">
        <h1>{data.total_points.other_coins}</h1>
        <h6>Other Coins</h6>
      </div>
      <div className=" bigFonts redeemCoinColor">
        <h1>{data.total_points.redeem_coins}</h1>
        <h6>Redeemed Coins</h6>
      </div>
      <div className=" bigFonts purpleColor ">
        <h1>{data.total_points.overall_coins}</h1>
        <h6>Total Coins</h6>
      </div>
      </div>
      }
  </section>
      <section className="container">
        {data.coins_breakup.length !== 0 && 
        <>
        <Titles title="Know your coin in details"/>
        <div className="datagrid mt-5">
       <Table
        data={data.coins_breakup}
        columns={storyColumns}
        />
        </div>
        </>
        }
        <div className="row justify-content-end mt-2">
          <div className="col-auto">
          <p className="btn btn-link textColorPrimary text-decoration-underline mb-0 text-uppercase" style={{ fontSize: "12px", cursor:"pointer" }} onClick={history.goBack}>Go Back to {location.state.prevPath}</p>
          </div>  
          </div>
        <AccountAssistance />
      </section>
      </>
      :
      <LoaderCustom/> }
    </>
  );
}
