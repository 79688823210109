import React, {useState, useEffect, useContext} from "react";
import LoggedOut from "./loggedout";
import LoggedIn from "./loggedIn";
import Logo from "../../../images/logo.svg";
import {BrowserRouter, NavLink} from "react-router-dom";
import styles from './nav.module.css';
import { authContext } from "../../services/authContext";


export default function Nav({data, searchHandler, menuBarHandler}) {
 const arrMenu = data.children;
 const context = useContext(authContext);
 let mobMenuClass = '';
 const[updateMobMenuClass, setupdateMobMenuClass] = useState(mobMenuClass);
 const[isLoggedIn , setIsLoggedIn] = useState();

  const mobMenuHandler = (e) => {
    menuBarHandler();
    //mobMenuClass = "open oppenned";
    if(updateMobMenuClass == "oppenned"){
       setupdateMobMenuClass("")
    }else{
       setupdateMobMenuClass("oppenned")
    }   
  };

  const searchBarfn = ()=>{
    setupdateMobMenuClass("");
    searchHandler()
  }

  return (
    <React.Fragment>
    <nav className="navbar justify-content-between ">
      <div className="d-flex flex-row justify-content-center align-items-center">
      <div className="open d-block d-lg-none" onClick={mobMenuHandler}>
      <div className="nav-icon1">
  <span></span>
  <span></span>
  <span></span>
</div>
        </div>
        <div className={updateMobMenuClass}>
          <div className="sub-menu">
            <a className="mobMenuClose" onClick={mobMenuHandler}><i className="bi bi-x-circle"></i></a>
          <div className="d-flex flex-row justify-content-start">{context.authenticated ? '' : <LoggedOut mobileMenu={mobMenuHandler} showClass={''} />}</div>
           <ul>
              {arrMenu.map((item, i) => {
                const { entity_id, entity_display_name } = item;
                const { link } = item.settings.component;
                return (
                  <li
                  className="nav-item d-flex align-items-center "
                  key={entity_id}>
                  <NavLink to={link} exact={true}  key={i} onClick={mobMenuHandler} >
                    {entity_display_name}
                  </NavLink>
                  </li>
                );
              })}
            </ul>
            </div>
        </div>
        <NavLink  to="/" exact={true} className="headerlogo" >
        <img src={Logo} alt="HopHigh" width="224" height="66" className="img-fluid" />
        </NavLink>
        <div className=" d-none d-lg-block" id="navbarTogglerDemo01">
        <ul
        className={`navbar-nav me-auto  mb-lg-0 d-lg-flex flex-lg-row ms-3 ${styles.headMenu}`}
        style={{ height: "70px" }}>
        {arrMenu.map((item) => {
        const { entity_id, entity_display_name } = item;
        const { link } = item.settings.component;
        return (
        <li
        className="nav-item d-flex align-items-center px-2 "
        key={entity_id}>
        <NavLink to={link} exact={true}  key={entity_id}  className="nav-link customNavlink">
          {entity_display_name}
        </NavLink>
        </li>
        );
        })} 
        </ul>
      </div>
      </div>
      <div className=" d-flex float-end">
      <div className="icon-search d-lg-none"><a onClick={searchBarfn}><i className="bi bi-search" id="searchIcon"></i></a></div>
       {context.authenticated ? <LoggedIn  userName={context.profileData} /> : <LoggedOut mobileMenu={()=>{}} showClass={'d-none'} />}
      </div>
    </nav>
</React.Fragment>
  );
}
