import React,{useState, useEffect} from "react";
import {PostData} from "../../services/fetchData";
import {Api_Endpoints, base_url} from "../../../endpoint";
import {BrowserRouter, NavLink} from "react-router-dom";


export default function Footer() {
 // const {children} = props.data;
  const [menuData, setMenuData] = useState();
  const {footer} = Api_Endpoints;


  useEffect(() => {
    PostData(footer, "GET").then(result => {
      setMenuData(result);
    },
    error => {
    //  console.log("error");
    })
    },[])

    const rootElement = document.documentElement;
    const scrollToTop = () => {
      // Scroll to top logic
      rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }

    return (
        <footer className="footer py-3 hopHighFooter">
        <div className="container-lg d-flex flex-column flex-lg-row align-items-center ">
        <span> <p className="text-white d-block d-md-inline pe-2">Copyright @ 2021 HopHigh. All rights reserved.</p></span>
          <span className="d-flex flex-wrap">
          {menuData && menuData.children.map((item, i) => {
          const {entity_id, entity_display_name} = item;
          const {link} = item.settings.component; 
          return ( 
          <NavLink to={link} exact={true} className="nav-link px-2 customNavlink footerLink px-2 text-white text-decoration-none"  key={i}>{entity_display_name}</NavLink>
          /*<a href={link} className="nav-link px-2 customNavlink footerLink px-2 text-white text-decoration-none" key={entity_id}> 
          {entity_display_name}
          </a>*/
          )
          })
        }
          </span>
        </div>
        <a className="moveTop" onClick={scrollToTop}><i className="bi bi-arrow-up-short"></i></a>
      </footer>
    )
  }


