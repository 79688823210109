import React,{useState, useContext, useEffect} from "react";
import Titles from "../common/titles/titles";
import { useForm} from "react-hook-form";
import { PostData } from "../services/fetchData";
import { Api_Endpoints } from "../../endpoint";
import Modal from "react-bootstrap/Modal"
import { authContext } from "../services/authContext";
import ModalPop from "../common/modalPopup/modalPopup";

export default function FrmPersonalDiary(props) {
  const {authenticated, passCode, skipPassCode} = useContext(authContext);
  const [show, setShow] = useState();
  const [data, setData] = useState();
  const [modal, setModal] = useState();
  const [success, setSuccess] = useState(false);
  const [resetPasscode, setResetPasscode] = useState(false);
  const [uid, setUid] = useState("");
  const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const { register:register2, handleSubmit:handleSubmit2, formState: { errors: errors2 }} = useForm();  
  const {personalDiary, userProfile, userDiary} = Api_Endpoints;
  const [skip, setSkip] = useState(props.skip);


  const  handleClose = ()=>{
    setSuccess(false);
    setValue("mydiary", "");
    props.diaryData(data);
  }
  
  useEffect(()=>{
    if("uid" in localStorage){
      setUid(localStorage.getItem('uid'));
    }  
    },[]);

  const  handlePassCodeClose = ()=>{
    setResetPasscode(false);
  }

  const modalfn = ()=>{
    setModal(!modal)
  }

  
  const onSubmit = (data) => {
    const newSubmitData = `${data.mydiary} <br>`
    if(authenticated){
      if(passCode) {
        const req =  {
          "uid": uid,
          "diary": newSubmitData,
          "date": new Date().toISOString().split('T')[0],
          "type": "POST"
      }
      const reqParam = JSON.stringify(req);
      PostData(userDiary, "POST", reqParam, true, true).then((result) => {
        setShow(true);
        setSuccess(true);
        if(props.skip){
        setData(result.body);
        props.diaryData(result.body);
        } else {
          setData([]);
        }
        },(error) => {
        }
      );
      } else {
        setResetPasscode(true);
      }
      } else {
        setModal(true);
      }
  }

  const resetPasscodeFn = (data)=>{  
      const req = {
      "field_diary_pass" :[
          {
              "value": data.passCode
          }
      ],
      "field_passcode_status": [
          {
              "value": true
          }
      ]
  }

  const reqParam = JSON.stringify(req);
  PostData(`${userProfile}${uid}?_format=json`, "PATCH", reqParam, true, true).then(result => {
   
  },
     error => {
   })
  }

  return (
  <>
  <div className="row">
  <Titles title={props.title} />
  <form onSubmit={handleSubmit(onSubmit)}> 
  <div className="col-12 px-md-3 px-0">
  <textarea className="txtArea" placeholder="Start writing here…" {...register('mydiary', { required: true})}/>
  {errors.mydiary && errors.mydiary.type === "required" && <span className="text-danger">This is required</span>}
  </div>
  <div className="col-12 col-md-5 offset-md-7 mt-4">
  <button type="submit" className="custButton float-end">Submit</button>
  <button type="button" className="custButton hollow float-end mx-3" onClick={() => reset({ mydiary: "" })}>Reset</button>
  </div>
  </form>
  <Modal show={success} dialogClassName={`hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
     <Modal.Header>
     <h3><span className="closebtn"  onClick={handleClose}><i className={`bi bi-x-circle`} ></i></span></h3>
      </Modal.Header >
      <Modal.Body className="pt-0 text-center">
      <i className="bi bi-check-circle-fill text-success  h1"></i>
      <h3>Your personal diary successfully submitted</h3>
      </Modal.Body>
</Modal>

<Modal show={resetPasscode} dialogClassName={`hophighModal modal-dialog-centered`} size="md" onHide={handlePassCodeClose}>
     <Modal.Header>
     <h3><span className="closebtn"  onClick={handlePassCodeClose}><i className={`bi bi-x-circle`} ></i></span></h3>
    </Modal.Header >
    <Modal.Body className="pt-0 text-center">
    <form onSubmit={handleSubmit2(resetPasscodeFn)}>
    <div>
      <div className="form-floating mb-3">
      <input id="passCode" className="form-control customforminput" placeholder="Passcode" {...register2('passCode', { required: true})} />
      <label htmlFor="passCode">Passcode<span className="text-danger ps-1">*</span></label> 
      </div>
      {errors2.passCode && errors2.passCode.type === "required" && <span className="text-danger">This is required</span>}
    </div>
    <button type="submit" className="custButton mt-3">Reset Passcode</button>        
    </form>
</Modal.Body>
</Modal>
</div>
{modal &&
<ModalPop modal={modal} modalFn={modalfn} storyType={"socialModal"} shortDescription="Login for personal dairy"/>
}
</>
)
}