import React from 'react';

export default function Counters({data}) {
  const countArray = data[0].children.content.counters;
  const counter = countArray.map((item,i) =>{
    const colorCode = item.color;
    return (
      <div className="col-6 col-md-3 bigFonts registeredSchool" style={{color:colorCode}} key={i}>
        <h1>{item.counter}</h1>
        <h6>{item.title}</h6>
      </div>
      )
    })
  return(
    <>
     <div className="row">
      {counter}
      </div>
    </>
  )
}
