import React, {Component, useEffect, useState} from "react";
import {AuthProvider} from "./authContext";
import { PostData } from "./fetchData";
import { Api_Endpoints } from "../../endpoint";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function Auth ({children}) {
    const [auth, setAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [profileData, setProfileData] =useState();
    const [passCode, setPassCode] = useState(false);
    const [skipPassCode, setSkipPassCode] = useState(false);
    const [divisionList, setDivisionList] = useState('');
    const [gradeList, setGradeList] = useState('');
    const [schoolList, setSchoolList] = useState('');
    const [genreList, setGenreList] = useState('');
    const [genreVidList, setGenreVidList] = useState('');
    const [profileImage, setProfileImage] = useState("");
    const [coinBalance, setCoinBalance] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [earnedCoins, setEarnedCoines] = useState("");
    const [termsConditionCont, setTermsConditionCont] = useState({});
    
    const {userProfile, school_list, grade_list, gradeType, divList, genre_list, termsCondition} = Api_Endpoints;

    const termsConditions_API = function () {
      return PostData(termsCondition, "GET")
    }

    const school_list_API = function () {
      return PostData(school_list, "GET")
    }

    const grade_list_API = function () {
      return PostData(grade_list, "GET")
    } 

    const div_list_API = function () {
      return PostData(divList, "GET")
    }
     
    const genre_list_API = function () {
      return PostData(genre_list, "GET")
    }

    useEffect(() => {
        if("X-CSRF-Token" in localStorage){
            setAuth(true);
            setIsLoading(false);
         }  else  {
            setAuth(false);
            setIsLoading(false)
         } 
         if("basicToken" in localStorage){
          userprofilefn();
         }
         if("uid" in localStorage){
          const uid = localStorage.getItem('uid');
          userprofilefn(uid);
         }       
    },[]);
    
   useEffect(()=>{
    Promise.all([
      school_list_API(),
      grade_list_API(),
      div_list_API(),
      genre_list_API(),
      termsConditions_API()
      ]).then(function(newData){
        let schoolList_options =[];
        newData[0].forEach((d,i) =>{ 
          const iCount = ++i;
          schoolList_options.push({
              "value" : d.tid,
              "label" : d.field_school_name,
              "address":d.address,
              "id":d.tid
              })
         
        })
        const gradeList_options  =[];
        newData[1].forEach((d,i) =>{ 
            gradeList_options.push({
              "value" : d.grade_id,
              "label" : d.field_grade
            })
         }
        )
        const divList_options  =[];
        newData[2].forEach((d,i) =>{ 
            divList_options.push({
              "value" : d.division,
              "label" : d.division
              })
         }
        )
        const generStoryList = [];
        const generVidoeList = [];
        newData[3].forEach(function (d, i) {
          if(d.type === "Story") {
            generStoryList.push({
              "value": d.key,
              "label": d.value
              })
          };
          if(d.type === "Video") {
            generVidoeList.push({
              "value": d.key,
              "label": d.value
            });
          }
         // return d.type === "Story" || d.value === "All";
        });
      setTermsConditionCont(newData[4].content[0])
      const allObj = {
        "value" : "all",
        "label" : "All"
      }

      schoolList_options.unshift(allObj);
      divList_options.unshift(allObj);
      generStoryList.unshift(allObj);
      generVidoeList.unshift(allObj);
      setSchoolList(schoolList_options);
      setGradeList(gradeList_options);
      setDivisionList(divList_options);
      setGenreList(generStoryList);
      setGenreVidList(generVidoeList);
   });
   },[]);

    const handleAuthentication = (uid)=>{
        setAuth(true);
        userprofilefn(uid);
    }

    const logOutfn = ()=>{
      setAuth(false);
      setPassCode(false);
  }

    const userprofilefn =(uid)=>{
      if(uid) {
      PostData(`${userProfile}${uid}?_format=json`, "GET", "", false, true).then(result => {
        setProfileData(result);
        setProfileImage(result.user_picture);
        setCoinBalance(Number(result.field_coin_count[0].value));
        const passCodeStat = result.field_passcode_status;
        if(passCodeStat.length !== 0){
          setPassCode(passCodeStat[0].value);
        }
       },
         error => {
       })
      }
    }

    const authProviderValue = {
        authenticated: auth,
        isLoading:isLoading,
        profileData:profileData,
        handleAuthentication:handleAuthentication,
        logOutfn:logOutfn,
        userprofilefn:userprofilefn,
        passCode:passCode,
        skipPassCode:skipPassCode,
        setSkipPassCode:setSkipPassCode,
        grade_List:gradeList,
        div_list:divisionList,
        terms_condition:termsConditionCont,
        school_List:schoolList,
        genre_List:genreList,
        genre_vid_List:genreVidList,
        coinBalance,
        setCoinBalance,
        profileImage,
        setProfileImage,
        showToast,
        setShowToast,
        setEarnedCoines
    };

    return (
      <>
      <AuthProvider value={authProviderValue}>
      {children}
      </AuthProvider>
         <ToastContainer className="position-fixed p-3" position={`bottom-end`}>
         <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000}  >
           <Toast.Header>
             <img
               src="holder.js/20x20?text=%20"
               className="rounded me-2"
               alt=""
             />
             <strong className="me-auto">Coins Earned</strong>
           </Toast.Header>
           <Toast.Body>
           <h6>Great!! <span>{earnedCoins}</span> Coins achieved for reading </h6>
           </Toast.Body>
         </Toast>
       </ToastContainer>
       </>
    )
}



