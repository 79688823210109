import './more.css';
import React from 'react';
import Chapters from './chapters';
export const More = ({ info, rendition, visible, handleHideMore, renderChapters, labelDisplayHandler, redeemChapter, chapterAllowed, redeemType }) => {
    if (!visible)
        return null;
    const { title } = info;
    return (
        <div className="more" onClick={handleHideMore}>
          <div className="info">
            <div className="title">{title}</div>
          </div>
          <Chapters rendition={rendition} renderChapters={renderChapters} labelDisplay={labelDisplayHandler} redeemChapter={redeemChapter} chapterAllowed={chapterAllowed} redeemType={redeemType} />
        </div>
      )
};
export default More;