import React,{useState, useEffect} from "react";
import SearchBar from "../search/search";
import Nav from "../header/nav";
import {PostData} from "../../services/fetchData";
import {Api_Endpoints, base_url} from "../../../endpoint";
import Notification from "../../globalNotification/notification";
import Zoom from "react-reveal/Zoom";
import { useComponentVisible } from "../../services/useComponentVisible";

export default function Header(props) {
  const [menuData, setMenuData] = useState();
  const [searchShow, setSearchShow] = useState(false);
  const {header, golbalToken} = Api_Endpoints;
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  useEffect(() => {
    PostData(header, "GET", "", false).then(result => {
      setMenuData(result);
    },
    error => {
  });

  fetch(golbalToken, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((response) => {
    return response.text();
  }).then((data) => {
    localStorage.setItem('globalToken', data);
   }).catch((error)=>{
  });
  },[]);

  
   const menuBarfn = ()=>{
    setSearchShow(false)
   }  

   const iconClick = ()=>{
    setIsComponentVisible(!isComponentVisible);
  }
  
  
  return (
    <div className="container pt-2 " >      
     {menuData && <Nav data={menuData} menuBarHandler={menuBarfn} searchHandler={iconClick}></Nav>}
     <div ref={ref}>
     <SearchBar searchClass={isComponentVisible} mobileSearchbar={()=>setIsComponentVisible(false)}></SearchBar>
     </div>
     <Notification Status={'hide'}/>
    </div>
  );
}
