import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { PostData } from "../../services/fetchData";
import Titles from "../../common/titles/titles";
import Fade from "react-reveal/Fade";


export default function DailyLoginCoins({ dailycoins, title, checkIn }) {
    let uid = "";
    const disableCheckIn = useRef(false);
    useEffect(() => {
        //  uid = localStorage.getItem('uid');
    }, []);



    return (
        <>
            <Titles title={title} />
            <Fade bottom>
                <div className="checkIn-box d-flex">
                    {dailycoins && dailycoins.length !== 0 ?
                        dailycoins.map((item, i) => {
                            let logindate = parseInt(item.date);
                            logindate = new Date(logindate * 1000);
                            logindate = logindate.getDate();
                            let currDate = new Date();
                            currDate = currDate.getDate();
                            if (logindate === currDate) {
                                disableCheckIn.current = true;
                            }
                            if (i > 6) return false;
                            return (
                                <div key={i} className="coinblock" style={{
                                    filter: item.coins == "0" ? 'grayscale(1)' : 'grayscale(0)'
                                }}>
                                    <div className={`coin goldCoin`}><h6>{item.coins}</h6></div>
                                    <div className="dayCount" style={{
                                        color: item.coins == "0" ? '#999' : '#000'
                                    }}>Day {item.day}</div>

                                </div>

                            );
                        }) : ""}


                </div>
                <div className='d-flex justify-content-center py-3'><button onClick={checkIn} disabled={disableCheckIn.current} className='custButton'>{disableCheckIn.current ? 'Checked In Today' : 'Check In'}</button></div>
            </Fade>
        </>
    )
}
