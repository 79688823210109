import React, { useState, useEffect } from "react";
import { PostData } from "../services/fetchData";
import TopBanner from "../common/banner/topBanner";
import Stories from "../common/stories/stories";
import { Api_Endpoints} from "../../endpoint";
import Titles from "../common/titles/titles";
import LoaderCustom from "../common/loaders/loaderCustom";
import {Helmet} from "react-helmet";


export default function PrivacyPolicy() {
  const [data, setData] = useState();
  const { privacypolicy } = Api_Endpoints;

  useEffect(() => {
    window.scrollTo(0, 0);
    PostData(privacypolicy, "GET").then(
      (result) => {
        setData(result);
      },
      (error) => {
      }
    );
  }, []);
  return <>
   <Helmet>
    <title>HopHigh -  Privacy Policy</title>
    <meta name="description" content="Privacy Policy"/>
    </Helmet>
  {data ? <PrivacyPolicyPage data={data} /> : <LoaderCustom></LoaderCustom> }
  </>;
}
function PrivacyPolicyPage({ data }) {
  const moduleArray = data.display_weight;
  const {title, body} = data[0];
  
  return (
    <>

      <section className="container">
      <Titles title="Privacy Policy"></Titles>
      <p className="mt-3" dangerouslySetInnerHTML={{__html: body}}></p>
      </section>
    </>
  );
}

