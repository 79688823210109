import React, { useEffect, useState } from 'react';
import Titles from "../titles/titles";
import Video from './video';
import CardSearch from '../card/cardSearch';
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import NoDataCard from '../card/noDataCard';
import { Spinner } from 'react-bootstrap';

export default function VideoBlock({data, title, form, noDataTitle, loadMore=true, type, cardFn}) {

  const [newdata, setNewdata] = useState(data.rows);
  const [pager, setPager] = useState(data.pager);
  const [currentPage, setCurrentPage] = useState();
  const [showBtn, setShowBtn] = useState();
  const [searchToggle, setsearchToggle] = useState(false);
  const [cardReqParam, setCardReqParam] = useState();
  const [spin, setSpin] = useState(false);

  //const [titlename , setTitlename] = React.useState(data[0].entity_display_name);
  
  const filterData = (item, reqParam)=>{
    setCardReqParam(reqParam);
    setNewdata(item.rows);
    setPager({current_page:0});
    if(item.pager.next_page == 0){
      setShowBtn(false);
    } else {
      setShowBtn(true);
    }
  } 

  useEffect(()=>{
    setPager(data.pager);
    const page = data.pager.current_page;
    if (data.pager.current_page >= 1) {
      setNewdata((prevArray) => [...prevArray, ...data.rows]);
    } else {
      setNewdata(data.rows);
    }
    if (data.pager.next_page == 0) {
      setCurrentPage(page);
      setShowBtn(false);
    } else {
      setShowBtn(true);
    }
    return ()=>{
      setSpin(false);
    }
  },[data])

  const pagerFn = ()=>{
    setSpin(true);
    const page = pager.current_page+1;
    setCurrentPage(page);
    cardFn(page, type, cardReqParam)
  }
  const cardArray = () => {
   
};
 // console.log(cardArray)
  return (
    <div>
    <div style={{'position':'relative'}}  className="row">
    <Fade>   <Titles title={title}></Titles></Fade>
    {form &&
        <span className="searchfilter d-block d-md-none" onClick={()=>setsearchToggle(!searchToggle)}><i className={`bi ${searchToggle ? "bi-funnel-fill": "bi-funnel"}`}></i></span>
        }
    </div>
    <div className={searchToggle ? "d-block": "d-none d-md-block" }>
     {form && <CardSearch genreType="Video" form={form} filter={filterData} showHidefn={()=>setsearchToggle(false)} type={type} />}
    </div>
   <div className="row my-3 gap-0 gap-md-0">
   {newdata.length !== 0 ?
    newdata.map((item,i) =>{
    return(
      <Fade bottom key={i}>
    <div className="col-3 g-2 g-md-4 col-md-6 my-2 col-lg-3" key={i}>
    <Video data={item} key={2+i} type={type}/>
    </div>
    </Fade>
    )
  })
:
<NoDataCard nodatatext={noDataTitle}/>}
    </div>
    <div className="d-flex align-content-center justify-content-center  pt-3 pb-3  mx-auto">
    {loadMore && showBtn && 
    <button type="button" className="custButton hollow" onClick={pagerFn}>
    { spin ?
     <>
     <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      />
     <span className="ps-2">Loading...</span>
     </>
     :
      <span>LOAD MORE VIDEOS</span>
      }
      </button>
    }
    </div>
    </div>
  );
}

