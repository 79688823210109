import React from "react";
import { NavLink, useLocation, Link } from "react-router-dom";

export default function Batches({ data }) {
  const selectedSchool = "";
  const selectedGenre = "";
  const selectedGrade = "";
  const rdTypeVal = "userId";
  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
      <>
    <div style={{'padding':'5px','display':'flex','flexWrap':'wrap'}}>
    {data &&
          data.map((item, i) => {
            const  selectedUserId = item.name;
            return (
              <NavLink
                    onClick={(e) => { e.stopPropagation() }}
                    to={{
                      pathname: "/user",
                      state: {
                        rdValue: false,
                        userSel: { selectedUserId },
                        schoolSel: { selectedSchool },
                        gradeSel: { selectedGrade },
                        genreSel: { selectedGenre },
                        id: "0",
                        type: {rdTypeVal}
                      }
                    }}
                    className="batch_follow">
                 {item.name}
                  </NavLink>
            );
          })}
    </div>
  </>
  );
}