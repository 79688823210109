import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { useController } from 'react-hook-form';

const ButtonCustomList = [
  ['undo', 'redo',
  'font', 'fontSize', 'formatBlock',
  'paragraphStyle', 'blockquote',
  'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
  'fontColor', 'hiliteColor',
  'removeFormat',
  'outdent', 'indent',
  'align', 'horizontalRule', 'list', 'lineHeight',
  'table', 'link', 'image',  /** 'math', */ // You must add the 'katex' library at options to use the 'math' plugin.
  /** 'imageGallery', */ // You must add the "imageGalleryUrl".
  'showBlocks', 'codeView']
  ];

const Editor = ({ control, name, genreName={value:""}, ...props }) => {
  
  const {
    field: {
      value,
      ref,
      ...inputProps
    },
    fieldState: {
      invalid,
      isTouched,
      isDirty
    },
    formState: {
      touchedFields,
      dirtyFields
    }

  } = useController({
    name,
    control,
    rules: { required: true, minLength: genreName.value == "19" ? 350 : 500 },
    defaultValue: ''
  });

  const showInline = (toolbar, context) => {
    console.log(toolbar, context)
  }
  console.log(value);
  return (
    <SunEditor
      {...props}
      {...inputProps}
        setContents={value == undefined ? "" : value}
        inputRef={ref} 
        setOptions={{
        height: 200,
        buttonList: ButtonCustomList,
        imageUploadUrl :null, 
        mode: 'classic'
    }}/>
  );
};

export default Editor;
