import React, { useState, useContext, useEffect } from "react";
import SocialActions from "../socialActions/socialActions";
import YoutubeEmbed from "./YoutubeEmbed";
import { useHistory } from "react-router-dom";
import {authContext} from "../../services/authContext";
import ModalPop from "../modalPopup/modalPopup";
import { NavLink } from "react-router-dom";

export default function Video(props) {

  const [modal, setModal] = useState(false);
  const context = useContext(authContext);
  let history = useHistory();
  const {
    title,
    description,
    author,
    nid,
    field_user_id,
    field_genre,
    gener_color,
    field_bg_color,
    bookmark_count,
    like_count,
    field_bookmarks,
    field_like,
    field_view,
    field_share,
    field_wishlist,
    field_stories_type,
    bookmark_status,
    view_status,
    like_status,
    wishlist_status,
    gener_id,
    field_video_link,
  } = props.data;

  const  selectedUserId = field_user_id;
  const selectedSchool = "";
  const selectedGenre = "";
  const selectedGrade = "";
  const rdTypeVal = "userId";

  let newvar = field_video_link.toString().split("=");
  let videoId = '';

    const regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    const match = field_video_link.match(regExp);
    if(match && match[1].length==11){
      videoId = match[1]
    }

  const redirectFn = (e) => {
  //  if(context.authenticated || props.type=="hophigh_picks_video"){
      return history.push({
        pathname: `/videos/${nid}`,
        state: { field_genre, gener_id }
      });
  //  }
  //  return (setModal(true));
  };
  const modalfn = ()=>{
    setModal(!modal)
  }
  return (
   <>
    <div className="card videocard border-0 shadowEffect2 mb-md-5">
       <div className="m_overlay" style={{cursor:"pointer"}} onClick={redirectFn}>
      <div className="ratio ratio-16x9">
          <img
            className="ratio ratio-16x9 position-absolute"
            src={`https://img.youtube.com/vi/${videoId}/hq720.jpg`}
            alt="YouTube Video"
          />
            <span className="m_yt_button">
              <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
                <path
                  className="ytp-large-play-button-bg"
                  d="m .66,37.62 c 0,0 .66,4.70 2.70,6.77 2.58,2.71 5.98,2.63 7.49,2.91 5.43,.52 23.10,.68 23.12,.68 .00,-1.3e-5 14.29,-0.02 23.81,-0.71 1.32,-0.15 4.22,-0.17 6.81,-2.89 2.03,-2.07 2.70,-6.77 2.70,-6.77 0,0 .67,-5.52 .67,-11.04 l 0,-5.17 c 0,-5.52 -0.67,-11.04 -0.67,-11.04 0,0 -0.66,-4.70 -2.70,-6.77 C 62.03,.86 59.13,.84 57.80,.69 48.28,0 34.00,0 34.00,0 33.97,0 19.69,0 10.18,.69 8.85,.84 5.95,.86 3.36,3.58 1.32,5.65 .66,10.35 .66,10.35 c 0,0 -0.55,4.50 -0.66,9.45 l 0,8.36 c .10,4.94 .66,9.45 .66,9.45 z"
                  fill="#1f1f1e"
                  fillOpacity="0.81"
                />
                <path
                  d="m 26.96,13.67 18.37,9.62 -18.37,9.55 -0.00,-19.17 z"
                  fill="#fff"
                />
                <path
                  d="M 45.02,23.46 45.32,23.28 26.96,13.67 43.32,24.34 45.02,23.46 z"
                  fill="#ccc"
                />
              </svg>
            </span>

      </div>
      <div className="card-body pt-4"
        style={{
          backgroundColor: field_bg_color,
          color: "#000",
          pointer: "cursor",
        }}    
      >
        <h2 className="fw-bold">{title}</h2>
        <p className="mb-0">
          <b className="smlabel">Video By:&nbsp;</b>
          <span className="smcontent d-block d-md-inline position-relative">
            {selectedUserId ?
                  <NavLink
                    onClick={(e) => { e.stopPropagation() }}
                    to={{
                      pathname: "/user",
                      state: {
                        rdValue: false,
                        userSel: { selectedUserId },
                        schoolSel: { selectedSchool },
                        gradeSel: { selectedGrade },
                        genreSel: { selectedGenre },
                        id: "0",
                        type: {rdTypeVal}
                      }
                    }}
                    className="">
                    {author}
                  </NavLink>
                  :
                  "Test"
                }
          {/* {wishlist_status == "1" ? 
        <span className="follow-badge d-none d-md-inline">following</span> : ''
      } */}
          </span>
        </p>
      </div>
      </div>
      <p 
      className="genreBox mb-0 px-1 px-md-3 py-0 py-md-2"
       style={{
        backgroundColor: gener_color,
        color: "#fff"
      }}
      >
        <b className="smlabel">Genre:&nbsp;</b>
        <span className="smgenrecontent d-block d-md-inline" dangerouslySetInnerHTML={{__html: field_genre}}></span>
      </p>
      <SocialActions
      socialImg={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
      title={title}
      styles={`d-none d-md-flex cardSocials`}
      like={field_like} 
      view={field_view} 
      bookmark={field_bookmarks} 
      wishlist={field_wishlist} 
      bookmarkStatus={bookmark_status}
      likeStatus={like_status}
      viewStatus={view_status}
      wishlistStatus={wishlist_status}
      nid={nid}
      type="videos"
      >
      </SocialActions>
    </div>
     {modal &&
      <ModalPop modal={modal} modalFn={modalfn} storyType={field_stories_type} />
    }
</>
  );
}
