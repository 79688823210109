import React from "react"
import ContentLoader from "react-content-loader"

const LoaderHome = (props) => (
  <div className="container d-flex mt-2 customloader-home">
  {/* <ContentLoader 
    speed={2}
    width={800}
    height={400}
    viewBox="0 0 800 400"
    backgroundColor="#efecec"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="12" y="4" rx="18" ry="18" width="578" height="211" /> 
    <rect x="276" y="152" rx="0" ry="0" width="1" height="0" /> 
    <rect x="55" y="231" rx="0" ry="0" width="2" height="1" /> 
    <rect x="52" y="233" rx="0" ry="0" width="1" height="0" /> 
    <rect x="46" y="236" rx="5" ry="5" width="506" height="7" /> 
    <rect x="105" y="413" rx="5" ry="5" width="506" height="7" /> 
    <rect x="46" y="284" rx="5" ry="5" width="506" height="7" /> 
    <rect x="46" y="268" rx="5" ry="5" width="506" height="7" /> 
    <rect x="46" y="251" rx="5" ry="5" width="506" height="7" />
  </ContentLoader> */}
    <ContentLoader viewBox="0 0 500 300" height={'900'} width={'90%'} {...props}>
    <rect x="0" y="8" rx="0" ry="0" width="40" height="18" />
    <circle cx="492" cy="16" r="8" />
    <circle cx="472" cy="16" r="8" />
    <rect x="362" y="8" rx="7" ry="7" width="96" height="16" />

    <rect x="0" y="39" rx="0" ry="0" width="34" height="8" />
    <rect x="50" y="39" rx="0" ry="0" width="36" height="8" />
    <rect x="102" y="39" rx="0" ry="0" width="34" height="8" />
    <rect x="152" y="39" rx="0" ry="0" width="34" height="8" />
    <rect x="202" y="39" rx="0" ry="0" width="36" height="8" />
    <rect x="254" y="39" rx="0" ry="0" width="34" height="8" />

    <rect x="477" y="39" rx="0" ry="0" width="10" height="8" />

    <rect x="19" y="64" rx="0" ry="0" width="465" height="155" />

    <rect x="18" y="225" rx="0" ry="0" width="141" height="38" />
    <rect x="182" y="225" rx="0" ry="0" width="141" height="38" />
    <rect x="343" y="225" rx="0" ry="0" width="141" height="38" />
    <rect x="18" y="270" rx="0" ry="0" width="141" height="38" />
    <rect x="182" y="270" rx="0" ry="0" width="141" height="38" />
    <rect x="343" y="270" rx="0" ry="0" width="141" height="38" />
  </ContentLoader>
  
  </div>
)

export default LoaderHome;

