
import React, {useContext, useEffect, useState}  from "react";
import {domain_name} from "../../../endpoint";
import ModalPop from "../modalPopup/modalPopup";
import {authContext} from "../../services/authContext";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

function TopBanner({data, pageName, click}) {
const [modal, setModal] = useState(false);
const context = useContext(authContext);

const btnClick = () => {
  if(!context.authenticated){
    setModal(true);
  } else {
    click();
  //  window.scrollTo(0, document.body.scrollHeight);
  }

};

const modalfn = ()=>{
  setModal(!modal)
}

return (
<React.Fragment>
<section className="container pb-0 pt-0">
<div className="top-banner-section" >
<Fade bottom cascade>
  <div className="banner-image-div">
    <img className="banner-image" src={domain_name+data[0].children.content.banner_image} /> 
  </div>
  <div className="banner-overlay-div"></div> 
  <div className="banner-text-div" >
    <span className="banner-text">
      <h2 className="banner-h1-text"> {data[0].children.content.title} </h2>
     {data[0].children.content.button &&
      <p className="banner-btn">
        {data[0].children.content.button !== "READ NOW" ?
        <Fade delay={1000}>
        <button 
          type="button"
          className="custButton" 
          onClick={btnClick}>
          {data[0].children.content.button}
        </button>
        </Fade>
        : ""
        }
        </p>
     }
    </span>
  </div>
  </Fade>
</div>
{data[0].children.content.body &&
<div className="col-12 col-lg-10 offset-0 offset-lg-1 innerContent mt-5">
<Fade delay={1000}>
  <div dangerouslySetInnerHTML={{__html:data[0].children.content.body}}>
  </div>
  </Fade>
</div>
}
</section>
<section className="container-fluid"><hr className="row dashed"/></section>
{
modal && pageName == "WRITE NOW" &&
<ModalPop modal={modal} modalFn={modalfn} storyType={pageName}  />
}
{
modal && pageName == "Record Now" &&
  <ModalPop modal={modal} modalFn={modalfn} storyType={pageName}  />
}
{
modal && pageName == "personal-dairy" &&
<ModalPop modal={modal} modalFn={modalfn} storyType={pageName}  />
}
{
modal && pageName == "hophigh_picks_story" &&
<ModalPop modal={modal} modalFn={modalfn} storyType={pageName}  />
}
{
modal && pageName == "coin_story" &&
<ModalPop modal={modal} modalFn={modalfn} storyType={pageName}  />
}
</React.Fragment>
)
}

export default React.memo(TopBanner);
