import React, {useContext, useState, useRef} from "react";
import ModalPop from "../modalPopup/modalPopup";

export default function LoggedOut({mobileMenu, showClass}) {
  const [login , setLogin] = useState();
  const [signUp , setSignUp] = useState();
  const [modal, setModal] = useState();

  const signUpFn = ()=>{
    mobileMenu();
    setSignUp(true);
    setModal(true);
    setLogin(false);
  }

  const loginFn = ()=>{
    mobileMenu();
    setLogin(true);
    setModal(true);
    setSignUp(false);
  }

  const modalfn = ()=>{
    setModal(!modal)
  }
 
  return (
    <>
    <div
      className={`${showClass} nav d-lg-flex align-items-center float-end`}
      style={{ height: "70px" }}>
      <div className="nav-link ">
        <a className="customNavlink" onClick={loginFn}>Login</a>
      </div>
      <div className="nav-link px-1 mt-2">
        <a className="custButton secondary" onClick={signUpFn}>
          {" "}
          Register{" "}
        </a>
      </div>
    </div>
    {modal &&
    <ModalPop modal={true} modalFn={modalfn} loginModal={login} registerModal={signUp}/>
    }
    </>
  );
}
