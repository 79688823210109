import React, {useEffect, useState} from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {createEventId } from './event-utils'
import { PostData } from "../services/fetchData";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import LoaderCustom from "../common/loaders/loaderCustom";
import { addDays } from 'date-fns';
import { GithubPicker } from "react-color"; 
import "react-datepicker/dist/react-datepicker.css";
import { Api_Endpoints } from '../../endpoint'
import {Helmet} from "react-helmet";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

export default function Planner() {
  const [show, setShow] = useState();
  const [showDelete, setShowDelete] = useState();
  const [deleteEvent, setDeleteEvent] = useState();
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [title, setTitle] = useState("");
  const [eventInfo, setEventInfo] = useState({});
  const [startDate, setStartDate] = useState(new Date("2014-08-02"));
  const [endDate, setEndDate] = useState(new Date());
  const [allday, setAllday] = useState();
  const [gridMonth, setGridMonth] = useState();
  const [color, setColor] = useState("#0a58ca");
  const [initEvents, setInitEvents] = useState();
  let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

  const {planner} = Api_Endpoints;

 
 // const INITIAL_EVENTS = currentEvents;
useEffect(()=>{
  let newEvents = [];
  const req = {
    "uid": localStorage.getItem('uid'),
    "method": "GET"
}
const reqParam = JSON.stringify(req);
PostData(planner, "POST", reqParam, true, true).then(result => {
  //setInitEvents(result);
  let decrpString = window.atob(result.js);
  newEvents = JSON.parse(decrpString);
  // newEvents = JSON.parse(result);
  setInitEvents(newEvents);
},
error => {
 alert("error Event")
})
return()=>{
  setInitEvents(null);
}
},[]);

useEffect(()=>{
  eventAPIHandler()
},[currentEvents]);


  const  handleClose = ()=>{
    setShow(false);
    setShowDelete(false);
  }
  
  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible)
  }
  
  const handleDateSelect = (selectInfo) => {
    console.log("event000");
    var dt = new Date(selectInfo.endStr);
    setGridMonth(false);
    if(selectInfo.view.type == "dayGridMonth") {
      dt.setDate( dt.getDate() - 1);
      setGridMonth(true);
    } 
    if("timeGridDay") {
      setAllday(false)
    }
    setEndDate(new Date(dt));
    setStartDate(new Date(selectInfo.startStr));
    setTitle("");
    setEventInfo(selectInfo);
    setShow(true);
  }

  const onSubmit = () => {
    let calendarApi = eventInfo.view.calendar
    if(title){
      const eventObj = {
      id: createEventId(),
      title,
      start: startDate,
      end: endDate,
      allDay: allday,
      backgroundColor: color 
     }
     calendarApi.addEvent(eventObj);
    }
    setShow(false);
  }

  const eventAPIHandler = () => {
    let encrpString = window.btoa(JSON.stringify(currentEvents));
    const req ={
      "uid": localStorage.getItem("uid"),
      "js": encrpString,
      "method":"POST"
    }
    const reqParam = JSON.stringify(req);
     PostData(
      planner,
      "Post",
      reqParam,
      true,
      true
    ).then(
      (result) => {
        let decrpString = window.atob(result.js);
        decrpString = JSON.parse(decrpString);
      },
      (error) => {
      }
    );
  }

  const handleEventClick = () => {
   
    deleteEvent.event.remove();
    setShowDelete(false);
  }

  const deleteEventFn=(clickInfo)=>{
    setShowDelete(true);
    setDeleteEvent(clickInfo);
  }

  const handleEvents = (events) => {
    
   setCurrentEvents(events);
   setCurrentEvents((state) => {
     return state;
   })

  }

  
  const renderEventContent = (eventInfo)=> {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
  }

  const renderSidebarEvent = (event) =>{
  //  console.log(event);
    return (
     
       <li key={event.id}>
       <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
       <i>{event.title}</i>
     </li>
    )
  }

  const handleChangeComplete = (color, event) => {
    setColor(color.hex);
  };

//console.log(initEvents);
  return (
    <>
     <Helmet>
    <title>HopHigh - My Planner</title>
    <meta name="description" content="Schedule your plans"/>
    </Helmet>
   {initEvents ?
    <div className="container">  
    {/* <div className='demo-app-sidebar-section'>
          <h2>All Events ({currentEvents.length})</h2>
          <ul>
            {currentEvents.map(renderSidebarEvent)}
          </ul>
        </div> */}
   
      <div className='myPlanner'>
        {currentEvents && initEvents &&
        <Fade bottom>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          initialView='dayGridMonth'
          longPressDelay={0}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          initialEvents={initEvents} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={deleteEventFn}
          //events={eventInfo}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          allDay={allday}
          //eventAdd={eventAPIHandler}
          //eventRemove={eventAPIHandler}
          /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventChange={function(){}}
          eventRemove={function(){}}
          */
        />
        </Fade>
}
      </div>

    </div>
    : <LoaderCustom></LoaderCustom>}
     
     <Modal show={show}  dialogClassName={`hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
     <Modal.Header  >
     <h3>Add Events<span className="closebtn"  onClick={handleClose}><i className={`bi bi-x-circle`} ></i></span></h3>
      </Modal.Header >
      <Modal.Body className="pt-0">
      <div className="form-floating mb-3">
      <input id="title" className="form-control customforminput" placeholder="Title" value={title} onChange={(e)=>setTitle(e.target.value)}  />
      <label htmlFor="title" >Title</label> 
     </div>
     <div className="mb-3">
      {/*<input id="startDate" className="form-control customforminput" placeholder="Start Date" />*/}
      <label htmlFor="startDate">Start Date</label> 
      <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      timeInputLabel="Time:"
      includeDates={gridMonth ? "" : [new Date(), addDays(new Date(), 0)]}
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeInput
      className="form-control customforminput"
      placeholder="Start Date"
      startDate={startDate}
      endDate={endDate}
    />
     </div>
     <div className="mb-3">
      {/*<input id="startDate" className="form-control customforminput" placeholder="Start Date" />*/}
      <label htmlFor="startDate">End Date</label> 
      <DatePicker
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy h:mm aa"
      includeDates={gridMonth ? "" : [new Date(), addDays(new Date(), 0)]}
      showTimeInput
      className="form-control customforminput"
      placeholder="Start Date"
      startDate={startDate}
      endDate={endDate}
      minDate={startDate}
    />
     </div>
     {gridMonth &&
     <div className="mb-3 form-check mt-3">
    <input id="rememberPass" type="checkbox" className="form-check-input" checked={allday} onChange={()=>setAllday(!allday)}/>
    <label className="form-check-label" htmlFor="rememberPass">All Day</label>
    </div> 
    }
    <div className="mb-3">
    <label htmlFor="color">Background Color</label> 
    <GithubPicker color={color } width="100" onChangeComplete={handleChangeComplete }/>
    </div>
    <div className="text-center">
    <button type="button" className="custButton mt-3" onClick={onSubmit}>Submit</button>
    </div>       
    </Modal.Body>
   </Modal> 
   <Modal show={showDelete}  dialogClassName={`hophighModal modal-dialog-centered`} size="md" onHide={handleClose}>
     <Modal.Header  >
     <h3><span className="closebtn" onClick={handleClose}><i className={`bi bi-x-circle`}></i></span></h3>
    </Modal.Header>
     <Modal.Body>
     <p className="text-center">
     Are you sure you want to Delete <span className="textColorSecondary"> {deleteEvent && 
     deleteEvent.event.title} </span>
     event?
     </p>
      <div className="text-center">
    <button type="button" className="custButton  mt-3 mx-3" onClick={handleEventClick}>Delete</button>
    <button type="button" className="custButton hollow mt-3 mx-3" onClick={handleClose}>Cancel</button>
    </div>   
     </Modal.Body>

   </Modal> 
   </>
  )
};


