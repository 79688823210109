import React, {useEffect, useState} from "react";
import { useForm} from "react-hook-form";
import Titles from "../titles/titles";
import ForgotSuccess from "../../../images/forgot-success.svg";
import Input from "../forms/inputElement";
import forgotImg from '../../../images/forgot-password.svg';
import { PostData } from "../../services/fetchData";
import { base_url, Api_Endpoints } from '../../../endpoint';

export default function ForgotPassword({login}) {
  const [auth, setAuth] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState("");
  
    const {forgot_password} = Api_Endpoints;
    const { register, handleSubmit, formState: { errors }} = useForm();

    const onSubmit = (data) => {
      setFormData(data);
      apiReq(data);
    }

    const apiReq = (val) => {
      const req = {"mail":val.email}
      const reqParam = JSON.stringify(req);
      PostData(forgot_password, "post", reqParam).then(result => {
        setSuccess(true);
        setError("");
      },
        error => {
        setSuccess(false);
        setError(error.responseJSON.message);
      })
    }

    return (
      <>
       {error &&
      <div className="alert alert-danger text-danger" role="alert">
      <span><i className="bi bi-x-circle-fill text-danger"></i> {error}</span>
      </div>}
      { !success ?
      <div className="row">
       <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0 customForm">
       <img src="../forgot-password.svg" className="d-block float-end img-fluid" alt="HopHigh"/>
       </div>
       <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column justify-content-between">  
       <Titles title="Forgot Password"></Titles>
       <p>Enter your registered email address below to receive password reset link</p>
       <form className="customForm" onSubmit={handleSubmit(onSubmit)}>
       <div>
       <div className="form-floating mb-3">
       <input id="email" className="form-control customforminput" placeholder="Enter Email" {...register('email', { required: true, maxLength: 150 })} />
       <label htmlFor="email">Email<span className="text-danger ps-1">*</span></label> 
       </div>
       {errors.email && errors.email.type === "required" && <span className="text-danger">This is required</span>}
       {errors.email && errors.email.type === "maxLength" && <span className="text-danger">Max length exceeded</span> }
       </div>
       <p className="mt-2">Remember Password?&nbsp;<span>
          <a  className="textColorSecondary pe-auto" onClick={login} style={{cursor:"pointer"}}><b>Login</b></a>
          </span></p>
       <button type="submit" className="custButton mt-3">SEND ME THE LINK</button>
       </form>
       </div>      
       </div>
       :
       <div className="row">
          <div className="col-md-6">
            <img
              src={ForgotSuccess}
              className="d-block float-end img-fluid"
              alt="HopHigh Register"
            />
          </div>
          <div className="col-6">
          <Titles title={"Email has been sent!"}></Titles>
            <p className="mt-2">You have successfully created the account.</p>
            <p>Please check your registered email address and follow the instructions to reset your password.</p>
            <div className="nav-link px-0">
              <a href="#" className="custButton" onClick={login}>
                Login
              </a>
            </div>
            <p className="mt-2">Didn't receive the link?&nbsp;<span>
            <a className="textColorSecondary pe-auto" onClick={()=>setSuccess(false)} style={{cursor:"pointer"}}><b>Resend</b></a>
            </span></p>
          </div>
        </div>
       }
       </>
    );
  }


  
