import React, { useRef, useState, useEffect, useContext } from "react";
import { EpubView, ReactReader } from "react-reader";
import Titles from "../common/titles/titles";
import SocialActions from "../common/socialActions/socialActions";
import SimilarEpubStories from "../coin-stories/similar-epub-stories";
import Speech from "../common/speech/speech";
import { Helmet } from "react-helmet";
import { PostData } from "../services/fetchData";
import LoaderCustom from "../common/loaders/loaderCustom";
import { Api_Endpoints, base_url } from "../../endpoint";
import EpubReader from "../epub-reader/epub-reader";
import Modal from "react-bootstrap/Modal";
import RedeemCoins from "../common/coins/redeemCoins";
import { authContext } from "../services/authContext";
import { useHistory } from "react-router-dom";

      
const Epubstories = (props) => {
  // console.log(ReactReaderStyle)
  // And your own state logic to persist state
  const [page, setPage] = useState('')
  const [data, setData] = useState();
  const [showRedeem, setShowRedeem] = useState(false)
  const [location, setLocation] = useState(null);
  const [speachData, setSpeechData] = useState();
  const [bookName, setBookName] = useState(null);
  const [startingPoint, setStartingPoint] = useState();
  const [chapters, setChapters] = useState([]);
  const [redeemCoins, setRedeemCoins] = useState();
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const { postSocial, readStory, epubStory } = Api_Endpoints;
  const [redeemType, setRedeemType] = useState()
  const [redeemChapterName, setRedeemChapterName] = useState('The lion and the Mouse...');
  const [chapterId, setChapterId] = useState();
  const [currRendition, setCurrRendition] = useState()
  const [currToc, setCurrToc] = useState()
  const [genre, setgenre] = useState('Fantasy')
  const [generColor, setGenerColor] = useState("#000")
  const {setShowToast, setEarnedCoines} = useContext(authContext);
  const history = useHistory();  

  const renditionRef = useRef(null);
  const tocRef = useRef(null);
  const chapterAllowedRef = useRef([]);
  const {
    match: { params },
    location: { state },
  } = props;
  const nid = params.nid;

  //const nid = "546";

  useEffect(() => {
    if ("uid" in localStorage) {
      setUid(`${localStorage.getItem("uid")}`);
    } else {
      setUid(null);
    }
    singleStoryCall();
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    
  }, [nid, uid]);

  const req = {
    "uid": localStorage.getItem('uid'),
    "nid": 390,
    "status": "start"
  }

  
   const readStoryAPi = (val)=>{
     req.status = val;
     const reqParam = JSON.stringify(req);
  
    PostData(`${readStory}`, "Post", reqParam, true, true).then(
      (result) => {
        if (result.total_points) {
          setEarnedCoines(result.total_points);
          setShowToast(true);
        }
      },
      (error) => {
      }
    );
   }

  useEffect(() => {
    readStoryAPi("start");
      return(()=>{
        readStoryAPi("stop");
      });
  }, []);


  const singleStoryCall = () => {
    PostData(`${epubStory}${nid}?_format=json&user_id=${uid}`, "GET", false).then(
      (result) => {
        if(result.rows[0].accessStatus){
        setData(result.rows[0]);
        setGenerColor(result.rows[0].gener_color)
        setStartingPoint(result.rows[0].field_chapter_starting_point)
        setgenre(result.rows[0].field_genre)
        //setChapterAllowed(result.rows[0].field_epub_chapter_allowed)
        chapterAllowedRef.current = result.rows[0].field_epub_chapter_allowed
        setChapters(result.rows[0].field_epub_chapters)
        setBookName(result.rows[0].field_epub_file)
        setRedeemType(result.rows[0].field_redeemtype)
        storyViewCall();
      } else {
        history.push("/");
      }
      },
      (error) => {
      }
    );
  };

  const storyViewCall = () => {
    const req = {
      nid: nid,
      uid: localStorage.getItem("uid"),
      type: "view",
      status: true
    };

    const reqParam = JSON.stringify(req);
    PostData(
      postSocial,
      "Post",
      reqParam,
      true,
      true
    ).then(
      (result) => {
      },
      (error) => {
      }
    );
  }

  const handleRedeemClose = () => {
    setShowRedeem(false)
    currRendition.display(localStorage.getItem('cfi'));
   
  }

  const redeemChapterHandler = (toc, rendition) => {
    setCurrToc(toc)
    setCurrRendition(rendition)
    setRedeemChapterName(toc.label.trim())
    setChapterId(toc.id)
    const currChapter = chapters.find(e => e.name === toc.label.trim());
    const chapterAccessible = chapterAllowedRef.current.find(chapter => chapter.name === toc.label.trim());
    if(!chapterAccessible){
      if(currChapter){
        setRedeemCoins(currChapter.coins);
        setShowRedeem(true)
      } 

    }
    
       
   
  }

  const onRedeemSubmit = (resdata) => {
   // setChapterAllowed(resdata.rows[0].field_epub_chapter_allowed)
    chapterAllowedRef.current = resdata.rows[0].field_epub_chapter_allowed
    setTimeout(() => {
      setShowRedeem(false)
      currRendition.display(currToc.href);
    }, 2500);
    
  }

  const redeemBlockedChapterHandler = () => {
    setShowRedeem(true)
  }

  const setSpeechHandler = (data) => {
    setSpeechData(data)
  }
 
  return (
    <>
      <Helmet>
        <title>HopHigh Coin Stories</title>
        <meta name="description" content="Redeem to Read. Redeem your coins to read these best paid stories." />
      </Helmet>
      <section className="container">
      {data ? (
        <div className="mt-2">
          <Titles title={data.title}></Titles>
          <div
                className="cardInfoBox justify-content-start px-0 "
                style={{ background: "transparent " }}
              >
                <span>
                  <b>Author:</b> <span className="count">{data.author}</span>
                </span>
                <span className="px-md-4">
                  <b>Genre:</b>{" "}
                  <span
                    className="count "
                    dangerouslySetInnerHTML={{ __html: data.field_genre }}
                  ></span>
                </span>
                <span className="d-none d-lg-block">
                  <SocialActions
                    styles={`cardSocials backTransparent justify-content-start px-0`}
                    like={data.field_like}
                    view={data.field_view}
                    bookmark={data.field_bookmarks}
                    wishlist={data.field_wishlist}
                    bookmarkStatus={data.bookmark_status}
                    viewStatus={data.view_status}
                    likeStatus={data.like_status}
                    nid={nid}
                    type="stories"
                    socialImg={base_url + data.field_images}
                  ></SocialActions>
                </span>
                <Speech data={speachData}></Speech>
              </div>
         
        </div>


      ): (
        <LoaderCustom></LoaderCustom>
      )}
      </section>
      <section className="container pt-0" style={{width:"100%", height:"94vh"}}>
       {bookName && <EpubReader startingPoint={startingPoint} speech={setSpeechHandler} redeemBlockedChapter={redeemBlockedChapterHandler} redeemChapter={redeemChapterHandler} chapters={chapters} chapterAllowed={chapterAllowedRef.current} book={bookName} redeemType={redeemType}/>}
        </section>
        <section>
        {data && (
          <SimilarEpubStories
            genre={data.field_genre}
            genreid={data.gener_id}
            nid={nid}
            type={data.field_stories_type}
            tid={data.tid}
          />
          )}
      </section>
        <Modal show={showRedeem} dialogClassName={`hophighModal redeemModal modal-dialog-centered`} size="md" >
        <Modal.Body className="px-0">
          <RedeemCoins gener_color={generColor} chapterId={chapterId} title={redeemChapterName} genre={genre} nid={nid} uid={uid} closeHandler={handleRedeemClose} redeemType={redeemType} requiredCoins={redeemCoins} submitHandler={onRedeemSubmit}></RedeemCoins>
        </Modal.Body>
      </Modal>
      
    </>
  )
}

export default Epubstories