import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { getOrientation } from 'get-orientation/browser';
import ImgDialog from './ImgDialog';
import { getCroppedImg, getRotatedImage } from './canvasUtils';
import { styles } from './styles';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

export default function ImageCropper({ imageUpload, croppedResultHandler }){
  const [imageSrc, setImageSrc] = useState(imageUpload)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
    //  console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
      croppedResultHandler(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }

      setImageSrc(imageDataUrl)
    }
  }

  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div className="cropperContainer">
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="cropperControls">
            <div className="sliderBox">
             <span>Zoom</span>
              <RangeSlider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                variant="danger"
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className="sliderBox">
            <span>Rotation</span>
              <RangeSlider
                value={rotation}
                min={0}
                max={360}
                step={1}
                variant="danger"
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <button
              onClick={showCroppedImage}
              className="custButton flex-end"
                
            >
              Show Result
            </button>
          </div>

        </React.Fragment>
      ) : ""}
    </div>
  )
}

function readFile(file) {
  //  console.log(file)
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

