import React, { Component, lazy, useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Titles from "../common/titles/titles";
import Modal from "react-bootstrap/Modal";
import { PostData } from "../services/fetchData";
import { Api_Endpoints } from "../../endpoint";
import { authContext } from "../services/authContext";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

export default function PersonalDiaryView(props) {
  const location = useLocation()

  const { skipPassCode, setSkipPassCode, authenticated } = useContext(authContext);
  const [show, setShow] = useState(false);
  const [passCode, setPassCode] = useState("");
  const [error, setError] = React.useState();
  const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [skip, setSkip] = useState(props.skip);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };



  // const [newData, setNewData] = useState(props.diaryData);
  const [diaryData, setDiaryData] = useState([]);
  const { userDiary } = Api_Endpoints;
  const dData = props.diaryData;
  const handleClose = () => {
    setShow(false);
    setValue("passcode", "");
    setError("");
  };
  const handleShow = () => setShow(true);


  useEffect(() => {
    console.log(skip)
    if (skip == true) {
      setDiaryData(props.diaryData);      
    }
    return ()=>{
      setSkipPassCode(false);
     // setShow(true);
    }
  }, [dData, skip])


  const handleSubmitCode = (data) => {
    const req = {
      password: data.passcode,
      uid: localStorage.getItem("uid"),
      date: new Date().toISOString().split("T")[0],
      type: "GET"
    };

    const reqParam = JSON.stringify(req);
    PostData(userDiary, "POST",
      reqParam, true, true).then((result) => {
        if (result.pasword_status == undefined) {
          setPassCode("");
          
          props.diaryFn(result.body);          
          setSkip(true);
          props.skipFn(true);
          handleClose();
          console.log(skip)
        } else if (result.pasword_status == false) {
          setError(result.error)
        }
      },
        (error) => {
          setError(error)
        }
      );
  };

  console.log(props);

  return (
    <>
      <div className="row mt-4">
        <Titles title={props.title}></Titles>
        <div className="col-12">
          <div className="diary-box mt-4">
            {diaryData.length == 0 && !skip ?
              <div className="unlock-box align-items-center justify-content-center">
                <button className="custButton" onClick={handleShow}>
                  Unlock your diary
                </button>
              </div>
              :
              <div className="diaryView">
                {diaryData.length == 0 ?
                 <b className="mt-5 d-block text-center">You have not started pinning your thoughts yet! Start it now...</b>
                  :
                
                    diaryData.map((item, i) => {
                      let date = new Date(item.date);
                      var y = date.getFullYear();
                      /* var m = date.getMonth();*/
                      var d = date.getDate();
                      const m = date.toLocaleString('default', { month: 'short' });
                      return (
                        <Bounce key={`01${i}`} delay={500}>
                        <div>
                          <span><h3 className="mb-0">{d}</h3><div><h6 className="pe-1 d-inline-block">{m}</h6><h6 className="ps-1 d-inline-block">{y}</h6></div></span>
                          <p dangerouslySetInnerHTML={{ __html: item.body }}></p>
                        </div>
                        </Bounce>
                      );
                    })
                 
                }

              </div>
            }
          </div>
        </div>
      </div>
      <Modal
        show={show}
        dialogClassName="hophighModal"
        size="md"
        onHide={handleClose}
      >
        <Modal.Header>
          <h3 className="closebtn" onClick={handleClose}>
            <i className="bi bi-x-circle"></i>
          </h3>
        </Modal.Header>
        <Modal.Body>
          {error &&
            <div className="alert alert-danger text-danger" role="alert">
              <span><i className="bi bi-x-circle-fill text-danger"></i> {error}</span>
            </div>}
          <Titles title={`Unlock your diary`}></Titles>
          <form onSubmit={handleSubmit(handleSubmitCode)} className="customForm py-4 d-flex flex-column align-content-center justify-content-center">
            <div className="form-floating mb-3">
              <input
                type={passwordShown ? "text" : "password"}
                id="passcode"
                className="form-control customforminput"
                placeholder="Pass Code"
                {...register('passcode', { required: true })}
              />
              <i className={`eyeIcon bi ${passwordShown ? "bi-eye-slash" : "bi-eye"}`} onClick={togglePasswordVisiblity}></i>
              <label htmlFor="passcode">
                Enter your passcode for personal diary
                <span className="text-danger ps-1">*</span>
              </label>
            </div>
            {errors.passcode && errors.passcode.type === "required" && <span className="text-danger">This is required</span>}
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="custButton mt-3"

              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
