import React, { useEffect, useState, useContext } from "react";
import { PostData } from "../../services/fetchData";
import { Api_Endpoints } from "../../../endpoint";
import { authContext } from "../../services/authContext";
import ModalPop from "../modalPopup/modalPopup";
import {InlineShareButtons} from 'sharethis-reactjs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const { updateStory, postSocial } = Api_Endpoints;

export default function SocialActions(props) {
  const {type, socialImg, storyDesc, title} = props;
  const [modal, setModal] = useState(false);
  const [socialModal, setSocialModal] = useState(false);
  const {authenticated} = useContext(authContext);
  const [like, setLike] = useState(props.like || "0");
  const [view, setView] = useState(props.view || "0");
  const [wishlist, setWishlist] = useState(props.wishlist || "0");
  const [share, setShare] = useState(props.share || "0");
  const [bookmark, setBookmark] = useState(props.bookmark || "0");
  const [nid, setNid] = useState(props.nid);
  const [bkmstatus, setBkmstatus] = useState();
  const [viewstatus, setviewstatus] = useState();
  const [likestatus, setLikestatus] = useState();
  const [wishlistStatus, setWishlistStatus] = useState();

  useEffect(()=>{

    setLikestatus(props.likeStatus);
    setBkmstatus(props.bookmarkStatus)
    setviewstatus(props.viewStatus)
    setWishlistStatus(props.wishlistStatus)

    
  },[props]);
  
  const onSubmit = (val) => {
    let socialStatus = true;
    if(authenticated){
    
    let likeParam = like,
      viewParam = view,
      wishlistParam = wishlist,
      bookmarkParam = bookmark;
    switch (val) {
      case "like":        
        if(likestatus == "0"){
          socialStatus = true;
          setLikestatus("1");
        } else {
          socialStatus = false;
          setLikestatus("0");
        }
        break;
      case "view":
        viewParam = (parseInt(view) + 1).toString();
        setView(viewParam);
        break;
      case "wishlist":
        if(wishlistStatus == "0"){
          socialStatus = true;
          setWishlistStatus("1");
        } else {
          socialStatus = false;
          setWishlistStatus("0");
        }
        break;
      case "bookmark":
        if(bkmstatus == "0"){
          socialStatus = true;
          setBkmstatus("1");
          } else {
          socialStatus = false;
          setBkmstatus("0");
        }
        break;
      default:
    }

    const req = {
      nid: nid,
      uid: localStorage.getItem('uid'),
      type: val,
      status: socialStatus
    };

    const reqParam = JSON.stringify(req);
    PostData(
    postSocial,
      "Post",
      reqParam,
      true,
      true
    ).then(
      (result) => {
        switch (val) {
          case "like":            
            setLike(result.like);
            break;
          case "view":
            setView(result.view);
            break;
          case "wishlist":
             setWishlist(result.wishlist);
            break;
          case "bookmark":
            setBookmark(result.bookmark);
            break;
          default:
        }
      },
      (error) => {
      }
    );
  } else {
    setModal(true);
    setSocialModal(true);
  }
  };

  const modalfn = ()=>{
    setModal(!modal)
  }
 
  const styles = props.styles;
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Share it with your friends!!</Popover.Header>
      <Popover.Body>
      <InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'null',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [ 
              'whatsapp',          // which networks to include (see SHARING NETWORKS)
              'email',
              'pinterest',
            ],
            padding: 6,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 40,             // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            url: `https://www.hophigh.in/${type}/${nid}`, // (defaults to current url)
            image: socialImg,  // (defaults to og:image or twitter:image)
            description: storyDesc,       // (defaults to og:description or twitter:description)
            title: title,            // (defaults to og:title or twitter:title)
            message: 'Loved to read this!!',     // (only for email sharing)
            subject: 'Loved to read this!!',  // (only for email sharing)
            username: 'custom twitter handle' // (only for twitter sharing)
          }}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
    <div className={`${styles}`}>
      <span>
        <a onClick={() => onSubmit("like")}>
          <i className={`bi bi-heart${likestatus == "1" ? "-fill" : ""}`}></i>
          <span className="count">{like}</span>
        </a>
      </span>
      <span>
        <a style={{cursor:"default"}}>
          <i className="bi bi-eye"></i>
          <span className="count">{view}</span>
        </a>
      </span>
      
      <span>
      <OverlayTrigger trigger="click" rootClose  placement="top" overlay={popover}>
      <i className="bi bi-share customShare"></i>
      </OverlayTrigger>
             {/* <a>
          <i className="bi bi-share sharethis-inline-share-buttons customShare"></i>
        </a> */}
      </span>
      {/* <span>
        <a onClick={() => onSubmit("wishlist")}>
          <i className={`bi bi-person-plus${wishlistStatus == "1" ? "-fill" : ""}`}></i>
             <span className="count">{wishlist}</span>
        </a>
      </span> */}
      <span>
        <a onClick={() => onSubmit("bookmark")}>
          <i className={`bi bi-bookmark${bkmstatus == "1" ? "-fill" : ""}`}></i>
          <span className="count">{bookmark}</span>
        </a>
      </span>
    </div>
     {modal &&
     <ModalPop modal={modal} modalFn={modalfn} storyType={"socialModal"} shortDescription="To like, bookmark, follow the story please login with your registered ID or create a new ID"/>
     }
    </>
  );
}
