import './navigator.css';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const Navigator = ({ rendition, currentPages, chapterName, totalPages, handleShowMore, visible, handleBookmark, handleNext, handlePrev, percent, percentString = '$percent of this book', }) => {
  if (!visible)
        return null;
 //   percent = Math.round(percent.toFixed(2) * 100);
   // const percentStr = percentString.replace('$percent', percent + '%');
    const pagesDetails = `Page ${currentPages} of ${totalPages}`;
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
       Table of content
      </Tooltip>
    );
    return (
        <div className="navigator">
        <a className="more-info-area" onClick={handleShowMore}>
          <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}>
         <i className="bi bi-card-checklist"></i></OverlayTrigger></a>
        <a className="prev-area" onClick={handlePrev}><i className="bi bi-arrow-left-circle-fill"></i></a>
        <a className="next-area" onClick={handleNext}><i className="bi bi-arrow-right-circle-fill"></i></a>
        {pagesDetails ? <div className="page-number">{pagesDetails}</div> : null}
        {/* <div className="bookmark-area"><OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
  >
  <i onClick={handleBookmark} className="bi bi-file-earmark-plus"></i>
  </OverlayTrigger></div> */}
      </div>
        );
};
export default Navigator;