import React from 'react';
import Reader from './reader';
import { base_url } from "../../endpoint";

const onRelocated = (location) => {
   
  //  console.log("In Reloacted outside   " + cfi)    
};
const onNext = (ebook, rendition) => {
    const cfi =  rendition.location.end.cfi;
    const curLoc = rendition.location.end.index;
    localStorage.setItem('cfi', cfi);
   // var currContent = ebook.navigation.tocById;
    //   console.log(currContent)

    console.log("In Next outside")
}
const onRendered = (section) => {
    console.log("In Rendered outside")
}
export default function EpubReader({ startingPoint, book, redeemChapter, speech, chapters, chapterAllowed, redeemType, redeemBlockedChapter }) {
    const bookURL = `${base_url}${book}`
    const cfi = localStorage.getItem('cfi');
    return <Reader startingPoint={startingPoint} redeemType={redeemType} onNext={onNext} chapters={chapters} chapterAllowed={chapterAllowed} speech={speech} redeemBlockedChapter={redeemBlockedChapter} redeemChapter={redeemChapter} url={bookURL} onRendered={onRendered} onRelocated={onRelocated} cfi={cfi} showPercentage={`true`} />;
};
