import React from "react";
import {domain_name} from "../../../endpoint";

export default function BgQuotes({data}) {
  return (
   <>
   {
   data.map((item,i) =>{
   return(     

   <div className="row quote-Bg-Block" key={i} style={{"backgroundImage":`url(${domain_name+item.children.content.banner_image})`}}>
      <div className="d-flex flex-column align-items-center align-items-lg-end w-auto"><h2 dangerouslySetInnerHTML={{__html: item.children.content.title}}></h2>
      <span>{item.children.content.author}</span></div>
   </div>

    )})
    }
    </>
  );
}
