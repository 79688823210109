import React, {useEffect, useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { PostData } from "../../services/fetchData";
import {Api_Endpoints} from "../../../endpoint";

export default function DropNotification(props) {
const {notification, clearNotification} =  Api_Endpoints;
const [data, setData] = useState("");
const [count, setCount] = useState(0);
const [bell, setBell] = useState(localStorage.getItem('showBellCount') ? localStorage.getItem('showBellCount') : false);
const [showclass, setShowclass] = useState("d-none");
const [showSeeAll, setShowSeeAll] = useState("d-block");

  useEffect(() => {
    let isCancelled  = false;
    if("uid" in localStorage){
    const uid = localStorage.getItem('uid');
    PostData(notification+uid, "GET").then(
        (result) => {
          if(!isCancelled){
          if(result.notification !== "notification is empty"){
            setData(result.notification);
            localStorage.getItem('showBellCount') ? setBell(localStorage.getItem('showBellCount')) : setBell(true)
            const notification = result.notification;
            setCount(notification.length);
          } else {
            setCount(0);
          }
          }
        },
        (error) => {
        }
      );
    } 
    return () => { isCancelled = true };
    },[]);

    const SeeAllFn = (e)=>{
      e.preventDefault();
      setShowclass("d-block");
      setShowSeeAll("d-none");
      localStorage.setItem('showBellCount', false);
    }

    const clearHandler = () => {
      setData("")
      const uid = localStorage.getItem('uid');
      PostData(clearNotification+uid+"?_format=json", "POST", "", true, true).then(result => {
        //setInitEvents(result);
        console.log(result)
      },
      error => {
       alert("error Event")
      })
    }

    const bellClickHandler = () => {
      localStorage.setItem('showBellCount', false);
      setBell(false)
    }
    

    return (
      <>
        <Dropdown className="notificationblock" align="end" autoClose="outside" onClick={bellClickHandler}>
        <Dropdown.Toggle as={"div"}  id="drpNotification" >
        <a id="btnnotification" className="custombtnlink">
        <i className="bi bi-bell"></i>
        {bell == true ? <span className="counttag">{count}</span> : ''}
      </a>
        </Dropdown.Toggle>
        {data &&
        <Dropdown.Menu>
          <div className="notifyBar"><p>Notifications</p><a onClick={clearHandler}>Clear All</a></div>
          {data.map((val,i)=>{
            let date = parseInt(val.date);         
            date = new Date(date * 1000);
            const dateVal = date.getDate()+
          " "+date.toLocaleString('en-us', { month: 'short' })+
          " "+date.getFullYear()+
          " "+date.getHours()+
          ":"+date.getMinutes()+
          ":"+date.getSeconds();
           return <Dropdown.Item as="div" key={val.id} className={i > 5 ? showclass : ""}><span dangerouslySetInnerHTML={{__html: val.message}}></span><span>{dateVal}</span></Dropdown.Item>
          })}
          { data.length > 5 && 
         <Dropdown.Item  className={`btnSeeAll ${showSeeAll}`} onClick={SeeAllFn}>See All</Dropdown.Item>
          }
         </Dropdown.Menu>
           }
        </Dropdown>
     
      </>
    );
  }


  
