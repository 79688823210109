import './reader.css';
import React, { useEffect, useRef, useState } from 'react'
import ePub, { Rendition, Location } from 'epubjs'
import Navigator from './navigator';
import More from './more';
import Loader from './loader';
import { useSwipeable, EventData } from 'react-swipeable';


export const Reader = ({
    url,
    fontSize,
    fontFamily,
    fontColor,
    onLoad,
    onNext,
    onPrev,
    onRelocated,
    onRendered,
    showPercentage = true,
    percentString,
    className = '',
    cfi,
    redeemType,
    redeemBlockedChapter,
    renderChapters,
    redeemChapter,
    chapters,
    chapterAllowed,
    startingPoint,
    speech
}) => {
    const ref = useRef(null);
    const [rendition, setRendition] = useState(null);
    const [isMoreShow, setIsMoreShow] = useState(false);
    const [info, setInfo] = useState();
    const [percent, setPercent] = useState(0);
    const [currentPages, setCurrentPages] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [chapterName, setChapterName] = useState('');
    const [bookmarkPage, setBookMarkPage] = useState(null);
    const [navigation, setNavigation] = useState(null);
    const [toc, setToc] = useState(null);
    useEffect(() => {
     
        const el = ref.current;
        if (!el)
            return;
        const ebook = ePub(url);
        const rendition = ebook.renderTo(el, { flow: 'paginated', width: '100%', height: '100%' });
       

        onReaderLoad(ebook, rendition);

        
    }, []);


    const onReaderLoad = (ebook, rendition) => {
        if (!rendition)
            return;
        setRendition(rendition);        
     //  console.log("readerload")
      //  const startChapter = ebook ? ebook.navigaton.tocById[startingPoint.id] : "";
      //const startingChapter = chapters.find(e => e.id === startingPoint.id);
       
        setupStyles(rendition);

        const awaitfn = (val)=>{
            return new Promise(resolve => {
               ebook.locations.generate(1600);
                onLoad && onLoad(rendition);
                onRelocated && rendition.on('relocated', handleRelocated(ebook));           
                onRendered && rendition.on("rendered", function(section){
                   const currentTOC = val[section.index];
                   redeemChapter(currentTOC, rendition);
                   onRendered(section)
                  });
            })
        };

        ebook.ready.then(async () => {
            const { package: { metadata = {} } = {} } = ebook;
            setInfo(metadata);
            const { navigation } = ebook;            
            const { toc } = navigation;
            setNavigation(navigation)
            setToc(toc)
            await awaitfn(toc);
        });

        ebook.loaded.navigation.then(function(navigation){
            //rendition.display(startingPoint.src)
             const startChapter = navigation ? navigation.tocById[startingPoint.id] : "";
             let displayed = startingPoint ? rendition.display(startChapter) : rendition.display();
             displayed.then(function () {
                 const speechContent = rendition.getContents();
                 speech(speechContent[0].content.innerText)
                 const currLocation = rendition.currentLocation();
                 setCurrentPages(() => {
                     return currLocation.start.displayed.page;
                 })
                 setTotalPages(() => {
                     return currLocation.start.displayed.total;
                 })
                 
             });
          });

    };

    const setupStyles = (rendition) => {
        fontSize && rendition.themes.default({ p: { 'font-size': `${fontSize} !important` } });
        fontColor && rendition.themes.default({ p: { color: `${fontColor} !important` } });
        fontFamily && rendition.themes.default({ p: { fontFamily: `${fontFamily} !important` } });
    };

    const handleRelocated = (ebook) => (location) => {
     //   const chapterFound = chapterAllowed.find(chapter => chapter === tocCurrent.label.trim())
        onRelocated(location);
        //    const percent = ebook.locations.percentageFromCfi(location.start.cfi);
        const currentNew = ebook.locations.currentLocation;
        const currPage = ebook.pageList.pageFromCfi(location.start.cfi);
        //    console.log(currentNew)
        const textContent = ebook.rendition.getContents();
        speech(textContent[0].content.innerText);

        setBookMarkPage(location.start.cfi)
        const currentPage = location.end.displayed.page
        const totalPage = location.start.displayed.total
        setPercent(percent);
        setCurrentPages(currentPage)
        setTotalPages(totalPage)
    };
    const handleShowMore = () => setIsMoreShow((isMoreShow) => { return !isMoreShow});
    const handleHideMore = () => setIsMoreShow(false);
    const handleNext = () => {
        const temploc = rendition.currentLocation();
    
        // if(rendition.location.end.displayed.page + 1 < rendition.location.end.displayed.total){
        //     if (!rendition)
        //     return;

        // rendition.next();
        // // const speechContent = rendition.getContents()
        // // console.log(rendition);
        // onNext && onNext(rendition);
        // }else{
        //     return;
        // }
        
        rendition.next();
        onNext && onNext(navigation, rendition);
        
        // const speechContent = rendition.getContents()
        // console.log(rendition);
       

    };

    const handlePrev = () => {
        // if (rendition.location.start.displayed.page === 1) {
        //     return;
        // }
        const temploc = rendition.currentLocation();
       
        if (!rendition)
            return;
        rendition.prev();
        onPrev && onPrev(rendition);
    };


    const handleSwipe = (dir) => {
        //  console.log(dir)
        // const { dir } = eventData;
        if (dir === 'Left')
            handleNext();
        if (dir === 'Right')
            handlePrev();
    };

    const handlers = useSwipeable({
        onSwiped: ({ dir }) => handleSwipe(dir),
        //onSwipedRight: () => slide(PREV),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const labelDisplayHandler = (chapterName) => {
        setChapterName(chapterName)
    }
    const handleBookmark = () => {
        localStorage.setItem('cfi', bookmarkPage);
    }

    return (
        <div {...handlers} className="react-epubjs">
            {!rendition && <Loader />}
            <Navigator
                rendition={rendition}
                handleShowMore={handleShowMore}
                visible={true}
                handleNext={handleNext}
                handlePrev={handlePrev}
                percent={showPercentage ? percent : null}
                percentString={percentString}
                currentPages={currentPages}
                totalPages={totalPages}
                chapterName={chapterName}
                handleBookmark={handleBookmark}
            />
            <More
                info={info}
                rendition={rendition}
                visible={isMoreShow}
                handleHideMore={handleHideMore}
                renderChapters={renderChapters}
                labelDisplayHandler={labelDisplayHandler}
                redeemChapter={redeemChapter}
                chapterAllowed={chapterAllowed}
                redeemType={redeemType}
            />
            <div className={`reader ${className} ${showPercentage ? 'pb-25' : ''}`} ref={ref} />
        </div>
    )
};
export default Reader;