import React, { Component, lazy, useContext, useEffect, useState, useRef } from 'react';
import {PostData} from "../services/fetchData";
import {Api_Endpoints, domain_name} from "../../endpoint";
import TopBanner from '../common/banner/topBanner';
import FrmPersonalDiary from './frmPersonalDiary';
import PersonalDiaryView from './personalDairyView';
import LoaderCustom from '../common/loaders/loaderCustom';
import { authContext } from "../services/authContext";
import { useForm } from 'react-hook-form';
import {Helmet} from "react-helmet";

export default function PersonalDiary() {
  const [data, setData] = useState();
  const {personal_diary} = Api_Endpoints;


  useEffect(() => {
    window.scrollTo(0, 0);
    PostData(personal_diary, "GET").then(result => {
      setData(result);
    },
    error => {
    });
    return()=>{
      setData(null); //unsubscribe
    }
  },[])
  return (
    <React.Fragment>
    <Helmet>
    <title>HopHigh - Personal Diary</title>
    <meta name="description" content="Talk to yourself at least once a day, or you may miss meeting an excellent person."/>
    </Helmet>
      { data ?
      <PersonalDiaryPage data={data} /> : <LoaderCustom></LoaderCustom> 
      }
    </React.Fragment>
  );
}

function PersonalDiaryPage ({data}) {
  const moduleArray = data.display_weight;
  const {modules} = data; 
  const {banner, personal_diary} = data;
  const [personalDiary, setPersonalDiary] = useState([]);
  const [skip, setSkip] = useState(false);

 // const [unbox, setUnbox] = useState(true);
  const {passCode, authenticated} = useContext(authContext);
  const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const el2 = useRef();

  const personalDiaryFn = (dd, ub)=>{
    setPersonalDiary(dd);
  }

  const skipfn =(bool)=>{
    setSkip(bool);
  }

  useEffect(()=>{
  if(!authenticated){
    setSkip(false);
    setPersonalDiary([]);
  }
  },[skip, authenticated]);


return (
      <React.Fragment>
     {data &&
      <>
      <TopBanner data={banner} pageName={"personal-dairy"} click={()=> scrollToDiv(el2)} />
      <div className="container" ref={el2} >
      <FrmPersonalDiary title={'Personal Diary'} skip={skip} diaryData={personalDiaryFn}  />
      {/* <Planner></Planner> */}
      {passCode &&
      <PersonalDiaryView title={'Personal Diary'} skip={skip} skipFn={skipfn} diaryData={personalDiary} diaryFn={personalDiaryFn}  unblock={true}/>
      }
      </div>
      </>
      } 
      </React.Fragment>
  );
}

