import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SocialActions from "../socialActions/socialActions";
import ModalPop from "../modalPopup/modalPopup";
import { authContext } from "../../services/authContext";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import RedeemCoins from "../../common/coins/redeemCoins";

export default function Card({data, type, coinStory}) {
  const [modal, setModal] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false)
  const [coinStoryBool, setCoinStoryBool] = useState(coinStory) 
  const [totalCoins, setTotalCoins] = useState('20')
  const [successStatus, setSuccessStatus] = useState(false)
  const [uid, setUID] = useState(localStorage.getItem('uid'))
  const {authenticated, userprofilefn} = useContext(authContext);
  const [chapterId, setChapterId] = useState();
  let history = useHistory();
  const {
    title,
    field_short_description,
    author,
    nid,
    field_user_id,
    field_genre,
    gener_color,
    field_bg_color,
    field_bookmarks,
    field_like,
    field_view,
    field_share,
    field_wishlist,
    field_stories_type,
    bookmark_status,
    view_status,
    like_status,
    wishlist_status,
    gener_id,
    accessStatus,
    field_redeemtype,
    field_redeem_book_coins,
    field_epub_chapters,
    field_chapter_starting_point,
    field_epub_chapter_allowed
  } = data;

  const selectedUserId = field_user_id;
  const selectedSchool = "";
  const selectedGenre = "";
  const selectedGrade = "";
  const rdTypeVal = "userId";
  const redirectFn = (e) => {
  
    if (!coinStoryBool) {
      //if(context.authenticated || props.type == "hophigh_picks_story"){
      return history.push({
        pathname: `/stories/${nid}`,
        state: { field_genre, gener_id },
      });
    } else if(coinStoryBool & !accessStatus & authenticated) {
      if(field_redeemtype == "Book"){
        setTotalCoins(field_redeem_book_coins !== '' ? field_redeem_book_coins : 0)
      }else{
        setChapterId(field_chapter_starting_point.id)
        setTotalCoins(field_epub_chapters.length > 0 ? field_chapter_starting_point.coins : 0)
      }
      
      setShowRedeem(true);
    } else if(coinStoryBool & accessStatus & authenticated) {
      return history.push({
        pathname: `/coinstories/${nid}`,
        state: { field_genre, gener_id},
      });
    } else if(coinStoryBool & !authenticated ) {
      modalfn();
    } 
    //}
    //return (setModal(true));
  };

  const modalfn = () => {
    setModal(!modal)
  }

  const handleRedeemClose = () => {
    setShowRedeem(false)
  }

  const onRedeemSubmit = () => {
    
    setSuccessStatus(true)
    //setTotalCoins('320')
    setTimeout(() => {
      setShowRedeem(false);
     
      return history.push({
        pathname: `/coinstories/${nid}`,
        state: {field_genre, gener_id},
      });
    }, 2500);
  }

  return (
    <>
      <div className="card border-0 shadowEffect2 mb-md-5">
          <div
            onClick={redirectFn} style={{ cursor: "pointer" }}
            className="card-body pt-4"
            style={{
              backgroundColor: field_bg_color,
              color: "#000",
              cursor: "pointer",
            }}>
            <h2 className="fw-bold" dangerouslySetInnerHTML={{ __html: title }}></h2>
            <p className="card-text" dangerouslySetInnerHTML={{ __html: field_short_description }}></p>
            <p className="mb-0">
              <b className="smlabel">Author:&nbsp;</b>
              <span className="smcontent d-block d-md-inline position-relative">
                {selectedUserId ?
                  <NavLink
                    onClick={(e) => { e.stopPropagation() }}
                    to={{
                      pathname: "/user",
                      state: {
                        rdValue: false,
                        userSel: { selectedUserId },
                        schoolSel: { selectedSchool },
                        gradeSel: { selectedGrade },
                        genreSel: { selectedGenre },
                        id: "0",
                        type: { rdTypeVal }
                      }
                    }}
                    className="">
                    {author}
                  </NavLink>
                  :
                  ""
                }
                {/* <a href={`${uid}`}>{author}</a> */}
                {/* {wishlist_status == "1" ? 
        <span className="follow-badge d-none d-md-inline">following</span> : ''
      } */}
              </span>
            </p>
          </div>

        <p
          className="genreBox mb-0 px-1 px-md-3 py-0 py-md-2"
          style={{
            backgroundColor: gener_color,
            color: "#fff"
          }}>
          <b className="smlabel">Genre:&nbsp;</b>
          <span className="smgenrecontent d-block d-md-inline" dangerouslySetInnerHTML={{ __html: field_genre }}></span>
        </p>
        <SocialActions
          title={title}
          storyDesc={field_short_description}
          styles={`d-none d-md-flex cardSocials`}
          like={field_like}
          view={field_view}
          bookmark={field_bookmarks}
          wishlist={field_wishlist}
          bookmarkStatus={bookmark_status}
          likeStatus={like_status}
          viewStatus={view_status}
          bookmarkStatus={bookmark_status}
          wishlistStatus={wishlist_status}
          nid={nid}
          type="stories"
        ></SocialActions>
      </div>
      {modal &&
        <ModalPop modal={modal} modalFn={modalfn} storyType={field_stories_type} />
      }
      <Modal show={showRedeem} dialogClassName={`hophighModal redeemModal modal-dialog-centered`} size="md" onHide={handleRedeemClose}>
        <Modal.Body className="px-0">
          <RedeemCoins gener_color={gener_color} chapterId={chapterId} title={title} genre={field_genre} closeHandler={handleRedeemClose}  successStatus={successStatus} nid={nid} uid={uid} redeemType={field_redeemtype} requiredCoins={totalCoins} submitHandler={onRedeemSubmit}></RedeemCoins>
        </Modal.Body>
      </Modal>
    </>
  );
}


